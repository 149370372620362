<script setup>
	import { reactive, watch } from "vue";
	import { EditIcon } from "@/components";
	import { accountingService, executeServiceCall } from "@/services";
	import { formatAmount, parseNumber, sum } from "@/utils";

	/**
	 * Public Interface
	 */
	const props = defineProps({
		isSaving: { type: Boolean, required: true },
		payrollRunId: { type: String, required: false },
	});
	const emit = defineEmits(["doneSaving", "isEditing"]);

	/**
	 * Data
	 */
	const mainData = reactive({
		hasError: false,
		isProcessing: false,
		payrollRun: null,
		payrollRunEditing: null,
	});

	/**
	 * Functions
	 */
	function editEmployeeGrossPay(id, value) {
		if (!mainData.payrollRunEditing)
			return;
		var employee = getEditingEmployee(id);
		var parsedValue = parseNumber(value);
		// console.log("Gross was: ", value);
		// console.log("Gross is: ", parsedValue);
		employee.grossPay = parsedValue;
	}
	function editEmployeeEmployerContribution(employeeId, employerContributionName, value) {
		if (!mainData.payrollRunEditing)
			return;
		var employee = getEditingEmployee(employeeId);
		var employerContribution = employee.employerContributions.find(employerContribution => employerContribution.name === employerContributionName);
		if (!employerContribution) {
			employerContribution = { name: employerContributionName };
			employee.employerContributions.push(employerContribution);
		}
		var parsedValue = parseNumber(value);
		// console.log(`${employerContributionName} was: `, value);
		// console.log(`${employerContributionName} is: `, parsedValue);
		employerContribution.amount = parsedValue;
	}
	function editEmployeeWithholding(employeeId, withholdingName, value) {
		if (!mainData.payrollRunEditing)
			return;
		var employee = getEditingEmployee(employeeId);
		var withholding = employee.withholdings.find(withholding => withholding.name === withholdingName);
		if (!withholding) {
			withholding = { name: withholdingName };
			employee.withholdings.push(withholding);
		}
		var parsedValue = parseNumber(value);
		// console.log(`${withholdingName} was: `, value);
		// console.log(`${withholdingName} is: `, parsedValue);
		withholding.amount = parsedValue;
	}
	function emitDoneSaving(isSuccessful) { emit("doneSaving", isSuccessful); }
	function emitIsEditing() { emit("isEditing"); }
	function getEditingEmployee(id) {
		if (!mainData.payrollRunEditing)
			return null;
		var employee = mainData.payrollRunEditing.employeeCalculations.find(employee => employee.id === id);
		return employee;
	}
	function getEmployerContributionAmount(employee, name) {
		return employee.employerContributions[name] || 0;
	}
	function getEmployerContributionNames() {
		if (!mainData.payrollRun)
			return [];

		if (!mainData.employerContributionNames) {
			const names = [];
			mainData.payrollRun.employees.forEach(employee => Object.keys(employee.employerContributions).forEach(name => names.push(name)));

			const distinctNames = names
				.filter((value, index, array) => array.indexOf(value) === index)
				.sort();
			mainData.employerContributionNames = distinctNames;
		}
		return mainData.employerContributionNames;
	}
	function getWithholdingAmount(employee, name) {
		return employee.withholdings[name] || 0;
	}
	function getWithholdingNames() {
		if (!mainData.payrollRun)
			return [];

		if (!mainData.withholdingNames) {
			const names = [];
			mainData.payrollRun.employees.forEach(employee => Object.keys(employee.withholdings).forEach(name => names.push(name)));

			const distinctNames = names
				.filter((value, index, array) => array.indexOf(value) === index)
				.sort();
			mainData.withholdingNames = distinctNames;
		}
		return mainData.withholdingNames;
	}
	async function loadPayrollRun() {
		mainData.isProcessing = true;
		mainData.hasError = false;

		await executeServiceCall(() => accountingService.payrollRun.byId(props.payrollRunId))
			.then(({ data }) => mainData.payrollRun = data)
			.catch(() => mainData.hasError = true)
			.finally(() => mainData.isProcessing = false);
	}
	function resetData() {
		mainData.payrollRun = null;
	}
	async function saveEdits() {
		if (!mainData.payrollRunEditing)
			return;

		mainData.isProcessing = true;
		mainData.hasError = false;
		await executeServiceCall(() => accountingService.payrollRun.editPayroll(mainData.payrollRunEditing))
			.then(() => emitDoneSaving(true))
			.catch(() => {
				mainData.hasError = true;
				emitDoneSaving(false);
			})
			.finally(() => mainData.isProcessing = false);
	}
	function startEdit() {
		mainData.payrollRunEditing = {
			id: mainData.payrollRun.id,
			employeeCalculations: mainData.payrollRun.employees.map(employee => {
				return {
					id: employee.id,
					grossPay: employee.grossPay,
					withholdings: Object.keys(employee.withholdings).map(name => {
						return {
							name: name,
							amount: employee.withholdings[name]
						};
					}),
					employerContributions: Object.keys(employee.employerContributions).map(name => {
						return {
							name: name,
							amount: employee.employerContributions[name]
						};
					})
				}
			})
		};
	}

	/**
	 * Watches
	 */
	watch(() => mainData.payrollRunEditing, () => {
		if (!!mainData.payrollRunEditing)
			emitIsEditing();
	});
	watch(() => props.isSaving, async () => {
		if (props.isSaving)
			await saveEdits();
	});
	watch(() => props.payrollRunId, async () => {
		if (!!props.payrollRunId)
			await loadPayrollRun();
		else
			resetData();
	}, { immediate: true });
</script>

<template>
	<div>
		<VProgressLinear v-if="mainData.isProcessing" color="primary" class="view-payroll-run-loading-indicator" indeterminate />
		
		<VAlert v-if="mainData.hasError" color="error" class="view-payroll-run-critical-error-message">Something went wrong.  Try again later.</VAlert>

		<VSheet v-if="!!mainData.payrollRun" height="100%">
			<h2 id="payrollRunTitle">Payroll for '{{ mainData.payrollRun.payrollScheduleName }}' on {{ mainData.payrollRun.date }}</h2>
			<h4 class="text-center">Summary</h4>
			<VTable density="compact">
				<tbody>
					<tr>
						<td>Gross wages to {{ mainData.payrollRun.grossWagesExpenseAccountName }}</td>
						<td class="text-right total-gross-wages">{{ formatAmount(mainData.payrollRun.totalGrossWages) }}</td>
						<td>Net wages in {{ mainData.payrollRun.netWagesPayableAccountName }}</td>
						<td class="text-right total-net-wages">{{ formatAmount(mainData.payrollRun.totalNetWages) }}</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
						<td>Withheld taxes in {{ mainData.payrollRun.withheldTaxesLiabilityAccountName }}</td>
						<td class="text-right total-withheld-taxes">{{ formatAmount(mainData.payrollRun.totalWithheldTaxes) }}</td>
					</tr>
					<tr>
						<td>Employer taxes to {{ mainData.payrollRun.employerTaxesExpenseAccountName }}</td>
						<td class="text-right total-employer-taxes-expense">{{ formatAmount(mainData.payrollRun.totalEmployerTaxes) }}</td>
						<td>Employer taxes in {{ mainData.payrollRun.employerTaxesPayableAccountName }}</td>
						<td class="text-right total-employer-taxes-payable">{{ formatAmount(mainData.payrollRun.totalEmployerTaxes) }}</td>
					</tr>
					<tr>
						<th class="text-right">Total Expense</th>
						<th class="text-right total-expenses">{{ formatAmount(mainData.payrollRun.totalExpenses) }}</th>
						<th class="text-right">Total Liabilities</th>
						<th class="text-right total-liabilities">{{ formatAmount(mainData.payrollRun.totalLiabilities) }}</th>
					</tr>
				</tbody>
			</VTable>

			<VRow>
				<VCol class="v-col-6">
					<h4>Employees Paid</h4>
				</VCol>
				<VCol class="v-col-6 text-right">
					<VBtn v-if="!mainData.payrollRunEditing"
						title="Edit Data"
						color="primary"
						class="edit-employee-data-action"
						:disabled="mainData.isProcessing"
						@click="startEdit">
						<EditIcon/>
					</VBtn>
				</VCol>
			</VRow>
			
			<VTable density="compact">
				<thead>
					<th>Name</th>
					<th>Gross</th>
					<th v-for="(name, i) in getWithholdingNames()" :key="i">{{ name }}</th>
					<th>Net</th>
					<th v-for="(name, i) in getEmployerContributionNames()" :key="i">{{ name }}</th>
				</thead>
				<tbody>
					<tr v-for="(employee, i) in mainData.payrollRun.employees" :key="i" class="employee" :data-id="employee.id">
						<td>{{ employee.name }}</td>
						<td class="text-right">
							<span v-if="!mainData.payrollRunEditing">{{ formatAmount(employee.grossPay) }}</span>
							<span v-else>
								<VTextField :modelValue="getEditingEmployee(employee.id).grossPay"
									class="gross-pay-input"
									@update:modelValue="value => employee.tempGrossPay = value"
									@update:focused="isFocused => { if (isFocused) return; editEmployeeGrossPay(employee.id, employee.tempGrossPay); employee.tempGrossPay = undefined; }" />
							</span>
						</td>
						<td v-for="(name, j) in getWithholdingNames()" :key="j" class="text-right withholding" :data-id="name">
							<span v-if="!mainData.payrollRunEditing">{{ formatAmount(getWithholdingAmount(employee, name)) }}</span>
							<span v-else>
								<VTextField :modelValue="getEditingEmployee(employee.id).withholdings.find(withholding => withholding.name === name)?.amount"
									class="amount-input"
									@update:modelValue="value => employee[`tempwh${name}`] = value"
									@update:focused="isFocused => { if (isFocused) return; editEmployeeWithholding(employee.id, name, employee[`tempwh${name}`]); employee[`tempwh${name}`] = undefined; }" />
							</span>
						</td>
						<td class="text-right">{{ formatAmount(employee.netPay) }}</td>
						<td v-for="(name, j) in getEmployerContributionNames()" :key="j" class="text-right employer-contribution" :data-id="name">
							<span v-if="!mainData.payrollRunEditing">{{ formatAmount(getEmployerContributionAmount(employee, name)) }}</span>
							<span v-else>
								<VTextField :modelValue="getEditingEmployee(employee.id).employerContributions.find(employerContribution => employerContribution.name === name)?.amount"
									class="amount-input"
									@update:modelValue="value => employee[`tempec${name}`] = value"
									@update:focused="isFocused => { if (isFocused) return; editEmployeeEmployerContribution(employee.id, name, employee[`tempec${name}`]); employee[`tempec${name}`] = undefined; }" />
							</span>
						</td>
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<th>Totals</th>
						<th class="text-right">{{ formatAmount(sum(mainData.payrollRun.employees.map(employee => employee.grossPay))) }}</th>
						<th v-for="(name, i) in getWithholdingNames()" :key="i" class="text-right">{{ formatAmount(sum(mainData.payrollRun.employees.map(employee => getWithholdingAmount(employee, name)))) }}</th>
						<th class="text-right">{{ formatAmount(sum(mainData.payrollRun.employees.map(employee => employee.netPay))) }}</th>
						<th v-for="(name, i) in getEmployerContributionNames()" :key="i" class="text-right">{{ formatAmount(sum(mainData.payrollRun.employees.map(employee => getEmployerContributionAmount(employee, name)))) }}</th>
					</tr>
				</tfoot>
			</VTable>
		</VSheet>
	</div>
</template>
