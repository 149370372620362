<script setup>
	import { onMounted, reactive, ref } from "vue";
	import { VAlert, VCard, VCardText, VCol, VList, VListItem, VListItemSubtitle, VListItemTitle, VListSubheader, VProgressCircular, VRow } from "vuetify/components";
	import { useUserStore } from "@/stores";
	import { Roles } from "@/UserRoles";
	import { executeServiceCall, selfServiceService } from "@/services";
	import { getRouter } from "@/router";
	import { ErrorIcon, InfoIcon, WarningIcon } from "@/components";

	const account = ref(useUserStore().getAccount());

	const notificationInfo = reactive({
		hasError: false,
		isLoading: true,
		notifications: [],
	});

	function isInRole(role) {
		if (!account.value)
			return false;
		return account.value.roles.includes(role);
	}
	async function loadNotifications() {
		notificationInfo.hasError = false;
		notificationInfo.isLoading = true;

		await executeServiceCall(selfServiceService.accounting.notifications)
			.then(({ data }) => notificationInfo.notifications = data)
			.catch(() => notificationInfo.hasError = true)
			.finally(() => notificationInfo.isLoading = false);
	}
	function navigateTo(to) {
		getRouter().push(to);
	}

	onMounted(async () => { await loadNotifications(); });
</script>

<template>
	<div class="container">
		<h1>Accounting Home</h1>
		<VRow>
			<VCol class="v-col-6">
				<VCard title="Notifications">
					<VProgressCircular v-if="notificationInfo.isLoading"
						class="loading-notifications-indicator"
						:size="50"
						color="primary"
						indeterminate />
					<VList v-else>
						<VAlert v-if="notificationInfo.hasError"
							class="notification-critical-error"
							type="error">
							Something went wrong.  Try again later.
						</VAlert>
						<VListItem v-else-if="notificationInfo.notifications.length > 0" v-for="(notification, i) in notificationInfo.notifications"
							class="notification"
							:key="i"
							:value="notification"
							@click="navigateTo({ path: notification.link })">
							<template v-slot:prepend>
								<ErrorIcon v-if="notification.level === 'Error'" class="text-danger mr-2" />
								<WarningIcon v-else-if="notification.level === 'Warning'" class="text-orange mr-2" />
								<InfoIcon v-else class="text-primary mr-2" />
							</template>
							<VListItemTitle>{{ notification.title }}</VListItemTitle>
							<VListItemSubtitle>{{ notification.description }}</VListItemSubtitle>
						</VListItem>
						<VAlert v-else
							class="no-notification-message"
							variant="plain">
							There are no notifications at this time.
						</VAlert>
					</VList>
				</VCard>
			</VCol>
			<VCol class="v-col-6">
				<VCard title="Useful Links">
					<VList>
						<VListSubheader v-if="isInRole(Roles.Accountant)">Accountant Activities</VListSubheader>
						<VListItem v-if="isInRole(Roles.Accountant)" @click="navigateTo({ name: 'accountingAccounts' })">
							<VListItemTitle>G/L Account Management</VListItemTitle>
							<VListItemSubtitle>Allows you to create, edit, and delete General Ledger Accounts.</VListItemSubtitle>
						</VListItem>
						<VListSubheader v-if="isInRole(Roles.Representative)">Representative Activities</VListSubheader>
						<VListItem v-if="isInRole(Roles.Representative)" @click="navigateTo({ name: 'accountingInvoices' })">
							<VListItemTitle>Manage Invoices</VListItemTitle>
							<VListItemSubtitle>Allows you to manage Invoices for Customers.</VListItemSubtitle>
						</VListItem>
					</VList>
				</VCard>
				<!-- <div v-if="isInRole(Roles.Accountant)" class="my-4">
					<h5>Accountant Activities</h5>
					<div class="row">
						<div class="col-auto my-1">
							<router-link :to="{ name: 'accountingAccounts' }" class="btn btn-primary text-left">
								<v-card class="mx-auto">
									<template v-slot:title>G/L Account Management</template>
									<v-card-text>Allows you to create, edit, and delete General Ledger Accounts.</v-card-text>
								</v-card>
							</router-link>
						</div>
					</div>
				</div> -->

				<!-- <div v-if="isInRole(Roles.Representative)" class="my-4">
					<h5>Representative Activities</h5>
					<div class="row">
						<div class="col-auto my-1">
							<router-link :to="{ name: 'accountingInvoices' }" class="btn btn-primary text-left">
								<v-card class="mx-auto">
									<template v-slot:title>Manage Invoices</template>
									<v-card-text>Allows you to manage Invoices for Customers.</v-card-text>
								</v-card>
							</router-link>
						</div>
					</div>
				</div> -->
			</VCol>
		</VRow>
	</div>
</template>
