<script setup>
	import { ref } from "vue";
	import { VDivider, VFadeTransition, VList, VListItem, VNavigationDrawer } from "vuetify/components";
	import { getRouter, getTopLevelSecuredRoutes } from "@/router";
	import { useUserStore } from "@/stores";

	const props = defineProps({
		areaIcon: { type: String, required: true },
		areaRouteName: { type: String, required: true },
		areaTitle: { type: String, required: true },
		primaryLinks: { type: Array, required: true },
		secondaryLinks: { type: Array, required: true },
	});

	const account = ref(useUserStore().getAccount());

	function getAuthorizedPrimaryLinks() {
		return props.primaryLinks.filter(link => isRouteAuthorized(link.routeName));
	}
	function getAuthorizedSecondaryLinks() {
		return props.secondaryLinks.filter(link => isRouteAuthorized(link.routeName));
	}
	function isRouteAuthorized(routeName) {
		const route = getTopLevelSecuredRoutes()
			.find(topLevelRoute => topLevelRoute.name === props.areaRouteName)
			.children
				.find(childRoute => childRoute.name === routeName);
		if (!route)
			return false;
		if (!route.meta || !route.meta.authorize)
			return true;
		return isUserInAnyRole(route.meta.authorize);
	}
	function isUserInAnyRole(roles) {
		if (!account.value)
			return false;
		return account.value.roles.filter(role => roles.includes(role)).length > 0;
	}
	function navigateTo(to) {
		getRouter().push(to);
	}
</script>

<template>
	<div>
		<VNavigationDrawer color="primary">
			<VList>
				<VListItem :title="props.areaTitle" :prepend-icon="props.areaIcon" />
			</VList>
			<VDivider />
			<VList density="compact" nav>
				<VListItem v-for="(link, i) in getAuthorizedPrimaryLinks()" :key="i"
					:title="link.title"
					:prepend-icon="link.icon"
					:class="link.class"
					@click="navigateTo({ name: link.routeName })" />
			</VList>
			<VDivider />
			<VList density="compact" nav>
				<VListItem v-for="(link, i) in getAuthorizedSecondaryLinks()" :key="i"
					:title="link.title"
					:prepend-icon="link.icon"
					:class="link.class"
					@click="navigateTo({ name: link.routeName })" />
			</VList>
		</VNavigationDrawer>

		<RouterView v-slot="{ Component }">
			<VFadeTransition mode="out-in" :hide-on-leave="true">
				<component :is="Component"/>
			</VFadeTransition>
		</RouterView>
	</div>
</template>
