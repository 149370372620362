<script setup>
	import { reactive } from "vue";
	import { executeServiceCall, getValidationErrorsFromResponse, selfServiceService } from "@/services";
	import mapUrl from "@/assets/img/MapWide.png";

	const mainData = reactive({
		contact: {
			emailAddress: "",
			name: "",
			phoneNumber: "",
			recaptchaResponse: "",
			text: "",
		},
		hasCriticalError: false,
		isProcessing: false,
		validationErrors: [],
	});
	const rules = {
		required: value => !!value || "This value is required.",
	};

	async function submit() {
		mainData.isProcessing = true;
		mainData.hasCriticalError = false;
		mainData.validationErrors = [];

		var recaptchaResponse = grecaptcha.getResponse();
		mainData.contact.recaptchaResponse = recaptchaResponse;

		await executeServiceCall(() => selfServiceService.public.submitContactRequest(mainData.contact))
			.then(() => mainData.contact = null)
			.catch(error => {
				if (error.response?.status === 400) {
					mainData.validationErrors = getValidationErrorsFromResponse(error.response);
				} else {
					console.error(error);
					mainData.hasCriticalError = true;
				}
			})
			.finally(() => mainData.isProcessing = false);
	}
</script>

<template>
	<div class="container">
		<section class="contact">
			<div class="container-fluid p-0">
				<VRow class="g-0 pt-2">
					<VCol class="v-col-12 v-col-md-5 bg-light">
						<div class="text-center">
							<h1>Request Information</h1>
							<h5>Call us at</h5>
							<h3 class="text-primary"><a style="text-decoration: none;" href="tel: 817-704-0300">817-704-0300</a></h3>
							<p>Or, fill out the form, and we'll respond to you as soon as we can.</p>
						</div>
						<VAlert v-if="mainData.hasCriticalError" color="error" class="critical-error-message">Something went wrong.  Try again later.</VAlert>
						<VAlert v-if="mainData.validationErrors.length > 0"
							title="Problems"
							color="warning"
							type="warning"
							density="compact">
							<ul class="validation-messages">
								<li v-for="error in mainData.validationErrors">{{ error }}</li>
							</ul>
						</VAlert>
					</VCol>
					<VCol class="v-col-12 v-col-md-7">
						<div v-if="mainData.contact" class="contact-form">
							<VProgressLinear v-if="mainData.isProcessing" class="loading-indicator" color="primary" indeterminate />
							<VTextField v-model="mainData.contact.name"
								id="contactName"
								label="Your name"
								:rules="[rules.required]"
								:disabled="mainData.isProcessing" />
							<VTextField v-model="mainData.contact.emailAddress"
								id="contactEmailAddress"
								label="Your e-mail address"
								:rules="[rules.required]"
								:disabled="mainData.isProcessing" />
							<VTextField v-model="mainData.contact.phoneNumber"
								id="contactPhoneNumber"
								label="Your phone number"
								:rules="[rules.required]"
								:disabled="mainData.isProcessing" />
							<VTextarea v-model="mainData.contact.text"
								id="contactText"
								label="What would you like to know?"
								:rules="[rules.required]"
								:disabled="mainData.isProcessing" />
							<component :is="'script'" src="https://www.google.com/recaptcha/api.js"></component>
							<div class="text-center">
								<div class="g-recaptcha" data-sitekey="6LcjlScpAAAAANihJdYzvMoXmYpMhV3nQM88JWfe"></div>
								<VBtn color="primary"
									class="submit-contact-request-action"
									:disabled="mainData.isProcessing"
									@click="submit">Submit</VBtn>
							</div>
						</div>
						<VAlert v-else color="primary" class="success-message">Thank you for your submission!  We'll contact you as soon as possible.</VAlert>
					</VCol>
				</VRow>
				<VRow>
					<VCol class="v-col-12 text-center">
						<h3>Find Us in Arlington</h3>
						<a href="https://www.bing.com/maps?osid=3be552aa-5866-4086-b83a-8b32d0f136fc&cp=32.746634~-97.184012&lvl=14.5&pi=0&v=2&sV=2&form=S00027" target="_blank">
							<img :src="mapUrl" style="width: 100%;" />
						</a>
						<h6>2201 Dottie Lynn Pkwy, Suite 145<br/>Forth Worth, Texas 76120<br/><em>Just 1 mile south of I-30 and Eastchase Pkwy.</em></h6>
					</VCol>
				</VRow>
			</div>
		</section>
	</div>
</template>
