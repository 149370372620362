<script setup>
	import { nextTick, onMounted, reactive, watch } from "vue";
	import { ViewDetailsIcon } from "@/components";
	import { ExecutePayrollRunDialog, PayrollRunIcon, RunPayrollIcon, ViewPayrollRunDialog } from "../components";
	import { accountingService, executeServiceCall } from "@/services";

	const executePayrollRunDialog = reactive({
		hasChanges: false,
		isExecuting: false,
		payrollScheduleId: null,
		onClosed: async function() {
			executePayrollRunDialog.isExecuting = false;
			if (executePayrollRunDialog.hasChanges) {
				await nextTick(async () => {
					await loadPayrollRuns();
					executePayrollRunDialog.hasChanges = false;
				});
			}
		},
	});
	const mainData = reactive({
		hasAllPayrollSettings: true,
		hasError: false,
		isProcessing: false,
		payrollRuns: null,
		viewSelection: 0,
	});
	const viewPayrollRunDialog = reactive({
		isShowing: false,
		payrollRunId: null,
		onClosed: function () {
			viewPayrollRunDialog.isShowing = false;
		},
	});

	function executePayrollRun(payrollScheduleId) {
		executePayrollRunDialog.isExecuting = false;
		executePayrollRunDialog.payrollScheduleId = null;
		executePayrollRunDialog.payrollScheduleId = payrollScheduleId;
		executePayrollRunDialog.isExecuting = true;
	}
	async function loadPayrollRuns() {
		mainData.hasError = false;
		mainData.isProcessing = true;

		if (mainData.viewSelection === 0) {
			await executeServiceCall(accountingService.payrollRun.listPending)
				.then(({ data }) => {
					mainData.payrollRuns = data.payrollRuns;
					mainData.hasAllPayrollSettings = data.hasAllPayrollSettings;
				})
				.catch(() => mainData.hasError = true)
				.finally(() => mainData.isProcessing = false);
		} else {
			await executeServiceCall(accountingService.payrollRun.listCompleted)
				.then(({ data }) => {
					mainData.payrollRuns = data;
					mainData.hasAllPayrollSettings = true;
				})
				.catch(() => mainData.hasError = true)
				.finally(() => mainData.isProcessing = false);
		}
	}
	function viewPayrollRun(payrollRunId) {
		viewPayrollRunDialog.isShowing = false;
		viewPayrollRunDialog.payrollRunId = null;
		viewPayrollRunDialog.payrollRunId = payrollRunId;
		viewPayrollRunDialog.isShowing = true;
	}

	watch(() => mainData.viewSelection, async() => { await loadPayrollRuns(); });

	onMounted(async () => {
		await loadPayrollRuns();
	});
</script>

<template>
	<div class="container my-4">
		<VRow>
			<VCol class="v-col-auto">
				<h1 class="col-auto"><PayrollRunIcon fontSize="48px" /> Payroll Runs</h1>
			</VCol>
			<VSpacer></VSpacer>
			<VCol class="v-col-auto text-center">
				<VBtnToggle v-model="mainData.viewSelection" :disabled="mainData.isProcessing">
					<VBtn class="view-pending-action">Pending</VBtn>
					<VBtn class="view-completed-action">Completed</VBtn>
				</VBtnToggle>
			</VCol>
			<VSpacer></VSpacer>
			<VCol class="v-col-auto text-right">
			</VCol>
		</VRow>

		<VProgressLinear v-if="mainData.isProcessing" indeterminate color="primary" class="loading-indicator" />
		<VAlert v-if="mainData.hasError" color="error" class="mb-4 critical-error-message">Something went wrong.  Try again later.</VAlert>
		<VAlert v-if="!mainData.hasAllPayrollSettings" color="warning" class="mb-4 missing-settings-message">One or more Payroll Settings need to be set before Payroll can be run.</VAlert>

		<div v-if="!!mainData.payrollRuns">
			<VAlert v-if="mainData.payrollRuns.length === 0" class="mb-4 no-payroll-runs-message">No payroll runs were found.</VAlert>
			<VTable v-else class="table-striped">
				<thead>
					<tr>
						<th></th>
						<th>Date</th>
						<th>Schedule</th>
						<th># of Employees</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(payrollRun, i) in mainData.payrollRuns" :key="i" :data-id="payrollRun.id" class="payroll-run">
						<td>
							<VBtn v-if="payrollRun.isCompleted"
								class="view-payroll-run-action"
								variant="plain"
								color="primary"
								title="View Details"
								:disabled="mainData.isProcessing"
								@click="viewPayrollRun(payrollRun.id)">
								<ViewDetailsIcon/>
							</VBtn>
							<VBtn v-else
								class="execute-payroll-run-action"
								variant="plain"
								color="primary"
								title="Run Payroll"
								:disabled="mainData.isProcessing || !mainData.hasAllPayrollSettings"
								@click="executePayrollRun(payrollRun.id)">
								<RunPayrollIcon/>
							</VBtn>
						</td>
						<td :class="payrollRun.isOverdue ? 'text-danger fw-bold' : undefined">{{ payrollRun.date }}</td>
						<td>{{ payrollRun.scheduleName }}</td>
						<td>{{ payrollRun.employeeCount }}</td>
					</tr>
				</tbody>
			</VTable>
		</div>

		<ExecutePayrollRunDialog :payrollScheduleId="executePayrollRunDialog.payrollScheduleId"
			:shouldBeExecuting="executePayrollRunDialog.isExecuting"
			@payrollRunExecuted="executePayrollRunDialog.hasChanges = true"
			@closed="executePayrollRunDialog.onClosed" />
		<ViewPayrollRunDialog :payrollRunId="viewPayrollRunDialog.payrollRunId"
			:shouldBeShowing="viewPayrollRunDialog.isShowing"
			@closed="viewPayrollRunDialog.onClosed" />
	</div>
</template>
