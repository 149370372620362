<script setup>
	import { nextTick, onMounted, reactive } from "vue";
	import { VAlert, VBtn, VCol, VProgressLinear, VRow, VSpacer, VTable } from "vuetify/components";
	import { accountingService, executeServiceCall } from "@/services";
	import { formatAmount } from "@/utils";
	import { CustomerPaymentIcon, EditCustomerPaymentDialog, EditInvoiceDialog, InvoiceIcon } from "../components";

	const editInvoiceInfo = reactive({
		invoiceId: null,
		isEditing: false,
	});
	const editCustomerPaymentInfo = reactive({
		hasAnyChanges: false,
		invoiceId: null,
		isEditing: false,
	});
	const invoicesView = reactive({
		hasError: false,
		invoices: [],
		isLoading: false,
	});

	function addInvoice() {
		editInvoiceInfo.invoiceId = null;
		editInvoiceInfo.isEditing = true;
	}
	function createCustomerPaymentFor(invoiceId) {
		editCustomerPaymentInfo.isEditing = false;
		editCustomerPaymentInfo.invoiceId = null;
		editCustomerPaymentInfo.invoiceId = invoiceId;
		editCustomerPaymentInfo.isEditing = true;
	}
	async function editInvoice(invoiceId) {
		if (!invoiceId)
			return;

		editInvoiceInfo.invoiceId = invoiceId;
		editInvoiceInfo.isEditing = true;
	}
	function getInvoicesTotal() {
		let grandTotal = 0;
		const { invoices } = invoicesView;
		if (invoices)
			invoices.forEach(invoice => grandTotal += invoice.unpaidBalance);
		return grandTotal;
	}
	async function loadInvoicesView() {
		invoicesView.isLoading = true;
		invoicesView.hasError = false;
		await executeServiceCall(accountingService.invoice.listOpen)
			.then(({ data }) => invoicesView.invoices = data)
			.catch(() => invoicesView.hasError = true)
			.finally(() => invoicesView.isLoading = false);
	}
	async function onEditInvoiceDialogClosed() {
		editInvoiceInfo.isEditing = false;
		editInvoiceInfo.invoiceId = null;
		await nextTick(async () => {
			await loadInvoicesView();
		});
	}
	async function onCustomerPaymentDialogClosed() {
		editCustomerPaymentInfo.isEditing = false;
		if (editCustomerPaymentInfo.hasAnyChanges) {
			await nextTick(async () => {
				await loadInvoicesView();
				editCustomerPaymentInfo.hasAnyChanges = false;
			});
		}
	}
	function onCustomerPaymentUpdated() { console.log("Customer payment updated."); editCustomerPaymentInfo.hasAnyChanges = true; }

	onMounted(async () => await loadInvoicesView());

</script>

<template>
	<div class="container my-4">
		<VRow>
			<VCol class="v-col-auto">
				<h1 class="col-auto"><InvoiceIcon fontSize="48px" /> Invoice Management</h1>
			</VCol>
			<VSpacer></VSpacer>
			<VCol class="v-col-auto text-center">
				<h5 class="mt-3 text-center">Open Invoices</h5>
			</VCol>
			<VSpacer></VSpacer>
			<VCol class="v-col-auto text-right">
				<VBtn color="primary" variant="flat" size="large" class="ma-2 create-invoice-action" :disabled="invoicesView.isLoading" @click="addInvoice"><i class="mdi mdi-plus mr-2"></i> New Invoice</VBtn>
			</VCol>
		</VRow>

		<template v-if="invoicesView.hasError">
			<VAlert color="error" class="mb-4">Something went wrong.  Try again later.</VAlert>
		</template>
		<template v-if="invoicesView.isLoading">
			<VProgressLinear indeterminate color="primary"></VProgressLinear>
		</template>
		<template :class="invoicesView.isLoading ? 'disabled row' : 'row'">
			<VTable class="table-striped" density="compact">
				<thead>
					<th></th>
					<th>Date</th>
					<th>Age</th>
					<th>Number</th>
					<th>Customer</th>
					<th>Due Date</th>
					<th>Total</th>
					<th>Unpaid Balance</th>
				</thead>
				<tbody>
					<tr v-for="invoice in invoicesView.invoices" :key="invoice.id" :data-id="invoice.id">
						<td>
							<VBtn variant="plain" color="primary" class="print-invoice-action" title="Print" :disabled="invoicesView.isLoading" :href="`/accounting/invoice/print/${invoice.id}`" target="_blank"><i class="mdi mdi-printer mdi-24px"></i></VBtn>
							<VBtn class="create-invoice-payment-action"
								variant="plain"
								color="primary"
								title="Pay Invoice"
								:disabled="invoicesView.isLoading"
								@click="createCustomerPaymentFor(invoice.id)">
								<CustomerPaymentIcon/>
							</VBtn>
						</td>
						<td @click="editInvoice(invoice.id)" class="clickable">{{ invoice.date }}</td>
						<td @click="editInvoice(invoice.id)" class="clickable text-right">{{ invoice.ageInDays }}</td>
						<td @click="editInvoice(invoice.id)" class="clickable">{{ invoice.number }}</td>
						<td @click="editInvoice(invoice.id)" class="clickable">{{ invoice.partyName }}</td>
						<td @click="editInvoice(invoice.id)" :class="invoice.isPastDue ? 'clickable text-danger fw-bold' : 'clickable'">{{ invoice.dueDate }}</td>
						<td @click="editInvoice(invoice.id)" class="clickable text-right">{{ formatAmount(invoice.total) }}</td>
						<td @click="editInvoice(invoice.id)" class="clickable text-right">{{ formatAmount(invoice.unpaidBalance) }}</td>
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<th colspan="7"></th>
						<th class="text-right">${{ formatAmount(getInvoicesTotal()) }}</th>
					</tr>
				</tfoot>
			</VTable>
		</template>

		<EditInvoiceDialog :originalInvoiceId="editInvoiceInfo.invoiceId"
			:shouldBeEditing="editInvoiceInfo.isEditing"
			@closed="onEditInvoiceDialogClosed" />
		<EditCustomerPaymentDialog :selectedInvoiceId="editCustomerPaymentInfo.invoiceId"
			:shouldBeEditing="editCustomerPaymentInfo.isEditing"
			@customerPaymentUpdated="onCustomerPaymentUpdated"
			@closed="onCustomerPaymentDialogClosed" />
	</div>
</template>

<style scoped>
	.v-theme--light.VTable.table-striped tbody tr:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}
	.clickable {
		cursor: pointer;
	}
</style>
