<script setup>
	import { reactive, watch } from "vue";
	import { CloseIcon } from "@/components";
	import { ExecutePayrollRunForm, RunPayrollIcon } from "./";

	const props = defineProps({
		payrollScheduleId: { type: String, required: false },
		shouldBeExecuting: { type: Boolean, required: true },
	});
	const emit = defineEmits([ "closed", "payrollRunExecuted" ]);

	const dialog = reactive({
		isExecuting: false,
		isProcessing: false,
		isShowing: false,
	});
	const executePayrollRunForm = reactive({
		ignoreIsDirty: false,
		isDirty: false,
		onPayrollRunExecuted: function () {
			emitPayrollRunExecuted();
			snackbarInfo.isShowing = true;
			dialog.isShowing = false;
		}
	});
	const promptDialog = reactive({
		isPromptingUser: false,
		promptConfirmationAction: null,
		userPrompt: "",
	});
	const snackbarInfo = reactive({
		isShowing: false,
	});

	function emitClosed() { emit("closed"); }
	function emitPayrollRunExecuted() { emit("payrollRunExecuted"); }
	function promptUser(text, confirmationAction) {
		promptDialog.userPrompt = text;
		promptDialog.promptConfirmationAction = confirmationAction;
		promptDialog.isPromptingUser = true;
	}
	function stopEdit() {
		dialog.isShowing = false;
	}

	watch(() => dialog.isExecuting, () => {
		if (!dialog.isExecuting)
			emitClosed();
	});
	watch(() => dialog.isShowing, () => {
		if (!dialog.isShowing && executePayrollRunForm.isDirty && !executePayrollRunForm.ignoreIsDirty) {
			promptUser("You have unsaved changes.  Are you sure you want to close and lose your unsaved changes?", () => { executePayrollRunForm.ignoreIsDirty = true; dialog.isShowing = false });
			dialog.isShowing = true;
		}
		dialog.isExecuting = dialog.isShowing;
	}, { immediate: true });
	watch(() => props.shouldBeExecuting, (current, previous) => {
		if (!previous && current) {
			dialog.isShowing = true;
			dialog.isExecuting = true;
		}
	}, { immediate: true });
</script>

<template>
	<div>
		<VDialog v-model="dialog.isShowing"
			class="execute-payroll-run-dialog"
			scrim="true"
			transition="dialog-bottom-transition"
			:fullscreen="true">
			<VCard>
				<VToolbar color="primary">
					<VToolbarTitle><RunPayrollIcon/> Execute Payroll Run</VToolbarTitle>
					<VSpacer></VSpacer>
					<VBtn title="Close"
						:disabled="dialog.isProcessing"
						@click="stopEdit">
						<CloseIcon/>
					</VBtn>
				</VToolbar>
				<VCardText>
					<ExecutePayrollRunForm :payrollScheduleId="props.payrollScheduleId"
						@update:isDirty="isDirty => executePayrollRunForm.isDirty = isDirty"
						@payrollRunExecuted="executePayrollRunForm.onPayrollRunExecuted" />
				</VCardText>
			</VCard>
		</VDialog>
		<VDialog v-model="promptDialog.isPromptingUser" width="auto" scrim="true" class="confirm-cancel-dialog">
			<VCard>
				<VCardText>{{ promptDialog.userPrompt }}</VCardText>
				<VCardActions>
					<VBtn color="primary" class="yes-action" @click="promptDialog.promptConfirmationAction">Yes</VBtn>
					<VBtn color="secondary" @click="promptDialog.isPromptingUser = false" class="cancel-action">Cancel</VBtn>
				</VCardActions>
			</VCard>
		</VDialog>
		<VSnackbar v-model="snackbarInfo.isShowing" timeout="4000" rounded="pill">
			<span class="dialog-message">Payroll was run successfully.</span>
			<template v-slot:actions>
				<v-btn color="primary" variant="text" @click="snackbarInfo.isShowing = false">Close</v-btn>
			</template>
		</VSnackbar>
	</div>
</template>
