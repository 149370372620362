<script setup>
	import { reactive, watch } from "vue";
	import { academicService, executeServiceCall, getValidationErrorsFromResponse } from "@/services";
	import { hasAnySimpleValueChanged, hasArrayChanged } from "@/utils";
	import { AddSecondaryIcon, BaseEditDialog, RemoveSecondaryIcon } from "@/components";
	import { CourseIcon } from "../components";

	// Public Interface
	const props = defineProps({
		courseId: { type: String, required: false },
		shouldBeEditing: { type: Boolean, required: true },
	});
	const emit = defineEmits([ "closed", "courseUpdated" ]);

	// Data
	const mainData = reactive({
		course: null,
		hasCriticalError: false,
		ignoreIsDirty: false,
		isDeleteAllowed: false,
		isDirty: false,
		isEditing: false,
		isProcessing: false,
		isShowing: false,
		isUndeleteAllowed: false,
		newObjectiveText: "",
		originalCourse: null,
		validationErrors: [],
	});

	// Functions
	function addObjective() {
		mainData.course.objectives.push(mainData.newObjectiveText);
		mainData.newObjectiveText = "";
	};
	async function deleteCourse() {
		if (!props.courseId)
			return;

		resetErrors();
		mainData.isProcessing = true;

		await executeServiceCall(() => academicService.course.delete(props.courseId))
			.catch(error => {
				console.error(error);
				mainData.hasCriticalError = true;
			})
			.finally(() => mainData.isProcessing = false);
	};
	function emitClosed() { emit("closed"); }
	function emitCourseUpdated() { emit("courseUpdated", mainData.course.id); }
	function getTotalHours() {
		var totalHours = parseFloat(mainData.course.lectureHours || 0)
			+ parseFloat(mainData.course.labHours || 0)
			+ parseFloat(mainData.course.externshipHours || 0);
		var warning = totalHours > 24 ? "!!!" : "";
		return `${warning}${totalHours} Total Hours${warning}`;
	};
	async function loadCourse() {
		resetErrors();

		if (!props.courseId) {
			mainData.course = {
				cost: null,
				exemptionApprovalDate: null,
				exemptionSubmissionDate: null,
				explanation: "",
				externshipHours: null,
				isCertifiedToMeetTwcExemptionCriteria: false,
				labHours: null,
				lectureHours : null,
				name: "",
				objectives: [],
			};
			mainData.isProcessing = false;
			mainData.isDeleteAllowed = false;
			mainData.isUndeleteAllowed = false;
		}
		else {
			await executeServiceCall(() => academicService.course.byId(props.courseId))
				.then(({ data }) => {
					mainData.course = data;
					mainData.isDeleteAllowed = !mainData.course.isDeleted;
					mainData.isUndeleteAllowed = mainData.course.isDeleted;
				})
				.catch(() => mainData.hasCriticalError = true)
				.finally(() => mainData.isProcessing = false);
		}

		mainData.originalCourse = JSON.parse(JSON.stringify(mainData.course));
	};
	function onBaseBeginSaving(done) { mainData.isProcessing = true; done(); }
	function onBaseClosed(done) {
		resetErrors();
		mainData.isDirty = false;
		mainData.originalSnapshot = null;
		mainData.party = null;
		mainData.referenceData = null;
		emitClosed();
		done();
	}
	async function onBaseDeleting(done) { await deleteCourse(); done(); }
	function onBaseEndSaving(done) { mainData.isProcessing = false; done(); }
	function onBaseItemDeleted(done) { emitCourseUpdated(); done(); }
	function onBaseItemUndeleted(done) { emitCourseUpdated(); done(); }
	function onBaseItemUpdated(done) { emitCourseUpdated(); done(); }
	async function onBaseLoadingItem(done) { await loadCourse(); done(); }
	async function onBaseSaving(done) { await saveCourse(); done(); }
	async function onBaseUndeleting(done) { await reactivateCourse(); done(); }
	async function reactivateCourse() {
		if (!props.courseId)
			return;

		resetErrors();
		mainData.isProcessing = true;

		await executeServiceCall(() => academicService.course.reactivate(props.courseId))
			.catch(error => {
				console.error(error);
				mainData.hasCriticalError = true;
			})
			.finally(() => mainData.isProcessing = false);
	};
	function removeObjective(objective) {
		mainData.course.objectives = mainData.course.objectives.filter((value, index, array) => value != objective);
	};
	function resetErrors() {
		mainData.hasCriticalError = false;
		mainData.validationErrors = [];
	}
	async function saveCourse() {
		resetErrors();

		await executeServiceCall(() => academicService.course.save(mainData.course))
			.then(({ data }) => mainData.course.id = String(data))
			.catch(({ response }) => {
				if (response?.status === 400)
					mainData.validationErrors = getValidationErrorsFromResponse(response);
				else
					mainData.hasCriticalError = true;
			});
	}
	function updateIsDirty() {
		if (!mainData.course || !mainData.originalCourse)
			return;

		const isDirty = hasAnySimpleValueChanged(
			mainData.course,
			mainData.originalCourse,
			["cost","exemptionApprovalDate","exemptionSubmissionDate","explanation","externshipHours","isCertifiedToMeetTwcExemptionCriteria","labHours","lectureHours","name"])
			|| hasArrayChanged(mainData.course.objectives, mainData.originalCourse.objectives);
		mainData.isDirty = isDirty;
	}

	watch(() => mainData.course, () => { updateIsDirty(); }, { deep: true });
</script>

<template>
	<BaseEditDialog subjectName="Course"
		:isFullscreen="true"
		:isDeleteAllowed="mainData.isDeleteAllowed"
		:isUndeleteAllowed="mainData.isUndeleteAllowed"
		:shouldBeEditing="props.shouldBeEditing"
		:isDirty="mainData.isDirty"
		:hasCriticalError="mainData.hasCriticalError"
		:validationErrors="mainData.validationErrors"
		@loadingItem="onBaseLoadingItem"
		@beginSaving="onBaseBeginSaving"
		@saving="onBaseSaving"
		@endSaving="onBaseEndSaving"
		@deleting="onBaseDeleting"
		@itemDeleted="onBaseItemDeleted"
		@undeleting="onBaseUndeleting"
		@itemUndeleted="onBaseItemUndeleted"
		@itemUpdated="onBaseItemUpdated"
		@closed="onBaseClosed">
		<template v-slot:title><CourseIcon/> Course Management</template>
		<div v-if="mainData.course">
			<VRow>
				<VCol class="v-col-12 v-col-md-8">
					<VTextField id="courseName"
						v-model="mainData.course.name"
						:counter="50"
						label="Name"
						hint="The name must be unique among other courses."
						:disabled="mainData.isProcessing"
						required
						autofocus />
				</VCol>
				<VCol class="v-col-12 v-col-md-4">
					<VTextField id="courseCost"
						v-model="mainData.course.cost"
						type="number"
						label="Cost"
						hint="In US dollars.  This must be under $500 to be eligible for TWC exemption."
						prefix="$"
						:disabled="mainData.isProcessing" />
				</VCol>
			</VRow>
			<VRow>
				<VCol class="v-col-12 text-center mb-2">
					<VInput :messages="[getTotalHours(), 'Total hours must be 24 or fewer to be eligible for TWC exemption.']" />
				</VCol>
				<VCol class="v-col-12 v-col-md-4">
					<VTextField id="courseLectureHours"
						v-model="mainData.course.lectureHours"
						type="number"
						label="Lecture Time"
						hint="The number of hours of lecture for the class."
						suffix="hrs"
						:disabled="mainData.isProcessing" />
				</VCol>
				<VCol class="v-col-12 v-col-md-4">
					<VTextField id="courseLabHours"
						v-model="mainData.course.labHours"
						type="number"
						label="Lab Time"
						hint="The number of hours of lab for the class."
						suffix="hrs"
						:disabled="mainData.isProcessing" />
				</VCol>
				<VCol class="v-col-12 v-col-md-4">
					<VTextField id="courseExternshipHours"
						v-model="mainData.course.externshipHours"
						type="number"
						label="Externship Time"
						hint="The number of hours of externship for the class."
						suffix="hrs"
						:disabled="mainData.isProcessing" />
				</VCol>
			</VRow>
			<VRow>
				<VCol class="v-col-12 v-col-md-6">
					<VInput>Objectives</VInput>
					<VTextField id="courseNewObjectiveText" v-model="mainData.newObjectiveText" :counter="100" label="New Objective" :disabled="mainData.isProcessing">
						<template v-slot:append>
							<VChip class="add-objective-action" style="cursor: pointer;" @click="addObjective" :disabled="mainData.isProcessing"><AddSecondaryIcon/>&nbsp;Add</VChip>
						</template>
					</VTextField>
					<div>
						<VTimeline align="start" side="end" density="compact">
							<VTimelineItem v-for="(objective, i) in mainData.course.objectives" :key="i" class="objective" density="compact" size="x-small" rounded>
								<VAlert rounded>
									<VChip color="red" class="remove-objective-action" style="cursor: pointer;" @click="removeObjective(objective)" :disabled="mainData.isProcessing"><RemoveSecondaryIcon/></VChip>
									<span class="text">{{ objective }}</span>
								</VAlert>
							</VTimelineItem>
						</VTimeline>
					</div>
				</VCol>
				<VCol class="v-col-12 v-col-md-6">
					<div class="mb-2">
						<VTextarea id="courseExplanation"
							v-model="mainData.course.explanation"
							label="Explanation"
							rows="4"
							hint="This explanation will be visible to Instructors, Students, and the TWC."
							:disabled="mainData.isProcessing" />
					</div>

					<div class="ma-4">
						<div>
							<VCheckbox id="courseIsCertifiedToMeetTwcExemptionCriteria" v-model="mainData.course.isCertifiedToMeetTwcExemptionCriteria" :disabled="mainData.isProcessing">
								<template v-slot:label>
									I affirm this course meets the exemption criteria for the Texas Workforce Commission.
								</template>
							</VCheckbox>
						</div>
						<div>
							<VTextField id="courseExemptionSubmissionDate"
								v-model="mainData.course.exemptionSubmissionDate"
								type="date"
								label="TWC Exemption Submission Date"
								hint="The date the exemption for this course was submitted to TWC."
								:disabled="mainData.isProcessing" />
						</div>
						<div>
							<VTextField id="courseExemptionApprovalDate"
								v-model="mainData.course.exemptionApprovalDate"
								type="date"
								label="TWC Exemption Approval Date"
								hint="The date the exemption for this course was approved by TWC."
								:disabled="mainData.isProcessing" />
						</div>
					</div>
				</VCol>
			</VRow>
		</div>
	</BaseEditDialog>
</template>
