<script setup>
	import { onMounted, reactive } from "vue";
	import { academicService, executeServiceCall, getValidationErrorsFromResponse } from "@/services";
	import { AddSecondaryIcon } from "@/components";

	// Data
	const mainData = reactive({
		classTimes: [],
		hasError: false,
		isProcessing: false,
		newClassTime: "",
		validationErrors: [],
	});
	const removeClassTimeDialog = reactive({
		classTime: "",
		isPrompting: false,
	});

	// Functions
	async function addClassTime() {
		resetErrors();
		mainData.isProcessing = true;

		await executeServiceCall(() => academicService.setting.addClassTime(mainData.newClassTime))
			.then(({data}) => {
				mainData.classTimes = data;
				mainData.newClassTime = "";
			})
			.catch(error => {
				if (error.response && error.response.status === 400)
					mainData.validationErrors = getValidationErrorsFromResponse(error.response);
				else
					handleError(error);
			})
			.finally(() => mainData.isProcessing = false);
	}
	function handleError(error) {
		console.error(error);
		mainData.hasError = true;
	}
	async function loadClassTimes() {
		resetErrors();
		mainData.isProcessing = true;

		await executeServiceCall(() => academicService.setting.availableClassTimes())
			.then(({data}) => mainData.classTimes = data)
			.catch(error => handleError(error))
			.finally(() => mainData.isProcessing = false);
	}
	function promptToRemoveClassTime(classTime) {
		removeClassTimeDialog.classTime = classTime;
		removeClassTimeDialog.isPrompting = true;
	}
	async function removeClassTime() {
		resetErrors();
		mainData.isProcessing = true;
		removeClassTimeDialog.isPrompting = false;

		await executeServiceCall(() => academicService.setting.removeClassTime(removeClassTimeDialog.classTime))
		.then(({data}) => {
				mainData.classTimes = data;
				removeClassTimeDialog.classTime = "";
			})
			.catch(error => {
				if (error.response && error.response.status === 400)
					mainData.validationErrors = getValidationErrorsFromResponse(error.response);
				else
					handleError(error);
			})
			.finally(() => mainData.isProcessing = false);
	}
	function resetErrors() {
		mainData.hasError = false;
		mainData.validationErrors = [];
	}

	// Startup code
	onMounted(async () => await loadClassTimes());
</script>

<template>
	<div>
		<VProgressLinear v-if="mainData.isProcessing" color="primary" class="loading-indicator" indeterminate />
		<VAlert v-if="mainData.hasError" color="error" class="mb-4 critical-error-message">Something went wrong.  Try again later.</VAlert>
		<VRow>
			<VCol class="v-col-9">
				<VTextField id="newClassTime"
					v-model="mainData.newClassTime"
					label="New Class Time"
					:disabled="mainData.isProcessing" />
			</VCol>
			<VCol class="v-col-3">
				<VBtn color="primary" class="save-class-time-action" @click="addClassTime"><AddSecondaryIcon/> Add Class Time</VBtn>
			</VCol>
		</VRow>
		<div>
			<VChipGroup v-if="mainData.classTimes && mainData.classTimes.length">
				<VChip v-for="(classTime, i) in mainData.classTimes" :key="i"
					class="class-time remove-class-time-action"
					:data-value="classTime"
					size="large"
					:disabled="mainData.isProcessing"
					@click="promptToRemoveClassTime(classTime)">{{ classTime }}</VChip>
			</VChipGroup>
		</div>

		<VDialog v-model="removeClassTimeDialog.isPrompting" title="Remove Class Time" width="auto" scrim="true" class="confirm-cancel-dialog">
			<VCard>
				<VCardText>Are you sure you want to remove the class time '{{ removeClassTimeDialog.classTime }}'?</VCardText>
				<VCardActions>
					<VBtn color="primary" class="yes-action" @click="removeClassTime">Yes</VBtn>
					<VBtn color="secondary" @click="removeClassTimeDialog.isPrompting = false" class="cancel-action">Cancel</VBtn>
				</VCardActions>
			</VCard>
		</VDialog>
	</div>
</template>
