<script>
import { VAlert, VBtn, VTable, VTextField } from "vuetify/components";
import { VProgressLinear } from "vuetify/lib/components/index.mjs";
import { developmentService } from "@/services/developmentService";
import { useRoute } from 'vue-router';

export default {
	name: "DiagnosticsOperationDetailsView",

	components: {
		VAlert, VBtn, VProgressLinear, VTable, VTextField
	},

	data: () => ({
		operationId: "",
		operationEntries: null,
		hasLoadingError: false,
		isLoading: true,
	}),

	async created() {
		const route = useRoute();
		this.operationId = route.params.operationId;
		await this.loadEntries();
	},

	methods: {
		async loadEntries() {
			this.isLoading = true;
			this.hasLoadingError = false;
			this.operationEntries = null;

			await developmentService.diagnostic.operationEntries(this.operationId)
				.then(({ data }) => {
					this.operationEntries = data;
					this.isLoading = false;
				})
				.catch(error => {
					console.error(error);
					this.hasLoadingError = true;
					this.isLoading = false;
				});
		},
	},
}
</script>

<template>
	<div class="container">
		<h1>Operation Details</h1>
		<div class="row">
			<div class="col-6 offset-3 text-center">
				<v-text-field label="Operation ID" v-model="this.operationId">
					<template v-slot:append>
						<v-btn :loading="this.isLoading" @click="loadEntries">Refresh</v-btn>
					</template>
				</v-text-field>
			</div>
		</div>

		<div v-if="this.isLoading">
			<v-progress-linear indeterminate></v-progress-linear>
		</div>
		<div v-if="!this.isLoading">
			<div v-if="this.hasLoadingError" class="loading-error">
				<v-alert type="error">We couldn't retrieve the records.</v-alert>
			</div>
			<div v-if="!this.hasLoadingError">
				<v-table class="table table-striped" density="compact" style="font-size: 0.85rem;">
					<thead>
						<tr>
							<th>Time</th>
							<th>Level</th>
							<th>Category</th>
							<th>Subject</th>
							<th>Details</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="operationEntry in this.operationEntries" :key="operationEntry.id" :data-id="operationEntry.id">
							<td>{{ operationEntry.displayTime }}</td>
							<td>{{ operationEntry.eventType }}</td>
							<td>{{ operationEntry.category }}</td>
							<td>{{ operationEntry.subject }}</td>
							<td v-html="operationEntry.details"></td>
						</tr>
					</tbody>
				</v-table>
			</div>
		</div>
	</div>
</template>
