<script setup>
	import { VCol, VRow, VSelect, VTextarea, VTextField } from "vuetify/components";
	import { watch } from "vue";

	const props = defineProps({
		account: Object,
		courses: Array,
		isDisabled: Boolean
	});

	const accountTypes = [ "Asset", "Liability", "Equity", "Income", "Cost of Goods", "Expense" ];
	const rules = {
		accountNumberFormat: value => !!value.match(/^\d{4}$/g) || "This value must be 4 digits.",
		accountNumberStartingDigit: value => true,
		required: value => !!value || "You must enter a value.",
	};

	function getAccountNumberRules() {
		return [rules.required, rules.accountNumberFormat, rules.accountNumberStartingDigit]
	}

	watch(() => props.account.accountType, () => {
		if (props.account.accountType === "Asset")
			rules.accountNumberStartingDigit = value => !!value.match(/^1/g) || "The account number for an 'Asset' must start with the digit '1'.";
		else if (props.account.accountType === "Liability")
			rules.accountNumberStartingDigit = value => !!value.match(/^2/g) || "The account number for a 'Liability' must start with the digit '2'.";
		else if (props.account.accountType === "Equity")
			rules.accountNumberStartingDigit = value => !!value.match(/^3/g) || "The account number for an 'Equity' must start with the digit '3'.";
		else if (props.account.accountType === "Income")
			rules.accountNumberStartingDigit = value => !!value.match(/^4/g) || "The account number for an 'Income' must start with the digit '4'.";
		else if (props.account.accountType === "Cost of Goods")
			rules.accountNumberStartingDigit = value => !!value.match(/^5/g) || "The account number for a 'Cost of Goods' must start with the digit '5'.";
		else if (props.account.accountType === "Expense")
			rules.accountNumberStartingDigit = value => !!value.match(/^6/g) || "The account number for a 'Expense' must start with the digit '6'.";
		else
			rules.accountNumberStartingDigit = value => true;
});
</script>

<template>
	<div>
		<v-row>
			<v-col class="v-col-12 v-col-md-6">
				<v-select id="accountType" v-model="props.account.accountType" label="Account Type" :items="accountTypes"
					:rules="[rules.required]" :error-messages="props.account.errors.accountType" :disabled="props.isDisabled"></v-select>
			</v-col>
			<v-col class="v-col-12 v-col-md-6">
				<v-text-field id="number" v-model="props.account.number" label="Account Number" hint="This is a 4-digit number."
					:rules="getAccountNumberRules()" :error-messages="props.account.errors.number" :counter="4" :disabled="props.isDisabled"></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="v-col-12">
				<v-text-field id="name" v-model="props.account.name" label="Account Name" hint="The name must be unique."
					:rules="[rules.required]" :error-messages="props.account.errors.name" :counter="50" :disabled="props.isDisabled"></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="v-col-12">
				<v-textarea id="description" v-model="props.account.description" label="Description" hint="An optional description of the account."
					rows="6" :counter="1000" :error-messages="props.account.errors.description" :disabled="props.isDisabled"></v-textarea>
			</v-col>
		</v-row>
	</div>
</template>
