<script>
import { VAlert, VBtn, VBtnToggle, VCard, VCardActions, VCardSubtitle, VCardText, VCardTitle, VCheckbox, VCol, VDialog, VDivider, VHover, VListItem, VProgressLinear, VRow, VSnackbar, VSpacer, VTable, VTooltip } from "vuetify/components";
import { BIconCardList, BIconList, BIconPlusSquare } from "bootstrap-icons-vue";
import { getRouter } from "@/router";
import { accountingService, executeServiceCall } from "@/services";
import { formatAmount } from "@/utils";
import EditAccountDetails from "../components/EditAccountDetails.vue";
import EditTransactionDialog from "../components/EditTransactionDialog.vue";
import AccountIcon from "../components/AccountIcon.vue";

export default {
	name: "AccountManagementView",

	components: {
		VAlert, VBtn, VBtnToggle, VCard, VCardActions, VCardSubtitle, VCardText, VCardTitle, VCheckbox, VCol, VDialog, VDivider, VHover, VListItem, VProgressLinear, VRow, VSnackbar, VSpacer, VTable, VTooltip,
		BIconCardList, BIconList, BIconPlusSquare,
		AccountIcon, EditAccountDetails, EditTransactionDialog,
	},

	async created() {
		await this.loadMainList();
	},

	data: () => ({
		accountEditingInfo: {
			account: null,
			isEditing: false,
			isSaving: false,
			isSnackbarShowing: false,
			title: null,
		},
		mainList: {
			accounts: [],
			hasError: false,
			isLoading: false,
			viewChoice: 0,
		},
		promptDialog: {
			isPromptingUser: false,
			promptConfirmationAction: null,
			userPrompt: "",
		},
		transactionEditingInfo: {
			transactionId: null,
			isEditing: false,
			wasUpdated: false,
		},
	}),

	methods: {
		addAccount() {
			const account = {
				accountType: "",
				number: "",
				name: "",
				description: "",
			};
			this.showAndEditAccount(account, "Add Account");
		},
		cancelEditAccount() {
			this.accountEditingInfo.isSaving = false;
			this.accountEditingInfo.isSnackbarShowing = false;
			this.accountEditingInfo.isEditing = false;
		},
		async completeDeleteAccount(accountId) {
			this.accountEditingInfo.isSaving = false;
			this.accountEditingInfo.isSnackbarShowing = false;
			this.accountEditingInfo.isEditing = false;
			this.mainList.hasError = false;
			this.mainList.isLoading = true;

			await executeServiceCall(() => accountingService.account.delete(accountId))
				.then(async () => { await this.loadMainList(); })
				.catch(() => this.mainList.hasError = true)
				.finally(() => this.mainList.isLoading = false);
		},
		async completeEditAccount() {
			this.accountEditingInfo.isSaving = true;
			await executeServiceCall(() => accountingService.account.save(this.accountEditingInfo.account))
				.then(async () => {
					this.accountEditingInfo.isEditing = false;
					this.accountEditingInfo.isSnackbarShowing = true;
					await this.loadMainList();
				})
				.catch(async ({ response }) => {
					if (response && response.status === 400)
						this.accountEditingInfo.account.errors = response.data;
				})
				.finally(() => this.accountEditingInfo.isSaving = false);
		},
		async deleteAccount(accountId) {
			this.promptUser("Are you sure you want to delete the Account?", async () => await this.completeDeleteAccount(accountId));
		},
		async editAccount(accountId) {
			this.mainList.isLoading = true;
			await executeServiceCall(() => accountingService.account.byId(accountId))
				.then(({ data }) => this.showAndEditAccount(data, "Edit Account"))
				.catch(() => this.mainList.hasError = true)
				.finally(() => this.mainList.isLoading = false);
		},
		formatBalance(value) { return formatAmount(value); },
		getTotalCredits() {
			var sum = 0.00;
			if (this.mainList.accounts)
				this.mainList.accounts.forEach(account => sum += account.creditBalance);
			return sum;
		},
		getTotalDebits() {
			var sum = 0.00;
			if (this.mainList.accounts)
				this.mainList.accounts.forEach(account => sum += account.debitBalance);
			return sum;
		},
		async loadMainList() {
			this.mainList.isLoading = true;
			this.mainList.hasError = false;
			await executeServiceCall(accountingService.account.list)
				.then(({ data }) => this.mainList.accounts = data)
				.catch(() => this.mainList.hasError = true)
				.finally(() => this.mainList.isLoading = false);
		},
		navigateToAccountLedger(accountId) {
			getRouter().push({ name: "accountingLedger", params: { accountId: accountId } });
		},
		async onCloseEditTransactionDialog() {
			this.transactionEditingInfo.isEditing = false;
			if (this.transactionEditingInfo.wasUpdated)
				await this.loadMainList();
		},
		async promptConfirmationAction() {
			this.promptDialog.isPromptingUser = false;
			await this.promptDialog.promptConfirmationAction();
		},
		promptUser(text, confirmationAction) {
			this.promptDialog.userPrompt = text;
			this.promptDialog.promptConfirmationAction = confirmationAction;
			this.promptDialog.isPromptingUser = true;
		},
		showAndEditAccount(account, dialogTitle) {
			account.errors = {};
			this.accountEditingInfo.account = account;
			this.accountEditingInfo.title = dialogTitle;
			this.accountEditingInfo.isSaving = false;
			this.accountEditingInfo.isSnackbarShowing = false;
			this.accountEditingInfo.isEditing = true;
		},
	},
}
</script>

<template>
	<div class="container my-4">
		<v-row>
			<v-col class="v-col-auto">
				<h1 class="col-auto"><AccountIcon></AccountIcon> Chart of Accounts</h1>
			</v-col>
			<v-spacer></v-spacer>
			<v-col class="v-col-auto text-center">
				<v-btn color="primary" variant="flat" class="ma-2 create-transaction-action" :disabled="this.mainList.isLoading" @click="this.transactionEditingInfo.isEditing = true"><BIconPlusSquare class="mr-2"/> New Transaction</v-btn>
			</v-col>
			<v-spacer></v-spacer>
			<v-col class="v-col-auto text-right">
				<v-btn color="primary" variant="flat" size="large" class="ma-2 create-account-action" :disabled="this.mainList.isLoading" @click="addAccount()"><BIconPlusSquare class="mr-2"/>Add Account</v-btn>
			</v-col>
		</v-row>

		<div v-if="this.mainList.isLoading">
			<v-progress-linear indeterminate color="primary"></v-progress-linear>
		</div>
		<div :class="this.mainList.isLoading ? 'disabled row' : 'row'">
			<div v-if="!this.mainList.isLoading && this.mainList.accounts.length === 0">
				<v-alert class="text-center">We couldn't find any accounts.  Click the <strong>Add Account</strong> button to create a new one.</v-alert>
			</div>
			<div v-if="this.mainList.accounts.length > 0">
				<v-table density="compact">
					<thead>
						<th>Number</th>
						<th>Name</th>
						<th>Type</th>
						<th>Debit</th>
						<th>Credit</th>
					</thead>
					<tbody>
						<tr v-for="account in this.mainList.accounts">
							<v-tooltip text="Click to Edit this Account." location="bottom">
								<template v-slot:activator="{ props }">
									<td v-bind="props" class="clickable edit-account-action" @click="editAccount(account.id)" :data-id="account.id">{{ account.number }}</td>
								</template>
							</v-tooltip>
							<v-tooltip text="Click to View the Account Ledger." location="bottom">
								<template v-slot:activator="{ props }">
									<td v-bind="props" class="clickable view-account-ledger-action" @click="navigateToAccountLedger(account.id)">{{ account.name }}</td>
								</template>
							</v-tooltip>
							<v-tooltip text="Click to Edit this Account." location="bottom">
								<template v-slot:activator="{ props }">
									<td v-bind="props" class="clickable edit-account-action" @click="editAccount(account.id)" :data-id="account.id">{{ account.accountType }}</td>
								</template>
							</v-tooltip>
							<v-tooltip text="Click to View the Account Ledger." location="bottom">
								<template v-slot:activator="{ props }">
									<td v-bind="props" class="text-right clickable view-account-ledger-action" @click="navigateToAccountLedger(account.id)">{{ this.formatBalance(account.debitBalance) }}</td>
								</template>
							</v-tooltip>
							<v-tooltip text="Click to View the Account Ledger." location="bottom">
								<template v-slot:activator="{ props }">
									<td v-bind="props" class="text-right clickable view-account-ledger-action" @click="navigateToAccountLedger(account.id)">{{ this.formatBalance(account.creditBalance) }}</td>
								</template>
							</v-tooltip>
						</tr>
					</tbody>
					<tfoot>
						<tr>
							<th></th>
							<th></th>
							<th></th>
							<th class="text-right">{{ this.formatBalance(this.getTotalDebits()) }}</th>
							<th class="text-right">{{ this.formatBalance(this.getTotalCredits()) }}</th>
						</tr>
					</tfoot>
				</v-table>
			</div>
		</div>

		<v-dialog v-model="this.accountEditingInfo.isEditing" width="50%" :no-click-animation="true" scrim="true">
			<v-card>
				<v-card-title><AccountIcon></AccountIcon> {{ this.accountEditingInfo.title }}</v-card-title>
				<v-card-text><EditAccountDetails :account="this.accountEditingInfo.account" :isDisabled="this.accountEditingInfo.isSaving" /></v-card-text>
				<v-card-actions>
					<v-btn v-if="!this.accountEditingInfo.isSaving && this.accountEditingInfo.account.id && this.accountEditingInfo.account.isDeletable" color="danger" class="mx-2 delete-account-action" @click="deleteAccount(this.accountEditingInfo.account.id)">Delete</v-btn>
					<v-spacer></v-spacer>
					<v-btn v-if="!this.accountEditingInfo.isSaving" color="primary" class="mx-2 save-account-action" @click="completeEditAccount">Save</v-btn>
					<v-btn v-if="!this.accountEditingInfo.isSaving" color="secondary" class="mx-2" @click="cancelEditAccount">Cancel</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-snackbar v-model="this.accountEditingInfo.isSnackbarShowing" timeout="4000" rounded="pill">
			<span class="message">Account changes were saved successfully.</span>
			<template v-slot:actions>
				<v-btn color="primary" variant="text" @click="this.accountEditingInfo.isSnackbarShowing = false">Close</v-btn>
			</template>
		</v-snackbar>

		<v-dialog v-model="promptDialog.isPromptingUser" width="auto" scrim="true">
			<v-card>
				<v-card-text>{{ this.promptDialog.userPrompt }}</v-card-text>
				<v-card-actions>
					<v-btn color="primary" class="yes-action" @click="promptConfirmationAction">Yes</v-btn>
					<v-btn color="secondary" @click="this.promptDialog.isPromptingUser = false">Cancel</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-snackbar v-model="this.mainList.hasError" timeout="-1" rounded="pill" color="danger">
			<span class="error-message">Something went wrong.  Try again later.</span>
			<template v-slot:actions>
				<v-btn color="primary" variant="text" @click="this.mainList.hasError = false">Close</v-btn>
			</template>
		</v-snackbar>

		<EditTransactionDialog :transactionId="this.transactionEditingInfo.transactionId"
			:shouldBeEditing="this.transactionEditingInfo.isEditing"
			@closed="onCloseEditTransactionDialog"
			@transactionUpdated="this.transactionEditingInfo.wasUpdated = true" />
	</div>
</template>

<style scoped>
	.v-theme--light.v-table tbody td.clickable:hover {
		background-color: rgba(0, 0, 0, 0.1);
		cursor: pointer;
	}
</style>
