<script setup>
	import BaseIcon from "./BaseIcon.vue";
	import iconClassNames from "./iconClassNames";
	import { ref, onMounted } from "vue";

	const base = ref(null);
	const props = defineProps({ fontSize: String });

	onMounted(() => {
		base.value.className = iconClassNames.edit;
		base.value.fontSize = props.fontSize;
	});
</script>

<template>
	<BaseIcon ref="base"/>
</template>
