<script setup>
	import { nextTick, onMounted, reactive, watch } from "vue";
	import { AddPrimaryIcon } from "@/components";
	import { EditProspectDialog, ProspectIcon } from "@/modules/academic/components";
	import { academicService, executeServiceCall } from "@/services";

	// Data
	const changeStatusDialog = reactive({
		isActive: false,
		onYesAction: null,
		status: "",
	});
	const editProspectDialog = reactive({
		hasChanges: false,
		isEditing: false,
		prospectId: null,
		onClosed: async function () {
			editProspectDialog.isEditing = false;
			if (editProspectDialog.hasChanges) {
				await nextTick(async () => {
					await loadProspects();
					editProspectDialog.hasChanges = false;
				});
			}
		},
	});
	const followUpDateDialog = reactive({
		followUpDate: "",
		isActive: false,
	});
	const mainData = reactive({
		allProspects: null,
		filterChoice: 0,
		hasError: false,
		isProcessing: false,
		prospects: null,
		selectedProspectIds: [],
	});

	// Functions
	function addProspect() {
		editProspectDialog.isEditing = false;
		editProspectDialog.prospectId = null;
		editProspectDialog.isEditing = true;
	};
	function editProspect(prospectId) {
		editProspectDialog.isEditing = false;
		editProspectDialog.prospectId = prospectId;
		editProspectDialog.isEditing = true;
	};
	function filterProspects(text) {
		if (!text) {
			if (mainData.prospects.length !== mainData.allProspects)
				mainData.prospects = mainData.allProspects;
		}
		else {
			const regex = new RegExp(text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), "i");
			mainData.prospects = mainData.allProspects
				.filter(prospect => regex.test(prospect.name) || regex.test(prospect.emailAddress));
		}
	};
	async function loadProspects() {
		mainData.hasError = false;
		mainData.isProcessing = true;

		let action = null;
		switch (mainData.filterChoice) {
			case 4:
				action = academicService.prospect.listConvertedToStudent;
				break;
			case 3:
				action = academicService.prospect.listDoNotContact;
				break;
			case 2:
				action = academicService.prospect.listStale;
				break;
			case 1:
				action = academicService.prospect.listFollowUpToday;
				break;
			case 0:
				action = academicService.prospect.list;
				break;
		}
		await executeServiceCall(action)
			.then(({ data }) => {
				mainData.selectedProspectIds = [];
				mainData.prospects = data.prospects;
				mainData.allProspects = data.prospects;
			})
			.catch(() => mainData.hasError = true)
			.finally(() => mainData.isProcessing = false);
	};
	async function onChangeStatusDialogYesAction() {
		changeStatusDialog.isActive = false;
		await changeStatusDialog.onYesAction();
	};
	function promptToSetAsActive() {
		showChangeStatusDialog("Active", setAsActive)
	};
	function promptToSetAsConvertedToStudent() {
		showChangeStatusDialog("Converted to Student", setAsConvertedToStudent);
	};
	function promptToSetAsDoNotContact() {
		showChangeStatusDialog("Do Not Contact", setAsDoNotContact);
	};
	function promptToSetFollowUpDate() {
		followUpDateDialog.isActive = true;
	};
	async function setAsActive() {
		mainData.isProcessing = true;

		await executeServiceCall(() => academicService.prospect.setAsActive({ prospectIds: mainData.selectedProspectIds }))
			.then(async () => await loadProspects())
			.catch(() => {
				mainData.hasError = true;
				mainData.isProcessing = false;
			});
	};
	async function setAsConvertedToStudent() {
		mainData.isProcessing = true;

		await executeServiceCall(() => academicService.prospect.setAsConvertedToStudent({ prospectIds: mainData.selectedProspectIds }))
			.then(async () => await loadProspects())
			.catch(() => {
				mainData.hasError = true;
				mainData.isProcessing = false;
			});
	};
	async function setAsDoNotContact() {
		mainData.isProcessing = true;

		await executeServiceCall(() => academicService.prospect.setAsDoNotContact({ prospectIds: mainData.selectedProspectIds }))
		.then(async () => await loadProspects())
			.catch(() => {
				mainData.hasError = true;
				mainData.isProcessing = false;
			});
	};
	async function setFollowUpDate() {
		followUpDateDialog.isActive = false;
		const followUpDate = followUpDateDialog.followUpDate;
		mainData.isProcessing = true;

		await executeServiceCall(() => academicService.prospect.setFollowUpDate({ followUpDate: followUpDate, prospectIds: mainData.selectedProspectIds }))
			.then(async () => await loadProspects())
			.catch(() => {
				mainData.hasError = true;
				mainData.isProcessing = false;
			});
	};
	function showChangeStatusDialog(status, onYesAction) {
		changeStatusDialog.status = status;
		changeStatusDialog.onYesAction = onYesAction;
		changeStatusDialog.isActive = true;
	};

	// Life-Cycle Event Handlers
	onMounted(async () => {
		await loadProspects();
	});

	// Watches
	watch(() => mainData.filterChoice, async () => {
		await loadProspects();
	});
</script>

<template>
	<div class="container my-4">
		<VRow>
			<VCol class="v-col-auto">
				<h1 class="col-auto"><ProspectIcon fontSize="48px" /> Prospects</h1>
			</VCol>
			<VSpacer></VSpacer>
			<VCol class="v-col-auto text-center">
				<VBtnToggle v-model="mainData.filterChoice" :disabled="mainData.isProcessing">
					<VBtn>Active</VBtn>
					<VBtn class="filter-follow-up-today-action">Today</VBtn>
					<VBtn class="filter-stale-action">Stale</VBtn>
					<VBtn class="filter-do-not-contact-action">No Contact</VBtn>
					<VBtn class="filter-converted-to-student-action">Students</VBtn>
				</VBtnToggle>
			</VCol>
			<VSpacer></VSpacer>
			<VCol class="v-col-auto text-right">
				<VBtn color="primary"
					variant="flat"
					size="large"
					class="ma-2 create-prospect-action"
					:disabled="mainData.isProcessing"
					@click="addProspect"><AddPrimaryIcon/> Add Prospect</VBtn>
			</VCol>
		</VRow>

		<VProgressLinear v-if="mainData.isProcessing" indeterminate color="primary" class="loading-indicator" />
		<VAlert v-if="mainData.hasError" color="error" class="mb-4 critical-error-message">Something went wrong.  Try again later.</VAlert>

		<div v-if="!!mainData.allProspects">
			<VRow>
				<VCol class="col-12 col-md-6">
					<VTextField id="filterText"
						label="Filter"
						:clearable="true"
						placeholder="Filter by Name or Email Address"
						@update:model-value="filterProspects" />
				</VCol>
				<VCol class="col-12 col-md-6 text-right">
					<VBtn v-if="mainData.filterChoice <= 2"
						color="primary"
						variant="flat"
						class="ma-2 set-follow-up-date-action"
						:disabled="mainData.isProcessing || mainData.selectedProspectIds.length === 0"
						@click="promptToSetFollowUpDate">
						Follow-Up
					</VBtn>
					<VBtn v-if="mainData.filterChoice >= 3"
						color="primary"
						variant="flat"
						class="ma-2 set-active-action"
						title="Change status to 'Active'"
						:disabled="mainData.isProcessing || mainData.selectedProspectIds.length === 0"
						@click="promptToSetAsActive">
						Active
					</VBtn>
					<VBtn v-if="mainData.filterChoice !== 3"
						color="primary"
						variant="flat"
						class="ma-2 set-do-not-contact-action"
						title="Change status to 'Do Not Contact'"
						:disabled="mainData.isProcessing || mainData.selectedProspectIds.length === 0"
						@click="promptToSetAsDoNotContact">
						Do Not Contact
					</VBtn>
					<VBtn v-if="mainData.filterChoice !== 4"
						color="primary"
						variant="flat"
						class="ma-2 set-converted-to-student-action"
						title="Change status to 'Converted to Student'"
						:disabled="mainData.isProcessing || mainData.selectedProspectIds.length === 0"
						@click="promptToSetAsConvertedToStudent">
						Convert to Student
					</VBtn>
				</VCol>
			</VRow>
			<VAlert v-if="mainData.prospects.length === 0" class="mb-4">No prospects were found.</VAlert>
			<div v-else>
				<VTable class="table-striped" density="compact">
					<thead>
						<tr>
							<th></th>
							<th>Name</th>
							<th>State</th>
							<th>Phone</th>
							<th>Email</th>
							<th>Last Contacted</th>
							<th>Follow-Up Date</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(prospect, i) in mainData.prospects" :key="i" :data-id="prospect.id" class="prospect">
							<td>
								<VCheckbox v-model="mainData.selectedProspectIds"
									:value="prospect.id"
									class="selector"
									density="compact"
									hide-details
									:disabled="mainData.isProcessing" />
							</td>
							<td class="clickable" @click="editProspect(prospect.id)">{{ prospect.name }}</td>
							<td class="clickable" @click="editProspect(prospect.id)">{{ prospect.state }}</td>
							<td class="clickable" @click="editProspect(prospect.id)">{{ prospect.phoneNumber }}</td>
							<td class="clickable" @click="editProspect(prospect.id)">{{ prospect.emailAddress }}</td>
							<td class="clickable" @click="editProspect(prospect.id)">{{ prospect.lastContactDate }}</td>
							<td class="clickable" @click="editProspect(prospect.id)">{{ prospect.followUpDate }}</td>
						</tr>
					</tbody>
				</VTable>
			</div>
		</div>

		<EditProspectDialog :prospectId="editProspectDialog.prospectId"
			:shouldBeEditing="editProspectDialog.isEditing"
			@prospectUpdated="editProspectDialog.hasChanges = true"
			@closed="editProspectDialog.onClosed" />

		<VDialog v-model="followUpDateDialog.isActive"
			width="50%"
			persistent>
			<VCard>
				<VCardTitle>Set Follow-Up Date</VCardTitle>
				<VCardText>
					<VTextField v-model="followUpDateDialog.followUpDate"
						id="multipleProspectsFollowUpDate"
						type="date"
						label="Follow-Up Date" />
				</VCardText>
				<VCardActions>
					<VSpacer />
					<VBtn color="primary"
						variant="flat"
						class="save-action"
						@click="setFollowUpDate">
						Set Date
					</VBtn>
					<VBtn color="secondary"
						variant="flat"
						@click="followUpDateDialog.isActive = false">
						Cancel
					</VBtn>
					<VSpacer />
				</VCardActions>
			</VCard>
		</VDialog>

		<VDialog v-model="changeStatusDialog.isActive"
			width="50%">
			<VCard>
				<VCardTitle>Set as "{{ changeStatusDialog.status }}"</VCardTitle>
				<VCardText>
					Are you sure you want to set all of these contacts as "{{ changeStatusDialog.status }}"?
				</VCardText>
				<VCardActions>
					<VSpacer />
					<VBtn color="primary"
						variant="flat"
						class="yes-action"
						@click="onChangeStatusDialogYesAction">
						Yes
					</VBtn>
					<VBtn color="secondary"
						variant="flat"
						@click="changeStatusDialog.isActive = false">
						Cancel
					</VBtn>
					<VSpacer />
				</VCardActions>
			</VCard>
		</VDialog>
	</div>
</template>

<style scoped>
	.v-theme--light.v-table.table-striped tbody tr:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}
	.clickable {
		cursor: pointer;
	}
</style>
