<script setup>
	import { computed, reactive, watch } from "vue";
	import { academicService, executeServiceCall, getValidationErrorsFromResponse } from "@/services";
	import { hasAnySimpleValueChanged } from "@/utils";
	import { BaseEditDialog } from "@/components";
	import { ProspectIcon } from "@/modules/academic/components";

	// Public Interface
	const props = defineProps({
		prospectId: { type: String, required: false },
		shouldBeEditing: { type: Boolean, required: true },
	});
	const emit = defineEmits([ "closed", "prospectUpdated" ]);

	// Data
	const mainData = reactive({
		hasCriticalError: false,
		ignoreIsDirty: false,
		isDirty: false,
		isEditing: false,
		isProcessing: false,
		isShowing: false,
		originalProspect: null,
		prospect: null,
		stateItems: [
			{ value: 0, title: "Active" },
			{ value: 99, title: "Converted to Student" },
			{ value: 999, title: "Do Not Contact" },
		],
		validationErrors: [],
	});
	const rules = {
		required: value => !!value || "This value is required.",
	};
	const phoneNumberOrEmailAddressRequiredRule = computed({
		get() {
			return (!!mainData.prospect.phoneNumber || !!mainData.prospect.emailAddress) || "Phone Number or Email Address is required.";
		}
	});

	// Functions
	function emitClosed() { emit("closed"); }
	function emitProspectUpdated() { emit("prospectUpdated", mainData.prospect.id); }
	async function loadProspect() {
		resetErrors();

		if (!props.prospectId) {
			mainData.prospect = {
				name: "",
				phoneNumber: "",
				referralMethod: "",
				emailAddress: "",
				mailingAddress: "",
				importantNotes: "",
				journalDateTime: "",
				journalText: "",
				journalEntries: [],
				followUpDate: null,
				state: 0,
			};
			mainData.isProcessing = false;
		}
		else {
			await executeServiceCall(() => academicService.prospect.byId(props.prospectId))
				.then(({ data }) => mainData.prospect = data)
				.catch(() => mainData.hasCriticalError = true)
				.finally(() => mainData.isProcessing = false);
		}

		const now = new Date(Date.now());
		mainData.prospect.journalDateTime = `${now.getFullYear()}-${("0" + now.getMonth() + 1).slice(-2)}-${("0" + now.getDate()).slice(-2)}T${("0" + now.getHours()).slice(-2)}:${("0" + now.getMinutes()).slice(-2)}`;
		mainData.originalProspect = JSON.parse(JSON.stringify(mainData.prospect));
	};
	function onBaseBeginSaving(done) { mainData.isProcessing = true; done(); }
	function onBaseClosed(done) {
		resetErrors();
		mainData.isDirty = false;
		mainData.originalSnapshot = null;
		mainData.party = null;
		mainData.referenceData = null;
		emitClosed();
		done();
	}
	function onBaseEndSaving(done) { mainData.isProcessing = false; done(); }
	function onBaseItemUpdated(done) { emitProspectUpdated(); done(); }
	async function onBaseLoadingItem(done) { await loadProspect(); done(); }
	async function onBaseSaving(done) { await saveProspect(); done(); }
	function resetErrors() {
		mainData.hasCriticalError = false;
		mainData.validationErrors = [];
	}
	async function saveProspect() {
		resetErrors();

		await executeServiceCall(() => academicService.prospect.save(mainData.prospect))
			.then(({ data }) => mainData.prospect.id = String(data))
			.catch(({ response }) => {
				if (response?.status === 400)
					mainData.validationErrors = getValidationErrorsFromResponse(response);
				else
					mainData.hasCriticalError = true;
			});
	}
	function updateIsDirty() {
		if (!mainData.prospect || !mainData.originalProspect)
			return;

		const isDirty = hasAnySimpleValueChanged(
			mainData.prospect,
			mainData.originalProspect,
			["name","phoneNumber","referralMethod","emailAddress","importantNotes","mailingAddress","journalDateTime","journalText","followUpDate","state"]);
		mainData.isDirty = isDirty;
	}

	watch(() => mainData.prospect, () => { updateIsDirty(); }, { deep: true });
</script>

<template>
	<BaseEditDialog subjectName="Prospect"
		:isFullscreen="true"
		:isDeleteAllowed="false"
		:shouldBeEditing="props.shouldBeEditing"
		:isDirty="mainData.isDirty"
		:hasCriticalError="mainData.hasCriticalError"
		:validationErrors="mainData.validationErrors"
		@loadingItem="onBaseLoadingItem"
		@beginSaving="onBaseBeginSaving"
		@saving="onBaseSaving"
		@endSaving="onBaseEndSaving"
		@itemUpdated="onBaseItemUpdated"
		@closed="onBaseClosed">
		<template v-slot:title><ProspectIcon/> Prospect Management</template>
		<div v-if="mainData.prospect">
			<VRow>
				<VCol class="v-col-12 v-col-md-6">
					<VSheet border rounded class="pa-4 mt-4">
						<h5>Main Info</h5>
						<div>
							<VTextField v-model="mainData.prospect.name"
								id="prospectName"
								label="Name"
								:rules="[rules.required]"
								:disabled="mainData.isProcessing"
								autofocus />
						</div>
						<div>
							<VTextField v-model="mainData.prospect.phoneNumber"
								id="prospectPhoneNumber"
								label="Phone Number"
								:rules="[phoneNumberOrEmailAddressRequiredRule]"
								:disabled="mainData.isProcessing" />
						</div>
						<div>
							<VTextField v-model="mainData.prospect.referralMethod"
								id="prospectReferralMethod"
								label="How did they hear about us?"
								:disabled="mainData.isProcessing" />
						</div>
						<div>
							<VTextField v-model="mainData.prospect.emailAddress"
								id="prospectEmailAddress"
								label="Email Address"
								:rules="[phoneNumberOrEmailAddressRequiredRule]"
								:disabled="mainData.isProcessing" />
						</div>
						<div>
							<VTextarea v-model="mainData.prospect.importantNotes"
								id="prospectImportantNotes"
								label="Important Notes"
								:disabled="mainData.isProcessing" />
						</div>
						<div>
							<VTextarea v-model="mainData.prospect.mailingAddress"
								id="prospectMailingAddress"
								label="Mailing Address"
								:disabled="mainData.isProcessing" />
						</div>
					</VSheet>
				</VCol>
				<VCol class="v-col-12 v-col-md-6">
					<VSheet border rounded class="pa-4 mt-4">
						<h5>Status</h5>
						<div>
							<VSelect v-model="mainData.prospect.state"
								id="prospectState"
								:items="mainData.stateItems"
								label="State"
								:disabled="mainData.isProcessing" />
						</div>
						<div>
							<VTextField v-model="mainData.prospect.followUpDate"
								id="prospectFollowUpDate"
								type="date"
								label="Follow-Up Date"
								:clearable="true"
								:disabled="mainData.isProcessing" />
						</div>
					</VSheet>
					<VSheet border rounded class="pa-4 mt-4">
						<h5>New Journal Entry</h5>
						<div>
							<VTextField v-model="mainData.prospect.journalDateTime"
								id="prospectJournalDateTime"
								type="datetime-local"
								label="Journal Entry Date/Time"
								:disabled="mainData.isProcessing" />
						</div>
						<div>
							<VTextarea v-model="mainData.prospect.journalText"
								id="prospectJournalText"
								label="Journal Entry Text"
								:disabled="mainData.isProcessing" />
						</div>
						<h5>Journal History</h5>
						<VAlert v-if="mainData.prospect.journalEntries.length === 0">No journal entries have been created yet.</VAlert>
						<div v-else>
							<VTimeline side="end" align="start" density="compact">
								<VTimelineItem v-for="(journalEntry, i) in mainData.prospect.journalEntries" :key="i" size="small">
									<VAlert :title="journalEntry.dateTime">{{ journalEntry.text }}</VAlert>
								</VTimelineItem>
							</VTimeline>
						</div>
					</VSheet>
				</VCol>
			</VRow>
		</div>
	</BaseEditDialog>
</template>
