<script setup>
	import { onMounted, reactive, watch } from "vue";
	import { CloseIcon, RefreshIcon } from "@/components";
	import { executeServiceCall, selfServiceService } from "@/services";
	import { formatAmount } from "@/utils";
	import { PayStubIcon, ViewPayStubDetails } from "../components";

	const mainData = reactive({
		allPayStubs: null,
		hasError: false,
		isProcessing: false,
		payStubPageNumber: 1,
		payStubs: null,
	});
	const pageSize = 5;
	const viewPayStub = reactive({
		isShowing: false,
		payStubId: null,
	});

	function bySortDateDescending(a, b) {
		if (a.sortDate > b.sortDate) return -1;
		if (a.sortDate < b.sortDate) return 1;
		return 0;
	}
	function getPageCount() {
		return Math.ceil((mainData.allPayStubs?.length || 0) / pageSize);
	}
	async function loadPayStubs() {
		mainData.hasError = false;
		mainData.isProcessing = true;

		await executeServiceCall(selfServiceService.payStub.list)
			.then(({ data }) => {
				let allPayStubs = data;
				allPayStubs.sort(bySortDateDescending);
				mainData.allPayStubs = allPayStubs;
			})
			.catch(error => { console.error(error); mainData.hasError = true; })
			.finally(() => mainData.isProcessing = false);
	}
	function updatePayStubPage() {
		if (!mainData.allPayStubs) {
			mainData.payStubs = null;
			return;
		}

		const firstIndex = (mainData.payStubPageNumber - 1) * pageSize;
		const lastIndex = firstIndex + pageSize - 1;
		mainData.payStubs = mainData.allPayStubs.filter((payStub, i) => firstIndex <= i && i <= lastIndex);
	}
	function viewPayStubDetails(payStubId) {
		viewPayStub.isShowing = false;
		viewPayStub.payStubId = null;
		viewPayStub.payStubId = payStubId;
		viewPayStub.isShowing = true;
	}

	watch(() => mainData.allPayStubs, () => {
		updatePayStubPage();
	});
	watch(() => mainData.payStubPageNumber, () => {
		updatePayStubPage();
	});

	onMounted(async () => {
		await loadPayStubs();
	});
</script>

<template>
	<div class="container">
		<VRow>
			<VCol class="v-col-12 v-col-md-6">
				<h1><PayStubIcon fontSize="48px"/> My Pay Stubs</h1>
			</VCol>
			<VCol class="v-col-12 v-col-md-6 text-right">
				<VBtn color="primary"
					class="mt-2 refresh-pay-stubs-action"
					:disabled="mainData.isProcessing"
					@click="loadPayStubs">
					<RefreshIcon/>
				</VBtn>
			</VCol>
		</VRow>
		<VProgressLinear
			v-if="mainData.isProcessing"
			color="primary"
			class="processing-indicator"
			indeterminate/>
		<VAlert v-if="mainData.hasError"
			color="error"
			class="mb-4 critical-error-message">Something went wrong.  Try again later.</VAlert>
		<template v-if="mainData.payStubs">
			<VAlert v-if="mainData.payStubs.length === 0"
				class="no-pay-stubs-message">You have no pay stubs yet.  Once you get your first paycheck, your stubs will appear here.</VAlert>
			<template v-else>
				<VPagination v-model="mainData.payStubPageNumber"
					:length="getPageCount()"
					rounded="circle"
					class="pager"
					:disabled="mainData.isProcessing" />
				<VTable class="table-striped">
					<thead>
						<tr>
							<th>Date</th>
							<th>Gross</th>
							<th>Net</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(payStub, i) in mainData.payStubs"
							:key="i"
							class="pay-stub clickable"
							:data-id="payStub.id"
							@click="viewPayStubDetails(payStub.id)">
							<td class="display-date">{{ payStub.displayDate }}</td>
							<td class="text-right gross-pay">{{ formatAmount(payStub.grossPay) }}</td>
							<td class="text-right net-pay">{{ formatAmount(payStub.netPay) }}</td>
						</tr>
					</tbody>
				</VTable>

				<VDialog v-model="viewPayStub.isShowing"
					width="80%"
					scrim="true">
					<VCard>
						<VToolbar color="primary">
							<VToolbarTitle><PayStubIcon/> Pay Stub Details</VToolbarTitle>
							<VSpacer />
							<VBtn title="Close"
								@click="viewPayStub.isShowing = false">
								<CloseIcon/>
							</VBtn>
						</VToolbar>
						<VCardText>
							<ViewPayStubDetails :payStubId="viewPayStub.payStubId"/>
						</VCardText>
					</VCard>
				</VDialog>
			</template>
		</template>
	</div>
</template>

<style scoped>
	.clickable {
		cursor: pointer;
	}
	.v-theme--light.v-table.table-striped tbody tr:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}
</style>
