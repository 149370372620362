<script setup>
	import { nextTick, onMounted, reactive, watch } from "vue";
	import { academicService, executeServiceCall } from "@/services";
	import { AddPrimaryIcon, CardsViewIcon, ListViewIcon } from "@/components";
	import { ClassIcon, EditClassDialog } from "../components";

	const editClassDialog = reactive({
		classId: null,
		courseName: null,
		hasChanges: false,
		isEditing: false,
	});
	const mainData = reactive({
		courses: [],
		hasError: false,
		includeDeletedClasses: false,
		isLoading: false,
		viewChoice: 0,
	});

	// Functions
	async function addClass(courseName) {
		editClassDialog.courseName = courseName;
		await editClass(null);
	};
	async function editClass(classId) {
		let resetPromise = null;
		if (editClassDialog.isEditing) {
			resetPromise = new Promise(resolve => {
				editClassDialog.classId = null;
				editClassDialog.isEditing = false;
				resolve();
			});
		}
		else {
			resetPromise = new Promise(resolve => resolve());
		}

		await resetPromise
			.then(async () => {
				await nextTick(() => {
					editClassDialog.classId = classId;
					editClassDialog.isEditing = true;
				});
			});
	};
	function flattenClasses() {
		let classes = [];
		for (let i = 0; i < mainData.courses.length; i++) {
			for (let j = 0; j < mainData.courses[i].classes.length; j++) {
				const lucentaryClass = mainData.courses[i].classes[j];
				lucentaryClass.courseName = mainData.courses[i].name;
				classes.push(lucentaryClass);
			}
		}
		return classes;
	};
	async function loadClassList() {
		mainData.isLoading = true;
		mainData.hasError = false;

		await executeServiceCall(() => academicService.class.coursesAndClasses(mainData.includeDeletedClasses))
			.then(({ data }) => mainData.courses = data)
			.catch(async error => {
				console.error(error);
				mainData.hasError = true;
			})
			.finally(() => mainData.isLoading = false);
	};
	async function onEditClassDialogClosed() {
		editClassDialog.classId = null;
		editClassDialog.isEditing = false;
		if (editClassDialog.hasChanges) {
			await nextTick(async () => {
				await loadClassList();
				editClassDialog.hasChanges = false;
			});
		}
	};

	watch(() => mainData.includeDeletedClasses, async () => {
		await loadClassList();
	});

	onMounted(async () => await loadClassList());
</script>

<template>
	<div class="container my-4">
		<div class="row">
			<div class="col">
				<div class="row">
					<h1 class="col-auto"><ClassIcon fontSize="48px" /> Classes</h1>
					<div class="col-auto">
						<VCheckbox id="includeDeleted" v-model="mainData.includeDeletedClasses" label="Include Deleted?" class="view-deleted-action" :disabled="mainData.isLoading" />
					</div>
				</div>
			</div>
			<div class="col text-center">
				<VBtnToggle v-model="mainData.viewChoice" :disabled="mainData.isLoading">
					<VBtn><CardsViewIcon /> Course View</VBtn>
					<VBtn class="view-list-action"><ListViewIcon /> Class List</VBtn>
				</VBtnToggle>
			</div>
			<div class="col text-right">
				<VBtn color="primary" variant="flat" size="large" class="ma-2 create-class-action" :disabled="mainData.isLoading" @click="addClass(null)"><AddPrimaryIcon /> Add Class</VBtn>
			</div>
		</div>

		<div v-if="mainData.isLoading">
			<VProgressLinear class="loading-indicator" indeterminate color="primary" />
		</div>

		<VAlert v-if="mainData.hasError" color="error" class="mb-4 critical-error-message">Something went wrong.  Try again later.</VAlert>

		<div :class="mainData.isLoading ? 'disabled row' : 'row'">
			<div v-if="!mainData.isLoading && mainData.courses.length === 0">
				<VAlert class="text-center">We couldn't find any classes.  Click the <strong>Add Class</strong> button to create a new one.</VAlert>
			</div>
			<div v-if="mainData.courses.length > 0">
				<div v-if="mainData.viewChoice === 0" class="row">
					<VCard v-for="(course, i) in mainData.courses" :key="i" class="col-12 col-md-5 ma-4 course" elevation="4" :data-name="course.name">
						<VCardTitle>
							<VRow>
								<VCol class="v-col-auto">
									{{ course.name }}
									<VCardSubtitle>
										<div v-if="course.objectivesUnmetCount > 0" class="text-danger"><strong>{{ course.objectivesUnmetCount }} objectives unmet</strong></div>
									</VCardSubtitle>
								</VCol>
								<VSpacer />
								<VCol class="v-col-auto"><VBtn color="primary" size="small" variant="flat" class="add-course-class-action" :disabled="mainData.isLoading" @click="addClass(course.name)"><AddPrimaryIcon /> Add Class</VBtn></VCol>
							</VRow>
						</VCardTitle>
						<VDivider />
						<VListItem v-for="courseClass in course.classes" :title="courseClass.name" @click="editClass(courseClass.id)" class="mb-2 edit-class-action class" :data-id="courseClass.id">
							<VCardSubtitle>
								<span :class="courseClass.objectiveCount === 0 ? 'text-danger text-strong' : undefined">{{courseClass.objectiveCount}} objectives</span>
									| 
								<span :class="courseClass.materialCount === 0 ? 'text-danger text-strong' : undefined">{{courseClass.materialCount}} materials</span>
							</VCardSubtitle>
						</VListItem>
					</VCard>
				</div>
				<div v-if="mainData.viewChoice === 1">
					<VTable class="table-striped">
						<thead>
							<th>Class</th>
							<th>Course</th>
							<th>Objectives</th>
							<th>Materials</th>
						</thead>
						<tbody>
							<tr v-for="courseClass in flattenClasses()" :key="courseClass.id" @click="editClass(courseClass.id)" class="edit-class-action class" style="cursor: pointer;" :data-id="courseClass.id">
								<td>{{ courseClass.name }}</td>
								<td>{{ courseClass.courseName }}</td>
								<td><span :class="courseClass.objectiveCount === 0 ? 'text-danger text-strong' : undefined">{{ courseClass.objectiveCount }}</span></td>
								<td><span :class="courseClass.materialCount === 0 ? 'text-danger text-strong' : undefined">{{ courseClass.materialCount }}</span></td>
							</tr>
						</tbody>
					</VTable>
				</div>
			</div>
		</div>

		<EditClassDialog :courses="mainData.courses"
			:classId="editClassDialog.classId"
			:courseName="editClassDialog.courseName"
			:shouldBeEditing="editClassDialog.isEditing"
			@classUpdated="editClassDialog.hasChanges = true"
			@closed="onEditClassDialogClosed" />

		<!-- <v-dialog v-model="this.editingInfo.isEditing" width="80%" :no-click-animation="true" scrim="true">
			<v-card :title="this.editingInfo.title">
				<v-card-text><EditClassDetails v-bind:lucentaryClass="this.editingInfo.lucentaryClass" v-bind:courses="this.mainList.courses" v-bind:isDisabled="this.editingInfo.isSaving" /></v-card-text>
				<v-card-actions>
					<v-btn v-if="!this.editingInfo.isSaving && this.editingInfo.lucentaryClass.isDeleted" color="danger" class="mx-2 reactivate-class-action" @click="reactivateClass(this.editingInfo.lucentaryClass.id)">Reactivate</v-btn>
					<v-btn v-if="!this.editingInfo.isSaving && this.editingInfo.lucentaryClass.id && !this.editingInfo.lucentaryClass.isDeleted" color="danger" class="mx-2 delete-class-action" @click="deleteClass(this.editingInfo.lucentaryClass.id)">Delete</v-btn>
					<v-spacer></v-spacer>
					<v-btn v-if="!this.editingInfo.isSaving" color="primary" class="mx-2 save-class-action" @click="completeEditClass">Save</v-btn>
					<v-btn v-if="!this.editingInfo.isSaving" color="secondary" class="mx-2" @click="cancelEditClass">Cancel</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-snackbar v-model="this.editingInfo.isSnackbarShowing" timeout="4000" rounded="pill">
			<span class="message">Class changes were saved successfully.</span>
			<template v-slot:actions>
				<v-btn color="primary" variant="text" @click="this.editingInfo.isSnackbarShowing = false">Close</v-btn>
			</template>
		</v-snackbar>

		<v-dialog v-model="promptDialog.isPromptingUser" width="auto" scrim="true">
			<v-card>
				<v-card-text>{{ this.promptDialog.userPrompt }}</v-card-text>
				<v-card-actions>
					<v-btn color="primary" class="yes-action" @click="promptConfirmationAction">Yes</v-btn>
					<v-btn color="secondary" @click="this.promptDialog.isPromptingUser = false">Cancel</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-snackbar v-model="this.mainList.hasError" timeout="-1" rounded="pill" color="danger">
			<span class="error-message">Something went wrong.  Try again later.</span>
			<template v-slot:actions>
				<v-btn color="primary" variant="text" @click="this.mainList.hasError = false">Close</v-btn>
			</template>
		</v-snackbar> -->
	</div>
</template>

<style scoped>
	.v-theme--light.v-table.table-striped tbody tr:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}
	.text-strong {
		font-weight: bold;
	}
</style>
