<script setup>
	import { nextTick, onMounted, reactive } from "vue";
	import { VAlert, VBtn, VCard, VCardActions, VCardText, VCardTitle, VCheckbox, VCol, VDivider, VProgressLinear, VRow, VSpacer, VTable } from "vuetify/components";
	import { accountingService, executeServiceCall } from "@/services";
	import { AddPrimaryIcon } from "@/components";
	import { EditEmployeeDialog, EmployeeIcon } from "../components";

	const editEmployeeInfo = reactive({
		employeeId: null,
		isEditing: false,
		wasDataChanged: false,
	});
	const mainData = reactive({
		employees: null,
		hasError: false,
		isLoading: false,
	});

	function addEmployee() {
		editEmployeeInfo.isEditing = false;
		editEmployeeInfo.employeeId = null;
		editEmployeeInfo.isEditing = true;
	}
	async function editEmployee(employeeId) {
		editEmployeeInfo.isEditing = false;
		editEmployeeInfo.employeeId = null;
		if (!employeeId)
			return;

		editEmployeeInfo.employeeId = employeeId;
		editEmployeeInfo.isEditing = true;
	}
	async function loadEmployees() {
		mainData.isLoading = true;
		mainData.hasError = false;

		await executeServiceCall(accountingService.employee.list)
			.then(({ data }) => mainData.employees = data)
			.catch(() => mainData.hasError = true)
			.finally(() => mainData.isLoading = false);
	}
	async function onEditEmployeeDialogClosed() {
		editEmployeeInfo.isEditing = false;
		if (editEmployeeInfo.wasDataChanged) {
			await nextTick(async () => {
				await loadEmployees();
				editEmployeeInfo.wasDataChanged = false;
			});
		}
	}

	onMounted(async () => await loadEmployees());
</script>

<template>
	<div class="container my-4">
		<VRow>
			<VCol class="v-col-auto">
				<h1 class="col-auto"><EmployeeIcon fontSize="48px" /> Employee Management</h1>
			</VCol>
			<VSpacer />
			<VCol class="v-col-auto text-center">
				<h5 class="mt-4">All Employees</h5>
			</VCol>
			<VSpacer />
			<VCol class="v-col-auto text-right">
				<VBtn class="ma-2 create-employee-action"
					color="primary"
					variant="flat"
					size="large"
					:disabled="mainData.isLoading"
					@click="addEmployee">
					<AddPrimaryIcon class="mr-2"/> New Employee
				</VBtn>
			</VCol>
		</VRow>

		<template v-if="mainData.hasError">
			<VAlert color="error" class="mb-4">Something went wrong.  Try again later.</VAlert>
		</template>
		<template v-if="mainData.isLoading">
			<VProgressLinear indeterminate color="primary"></VProgressLinear>
		</template>

		<template :class="mainData.isLoading ? 'disabled row' : 'row'">
			<VAlert v-if="mainData.employees?.length === 0">No employees were found.</VAlert>
			<VRow v-else>
				<VCol v-for="employee in mainData.employees" class="v-col-4">
					<VCard class="clickable edit-employee-action"
						:data-id="employee.id"
						:title="employee.name"
						:subtitle="employee.emailAddress"
						@click="editEmployee(employee.id)">
						<VCardText>
							<VDivider></VDivider>
							<strong>{{ employee.employeeType }} - Hired {{ employee.hireDate }}</strong>
						</VCardText>
					</VCard>
				</VCol>
			</VRow>
		</template>

		<EditEmployeeDialog :employeeId="editEmployeeInfo.employeeId"
			:shouldBeEditing="editEmployeeInfo.isEditing"
			@employeeUpdated="editEmployeeInfo.wasDataChanged = true"
			@closed="onEditEmployeeDialogClosed" />
	</div>
</template>

<style scoped>
	.v-card-title.clickable {
		cursor: pointer;
	}
	.v-card-title.clickable:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}
	.v-theme--light.VTable.table-striped tbody tr:hover {
		background-color: rgba(0, 0, 0, 0.1);
		cursor: pointer;
	}
</style>
