import { webApiService } from "./webApiService";

const selfServiceService = {
	accounting: {
		/**
		 * 
		 * @returns 200, [{
		 * 		title: String,
		 * 		description: String,
		 * 		link: String,
		 * 		level: "Info"|"Warning"|"Error"
		 * }]
		 */
		notifications: async function() {
			return await webApiService.executeGet(`SelfService/Accounting/Notifications`);
			// await new Promise(r => setTimeout(r, 250));
			// return Promise.resolve({
			// 	data: [
			// 		{ title: "Past Due Invoice", description: "There is at least one Invoice that is past due.", link: "/accounting/invoices", level: "Warning" },
			// 	]
			// });
		},
	},
	payStub: {
		byId: async function(payStubId) {
			return await webApiService.executeGet(`SelfService/PayStub/ById?Id=${payStubId}`);
		},
		list: async function() {
			return await webApiService.executeGet("SelfService/PayStub/List");
		},
	},
	public: {
		submitContactRequest: async function(contact) {
			return await webApiService.executePost("SelfService/Public/SubmitContactRequest", contact);
		},
	},
};

export { selfServiceService };
