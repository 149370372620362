<script setup>
	import { reactive, watch } from "vue";
	import { VCol, VRow, VSelect, VTextField } from "vuetify/components";
	import { accountingService, executeServiceCall } from "@/services";
	import { hasAnySimpleValueChanged } from "@/utils";
	import BaseEditDialog from "@/components/BaseEditDialog.vue";
	import ProductIcon from "./ProductIcon.vue";

	const props = defineProps({
		productId: { type: String, required: false },
		shouldBeEditing: { type: Boolean, required: true },
	});
	const emit = defineEmits([ "closed", "productUpdated" ]);

	const mainData = reactive({
		hasCriticalError: false,
		isDirty: false,
		isSaving: false,
		originalSnapshot: null,
		product: null,
		referenceData: null,
		validationErrors: [],
	});
	const rules = {
		required: value => !!value || "This value is required.",
	};

	async function deleteProduct() {
		resetErrors();

		if (!mainData.product?.id)
			return;

		await executeServiceCall(() => accountingService.product.delete(mainData.product.id))
			.catch(() => mainData.hasCriticalError = true);
	}
	function emitClosed() { emit("closed"); }
	function emitProductUpdated() { emit("productUpdated", mainData.product?.id); }
	async function loadProductData() {
		resetErrors();

		await executeServiceCall(accountingService.product.referenceData)
			.then(({ data }) => mainData.referenceData = data)
			.catch(() => mainData.hasCriticalError = true);
		if (mainData.hasCriticalError)
			return;

		if (!props.productId) {
			mainData.product = {
				name: null,
				incomeAccountId: null,
				unitPrice: null,
			};
		}
		else {
			await executeServiceCall(() => accountingService.product.byId(props.productId))
				.then(({ data }) => mainData.product = data)
				.catch(() => mainData.hasCriticalError = true);
			if (mainData.hasCriticalError)
				return;
		}

		mainData.originalSnapshot = JSON.parse(JSON.stringify(mainData.product));
	}
	function onBaseBeginSaving(done) { mainData.isSaving = true; done(); }
	function onBaseClosed(done) {
		resetErrors();
		mainData.isDirty = false;
		mainData.originalSnapshot = null;
		mainData.party = null;
		mainData.referenceData = null;
		emitClosed();
		done();
	}
	async function onBaseDeleting(done) { await deleteProduct(); done(); }
	function onBaseEndSaving(done) { mainData.isSaving = false; done(); }
	function onBaseItemDeleted(done) { emitProductUpdated(); done(); }
	function onBaseItemUpdated(done) { emitProductUpdated(); done(); }
	async function onBaseLoadingItem(done) { await loadProductData(); done(); }
	async function onBaseSaving(done) { await saveProduct(); done(); }
	function resetErrors() {
		mainData.hasCriticalError = false;
		mainData.validationErrors = [];
	}
	async function saveProduct() {
		resetErrors();

		await executeServiceCall(() => accountingService.product.save(mainData.product))
			.then(({ data }) => mainData.product.id = String(data))
			.catch(({ response }) => {
				if (response?.status === 400) {
					if (typeof response.data === "array")
						mainData.validationErrors = response.data;
					else
						mainData.validationErrors = ["One or more validations failed.  Check your inputs and try again."];
				}
				else {
					mainData.hasCriticalError = true;
				}
			});
	}
	function updateIsDirty() {
		const { product, originalSnapshot } = mainData;
		if (!product || !originalSnapshot)
			return;

		const isDirty = hasAnySimpleValueChanged(product, originalSnapshot, ["name","incomeAccountId"])
			|| Number(product.unitPrice).toFixed(2) !== Number(originalSnapshot.unitPrice).toFixed(2);
		mainData.isDirty = isDirty;
	}

	watch(() => mainData.product, () => { updateIsDirty(); }, { deep: true });
</script>

<template>
	<div>
		<BaseEditDialog subjectName="Product"
			:shouldBeEditing="props.shouldBeEditing"
			:isDirty="mainData.isDirty"
			:hasCriticalError="mainData.hasCriticalError"
			:validationErrors="mainData.validationErrors"
			:isDeleteAllowed="!!mainData.product?.id"
			@beginSaving="onBaseBeginSaving"
			@endSaving="onBaseEndSaving"
			@deleting="onBaseDeleting"
			@loadingItem="onBaseLoadingItem"
			@saving="onBaseSaving"
			@itemDeleted="onBaseItemDeleted"
			@itemUpdated="onBaseItemUpdated"
			@closed="onBaseClosed">
			<template v-slot:title><ProductIcon></ProductIcon> Product Management</template>
			<div v-if="mainData.product">
				<v-row>
					<v-col class="v-col-12 v-col-md-6">
						Product type: Service
					</v-col>
					<v-col class="v-col-12 v-col-md-6">
						<v-text-field v-model="mainData.product.name"
							id="productName"
							label="Name"
							:rules="[rules.required]"
							:disabled="mainData.isSaving"
							autofocus />
					</v-col>
				</v-row>
				<v-row>
					<v-col class="v-col-12 v-col-md-6">
						<v-select v-model="mainData.product.incomeAccountId" :items="mainData.referenceData?.accounts" item-value="id" item-title="name" label="Income Account"
							:rules="[rules.required]" :disabled="mainData.isSaving"></v-select>
					</v-col>
					<v-col class="v-col-12 v-col-md-6">
						<v-text-field v-model="mainData.product.unitPrice"
							id="productUnitPrice"
							type="number"
							label="Unit Price"
							:rules="[rules.required]"
							:disabled="mainData.isSaving" />
					</v-col>
				</v-row>
			</div>
		</BaseEditDialog>
	</div>
</template>
