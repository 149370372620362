<script setup>
	import { onMounted } from "vue";
	import { authenticationService } from "@/services/authenticationService";
	import { getRouter, performPostSignInRedirect } from "@/router";
	import { trace } from "@/utils";

	async function completeSignIn() {
		const account = await authenticationService.getActiveAccount();
		if (account) {
			trace("Successfully signed in.  Redirecting to landing page.");
			performPostSignInRedirect();
		}
		else {
			trace("Not authenticated.  Redirecting to Home page.");
			getRouter().push({ name: "home" });
		}
	}

	onMounted(async () => {
		await completeSignIn();
	});
</script>

<template>
	<div class="container text-center">
		<h3>Attempting to sign you in...</h3>
 	</div>
</template>
