<script setup>
	import { onMounted, reactive } from "vue";
	import { useUserStore } from "@/stores";
	import { getRouter, academicTemplateRoute } from "@/router";
	import { Roles } from "@/UserRoles.js";
	import { iconClassNames } from "@/components";
	import { ClassAttendanceDialog } from "../components";

	// Data
	const classAttendanceDialog = reactive({
		isShowing: false,
	});
	const mainData = reactive({
		account: null,
	});

	// Functions
	function isRouteAuthorized(routeName) {
		if (!mainData.account)
			return false;

		const route = academicTemplateRoute.children
			.find(childRoute => childRoute.name === routeName);
		if (!route)
			return false;
		if (!route.meta || !route.meta.authorize)
			return true;
		return mainData.account.roles.filter(role => route.meta.authorize.includes(role)).length > 0;
	}
	function navigateTo(to) {
		getRouter().push(to);
	}
	function onClassAttendanceDialogClosed() {
		classAttendanceDialog.isShowing = false;
	}
	function openClassAttendanceDialog() {
		classAttendanceDialog.isShowing = true;
	}
	function userIsInRole(role) {
		if (!mainData.account)
			return false;

		return mainData.account.roles.includes(role);
	}

	onMounted(() => {
		const userStore = useUserStore();
		mainData.account = userStore.getAccount();
	});
</script>

<template>
	<div>
		<VNavigationDrawer color="primary">
			<VList>
				<VListItem title="Academy" :prepend-icon="iconClassNames.academic" />
			</VList>
			<VDivider />
			<VList density="compact" nav>
				<VListItem v-if="isRouteAuthorized('academicHome')" title="Home" :prepend-icon="iconClassNames.academic" @click="navigateTo({ name: 'academicHome' })" />
				<VListItem v-if="isRouteAuthorized('academicCourses')" title="Courses" :prepend-icon="iconClassNames.course" @click="navigateTo({ name: 'academicCourses' })" />
				<VListItem v-if="isRouteAuthorized('academicClasses')" title="Classes" :prepend-icon="iconClassNames.classes" @click="navigateTo({ name: 'academicClasses' })" />
				<VListItem v-if="isRouteAuthorized('academicProspects')" title="Prospects" :prepend-icon="iconClassNames.prospect" @click="navigateTo({ name: 'academicProspects' })" />
				<VListItem v-if="isRouteAuthorized('academicSettings')" title="Settings" :prepend-icon="iconClassNames.settings" class="main-navigation academic-settings" @click="navigateTo({ name: 'academicSettings' })" />
			</VList>
			<VDivider />
			<VList density="compact" nav>
				<VListItem v-if="userIsInRole(Roles.Instructor)" title="Class Attendance" :prepend-icon="iconClassNames.classAttendance" class="main-navigation class-attendance-action" @click="openClassAttendanceDialog" />
			</VList>
		</VNavigationDrawer>

		<RouterView v-slot="{ Component }">
			<VFadeTransition mode="out-in" :hide-on-leave="true">
				<component :is="Component"/>
			</VFadeTransition>
		</RouterView>

		<ClassAttendanceDialog :shouldBeShowing="classAttendanceDialog.isShowing"
			@closed="onClassAttendanceDialogClosed"/>
	</div>
</template>
