function compareStrings(a, b) {
	if (a < b)
		return -1;
	if (a > b)
		return 1;
	return 0;
}

function formatAmount(value, zeroValue = "") {
	if (value == 0)
		return zeroValue;
	const finalValue = new Intl.NumberFormat("en-US", {
		maximumFractionDigits: 2,
		minimumFractionDigits: 2
	}).format(value);
	trace("Format Was: ", value);
	trace("Format Is: ", finalValue);
	return finalValue;
}

function hasAnySimpleValueChanged(updated, original, propertyNames) {
	for (let i = 0, prop; prop = propertyNames[i]; i++) {
		if ((updated[prop] || "") !== (original[prop] || "")) {
			trace("Prop changed: ", prop);
			trace("Original: ", original[prop]);
			trace("Updated: ", updated[prop]);
			return true;
		}
	}
	return false;
}

function hasArrayChanged(updatedArray, originalArray, propertyNames) {
	if(updatedArray.length !== originalArray.length)
		return true;

	for (let i = 0, updatedElement; updatedElement = updatedArray[i]; i++) {
		const originalElement = originalArray[i];
		if (!propertyNames) {
			if (updatedElement != originalElement)
				return true;
		}
		else {
			if (hasAnySimpleValueChanged(updatedElement, originalElement, propertyNames))
				return true;
		}
	}

	return false;
}

function parseNumber(value, locales = navigator.languages) {
	if (typeof value === "number")
		return value;
	if (!value)
		return 0;

	const example = Intl.NumberFormat(locales).format('1.1');
	const cleanPattern = new RegExp(`[^-+0-9${ example.charAt( 1 ) }]`, 'g');
	const cleaned = value.replace(cleanPattern, '');
	const normalized = cleaned.replace(example.charAt(1), '.');

	const parsedValue = parseFloat(normalized);
	if (isNaN(parsedValue))
		return 0;
	const finalValue = Number(parsedValue.toFixed(2));
	trace("Parse Was: ", value);
	trace("Parse Is: ", finalValue);
	return finalValue;
};

function sum(array) {
	let total = 0;
	array.forEach(value => total += value);
	return total;
}

function trace(message, arg) {
	const isEnabled = false;
	if (isEnabled) {
		if (arg === undefined)
			console.log(message);
		else
			console.log(message, arg);
	}
}

export { compareStrings, formatAmount, hasArrayChanged, hasAnySimpleValueChanged, parseNumber, sum, trace };
