<script setup>
	import { nextTick, reactive } from "vue";
	import { VAlert, VCard, VCardText, VCol, VFadeTransition, VRow, VSpacer, VTimeline, VTimelineItem } from "vuetify/components";
	import { accountingService, executeServiceCall } from "@/services";
	import { formatAmount } from "@/utils";
	import { BaseEditDialog, iconClassNames } from "@/components";
	import { EditCustomerPaymentDialog, EditInvoiceDialog, TransactionHistoryIcon } from "./";

	const props = defineProps({
		customerId: { type: String, required: true },
		shouldBeShowing: { type: Boolean, required: true },
	});
	const emit = defineEmits([ "closed" ]);

	const editCustomerPaymentInfo = reactive({
		customerPaymentId: null,
		hasAnyChanges: false,
		isEditing: false,
	});
	const editInvoiceInfo = reactive({
		hasAnyChanges: false,
		invoiceId: null,
		isEditing: false,
	});
	const mainData = reactive({
		hasCriticalError: false,
		isShowingDetails: false,
		transactions: null,
	});

	function calculateRunningBalances() {
		var balance = 0;
		mainData.transactions.forEach(transaction => {
			balance += transaction.transactionType === "Invoice" ? transaction.amount : -transaction.amount;
			balance = Number(balance.toFixed(2));
			transaction.runningBalance = balance;
		});
	}
	function editCustomerPayment(customerPaymentId) {
		editCustomerPaymentInfo.isEditing = false;
		editCustomerPaymentInfo.customerPaymentId = null;
		editCustomerPaymentInfo.customerPaymentId = customerPaymentId;
		editCustomerPaymentInfo.isEditing = true;
	}
	function editInvoice(invoiceId) {
		editInvoiceInfo.isEditing = false;
		editInvoiceInfo.invoiceId = null;
		editInvoiceInfo.invoiceId = invoiceId;
		editInvoiceInfo.isEditing = true;
	}
	function emitClosed() { emit("closed"); }
	async function loadTransactionHistory() {
		resetErrors();
		mainData.transactions = null;

		await executeServiceCall(() => accountingService.party.customerTransactionHistory(props.customerId))
			.then(({ data }) => {
				mainData.transactions = data;
				calculateRunningBalances();
			})
			.catch(() => mainData.hasCriticalError = true);
	}
	function onBaseClosed(done) { emitClosed(); done(); }
	async function onBaseLoadingItem(done) { await loadTransactionHistory(); done(); }
	async function onCustomerPaymentDialogClosed() {
		if (editCustomerPaymentInfo.hasAnyChanges) {
			await nextTick(async () => await loadTransactionHistory());
			editCustomerPaymentInfo.hasAnyChanges = false;
		}
		editCustomerPaymentInfo.isEditing = false;
	}
	function onCustomerPaymentDialogCustomerPaymentUpdated() { editCustomerPaymentInfo.hasAnyChanges = true; }
	async function onEditInvoiceDialogClosed() {
		if (editInvoiceInfo.hasAnyChanges) {
			await nextTick(async () => {
				await loadTransactionHistory();
				editInvoiceInfo.hasAnyChanges = false;
			});
		}
		editInvoiceInfo.isEditing = false;
	}
	function onEditInvoiceDialogInvoicesUpdated() { editInvoiceInfo.hasAnyChanges = true; }
	function resetErrors() {
		mainData.hasCriticalError = false;
	}
</script>

<template>
	<div>
		<BaseEditDialog subjectName="Customer Invoice and Payment History"
			:shouldBeEditing="props.shouldBeShowing"
			:isFullscreen="true"
			:isDeleteAllowed="false"
			:isPrintAllowed="false"
			:isSaveAndNewAllowed="false"
			:isSaveAllowed="false"
			:isDirty="false"
			:hasCriticalError="mainData.hasCriticalError"
			@loadingItem="onBaseLoadingItem"
			@closed="onBaseClosed">
			<template v-slot:title><TransactionHistoryIcon/> Customer Ledger</template>
			<div v-if="mainData.transactions">
				<template v-if="mainData.transactions.length === 0">
					<VAlert title="No History" text="We couldn't find any entries for the selected Party." />
				</template>
				<template v-else>
					<VRow>
						<VSpacer />
						<VCol class="v-col-auto">
							<VCheckbox v-model="mainData.isShowingDetails" label="Show Details?" />
						</VCol>
						<VSpacer />
					</VRow>
					<VTimeline align="start" truncateLine="start">
						<VTimelineItem v-for="(transaction, i) in mainData.transactions"
							:key="i"
							:dot-color="transaction.transactionType === 'Invoice' ? 'indigo-lighten-2' : 'green-lighten-1'"
							:icon="transaction.isPaidInFull ? iconClassNames.paidInFull : (transaction.transactionType === 'Invoice' ? iconClassNames.invoice : iconClassNames.customerPayment)"
							fill-dot>
							<template v-slot:opposite>
								<template v-if="transaction.transactionType === 'Invoice'">
									<template v-if="i % 2 === 1">
										Balance: {{ formatAmount(transaction.runningBalance) }}
									</template>
									<template v-else>
										<VCard :title="transaction.date" :subtitle="`Invoice #${transaction.number}`" @click="editInvoice(transaction.id)">
											<VCardText>
												<VTable density="compact">
													<VFadeTransition>
														<tbody v-if="mainData.isShowingDetails">
															<tr v-if="transaction.isPaidInFull">
																<td colspan="2" class="text-center"><em>Paid-in-full</em></td>
															</tr>
														</tbody>
													</VFadeTransition>
													<tfoot>
														<tr>
															<th>Total</th>
															<th class="text-right">{{ formatAmount(transaction.amount) }}</th>
														</tr>
													</tfoot>
												</VTable>
											</VCardText>
										</VCard>
									</template>
								</template>
								<template v-else>
									<template v-if="i % 2 === 1">
										<VCard :title="transaction.date" subtitle="Payment" @click="editCustomerPayment(transaction.id)">
											<VCardText>
												<VTable density="compact">
													<VFadeTransition>
														<tbody v-if="mainData.isShowingDetails">
															<tr v-for="invoiceAllocation in transaction.invoiceAllocations" :key="invoiceAllocation.number">
																<td>Invoice #{{ invoiceAllocation.number }}</td>
																<td class="text-right">{{ formatAmount(invoiceAllocation.amount) }}</td>
															</tr>
															<tr v-if="transaction.prepaymentCreditAmount > 0">
																<td>Prepayment Credit</td>
																<td class="text-right">{{ formatAmount(transaction.prepaymentCreditAmount) }}</td>
															</tr>
														</tbody>
													</VFadeTransition>
													<tfoot>
														<tr>
															<th>Total</th>
															<th class="text-right">{{ formatAmount(transaction.amount) }}</th>
														</tr>
													</tfoot>
												</VTable>
											</VCardText>
										</VCard>
									</template>
									<template v-else>
										Balance: {{ formatAmount(transaction.runningBalance) }}
									</template>
								</template>
							</template>
							<template v-if="transaction.transactionType === 'Invoice'">
								<template v-if="i % 2 === 0">
									Balance: {{ formatAmount(transaction.runningBalance) }}
								</template>
								<template v-else>
									<VCard :title="transaction.date" :subtitle="`Invoice #${transaction.number}`" @click="editInvoice(transaction.id)">
										<VCardText>
											<VTable density="compact">
												<VFadeTransition>
													<tbody v-if="mainData.isShowingDetails">
														<tr v-if="transaction.isPaidInFull">
															<td colspan="2" class="text-center"><em>Paid-in-full</em></td>
														</tr>
													</tbody>
												</VFadeTransition>
												<tfoot>
													<tr>
														<th>Total</th>
														<th class="text-right">{{ formatAmount(transaction.amount) }}</th>
													</tr>
												</tfoot>
											</VTable>
										</VCardText>
									</VCard>
								</template>
							</template>
							<template v-else>
								<template v-if="i % 2 === 0">
									<VCard :title="transaction.date" subtitle="Payment" @click="editCustomerPayment(transaction.id)">
										<VCardText>
											<VTable density="compact">
												<VFadeTransition>
													<tbody v-if="mainData.isShowingDetails">
														<tr v-for="invoiceAllocation in transaction.invoiceAllocations" :key="invoiceAllocation.number">
															<td>Invoice #{{ invoiceAllocation.number }}</td>
															<td class="text-right">{{ formatAmount(invoiceAllocation.amount) }}</td>
														</tr>
														<tr v-if="transaction.prepaymentCreditAmount > 0">
															<td>Prepayment Credit</td>
															<td class="text-right">{{ formatAmount(transaction.prepaymentCreditAmount) }}</td>
														</tr>
													</tbody>
												</VFadeTransition>
												<tfoot>
													<tr>
														<th>Total</th>
														<th class="text-right">{{ formatAmount(transaction.amount) }}</th>
													</tr>
												</tfoot>
											</VTable>
										</VCardText>
									</VCard>
								</template>
								<template v-else>
									Balance: {{ formatAmount(transaction.runningBalance) }}
								</template>
							</template>
						</VTimelineItem>
					</VTimeline>
					<h5 class="text-center">Final Balance: $ {{ formatAmount(mainData.transactions[mainData.transactions.length - 1].runningBalance) }}</h5>
				</template>
			</div>
		</BaseEditDialog>

		<EditInvoiceDialog :originalInvoiceId="editInvoiceInfo.invoiceId"
			:shouldBeEditing="editInvoiceInfo.isEditing"
			:disableSaveAndNewButton="true"
			@invoicesUpdated="onEditInvoiceDialogInvoicesUpdated"
			@closed="onEditInvoiceDialogClosed" />
		<EditCustomerPaymentDialog :customerPaymentId="editCustomerPaymentInfo.customerPaymentId"
			:shouldBeEditing="editCustomerPaymentInfo.isEditing"
			@invoicePaymentUpdated="onCustomerPaymentDialogCustomerPaymentUpdated"
			@closed="onCustomerPaymentDialogClosed" />

		<component :is="'style'">
			.v-timeline-item__body {
				justify-self: flex-start;
			}
			.v-table table tbody tr td {
				border: none;
			}
			.v-table .v-table__wrapper > table > tbody > tr:not(:last-child) > td {
				border-bottom: none;
			}
		</component>
	</div>
</template>

<!-- <style scoped>
	.v-table table tbody tr td {
		padding-left: 0;
		padding-right: 0;
	}
	.v-table table tbody tr td {
		height: 40px;
		border: none;
	}
	.v-table .v-table__wrapper > table > tbody > tr:not(:last-child) > td {
		border-bottom: none;
	}
	.text-sm :deep(input), .text-sm :deep(.v-autocomplete__selection-text) {
		font-size: 0.8em;
	}
</style> -->
