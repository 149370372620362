<script setup>
	import { ref } from "vue";
	import { VDivider, VFadeTransition, VList, VListItem, VNavigationDrawer } from "vuetify/components";
	import { useUserStore } from "@/stores";
	import { getRouter, accountingTemplateRoute } from "@/router";
	import iconClassNames from "@/components/iconClassNames";

	const account = ref(useUserStore().getAccount());

	const primaryLinks = [
		{ routeName: "accountingHome", title: "Home", icon: iconClassNames.home, class: "home-navigation" },
		{ routeName: "accountingInvoices", title: "Manage Invoices", icon: iconClassNames.invoice, class: "invoices-navigation" },
		{ routeName: "accountingPayrollRuns", title: "Execute Payroll", icon: iconClassNames.payrollRun, class: "payroll-runs-navigation" },
	];
	const secondaryLinks = [
		{ routeName: "accountingAccounts", title: "Chart of Accounts", icon: iconClassNames.account, class: "accounts-navigation" },
		{ routeName: "accountingParties", title: "Manage Parties", icon: iconClassNames.party, class: "parties-navigation" },
		{ routeName: "accountingPayrollSchedules", title: "Manage Payroll Schedules", icon: iconClassNames.payrollSchedule, class: "payroll-schedules-navigation" },
		{ routeName: "accountingEmployees", title: "Manage Employees", icon: iconClassNames.employee, class: "employees-navigation" },
		{ routeName: "accountingProducts", title: "Manage Products", icon: iconClassNames.product, class: "products-navigation" },
		{ routeName: "accountingSettings", title: "Settings", icon: iconClassNames.settings, class: "settings-navigation" },
	];

	function getAuthorizedPrimaryLinks() {
		return primaryLinks.filter(link => isRouteAuthorized(link.routeName));
	}
	function getAuthorizedSecondaryLinks() {
		return secondaryLinks.filter(link => isRouteAuthorized(link.routeName));
	}
	function isRouteAuthorized(routeName) {
		const route = accountingTemplateRoute.children
			.find(childRoute => childRoute.name === routeName);
		if (!route)
			return false;
		if (!route.meta || !route.meta.authorize)
			return true;
		return isUserInAnyRole(route.meta.authorize);
	}
	function isUserInAnyRole(roles) {
		if (!account.value)
			return false;
		return account.value.roles.filter(role => roles.includes(role)).length > 0;
	}
	function navigateTo(to) {
		getRouter().push(to);
	}
</script>

<template>
	<div>
		<VNavigationDrawer color="primary">
			<VList>
				<VListItem title="Accounting" :prepend-icon="iconClassNames.accounting" />
			</VList>
			<VDivider />
			<VList density="compact" nav>
				<VListItem v-for="(link, i) in getAuthorizedPrimaryLinks()" :key="i"
					:title="link.title"
					:prepend-icon="link.icon"
					:class="link.class"
					@click="navigateTo({ name: link.routeName })" />
			</VList>
			<VDivider />
			<VList density="compact" nav>
				<VListItem v-for="(link, i) in getAuthorizedSecondaryLinks()" :key="i"
					:title="link.title"
					:prepend-icon="link.icon"
					:class="link.class"
					@click="navigateTo({ name: link.routeName })" />
			</VList>
		</VNavigationDrawer>

		<RouterView v-slot="{ Component }">
			<VFadeTransition mode="out-in" :hide-on-leave="true">
				<component :is="Component"/>
			</VFadeTransition>
		</RouterView>
	</div>
</template>
