<script setup>
	import { reactive, watch } from "vue";
	import { academicService, executeServiceCall, getValidationErrorsFromResponse } from "@/services";
	import { hasAnySimpleValueChanged, hasArrayChanged } from "@/utils";
	import { BaseEditDialog } from "@/components";
	import { ClassAttendanceIcon } from "../components";

	// Public Interface
	const props = defineProps({
		shouldBeShowing: { type: Boolean, required: true },
	});
	const emit = defineEmits([ "closed" ]);

	// Data
	const mainData = reactive({
		classRoster: null,
		hasCriticalError: false,
		ignoreIsDirty: false,
		isDirty: false,
		isEditing: false,
		isProcessing: false,
		isShowing: false,
		originalClassRoster: null,
		referenceData: null,
		validationErrors: [],
	});

	// Functions
	function emitClosed() { emit("closed"); }
	function handleError(error) {
		if (error.response?.status === 400) {
			mainData.validationErrors = getValidationErrorsFromResponse(error.response);
		}
		else {
			console.error(error);
			mainData.hasCriticalError = true;
		}
	}
	async function loadReferenceData() {
		resetErrors();

		await executeServiceCall(() => academicService.classAttendance.referenceData())
			.then(({ data }) => {
				mainData.referenceData = data;
				const now = new Date();
				mainData.classRoster = {
					date: String(now.getFullYear()) + "-" + ("00" + (now.getMonth()+1)).slice(-2) + "-" + ("00" + (now.getDate())).slice(-2),
					time: "",
					classIds: [],
					studentIds: [],
				};
				mainData.originalClassRoster = JSON.parse(JSON.stringify(mainData.classRoster));
			})
			.catch(() => mainData.hasCriticalError = true)
			.finally(() => mainData.isProcessing = false);
	};
	function onBaseBeginSaving(done) { mainData.isProcessing = true; done(); }
	function onBaseClosed(done) {
		resetErrors();
		mainData.isDirty = false;
		mainData.originalClassRoster = null;
		mainData.classRoster = null;
		mainData.referenceData = null;
		emitClosed();
		done();
	}
	function onBaseEndSaving(done) { mainData.isProcessing = false; done(); }
	function onBaseItemUpdated(done) { done(); }
	async function onBaseLoadingItem(done) { await loadReferenceData(); done(); }
	async function onBaseSaving(done) { await saveRoster(); done(); }
	function resetErrors() {
		mainData.hasCriticalError = false;
		mainData.validationErrors = [];
	}
	async function saveRoster() {
		resetErrors();
	
		await executeServiceCall(() => academicService.classAttendance.save(mainData.classRoster))
			.catch(error => handleError(error));
	}
	function updateIsDirty() {
		if (!mainData.classRoster || !mainData.originalClassRoster)
			return;

		const isDirty = hasAnySimpleValueChanged(mainData.classRoster, mainData.originalClassRoster, ["date","time"])
			|| hasArrayChanged(mainData.classRoster.classIds, mainData.originalClassRoster.classIds)
			|| hasArrayChanged(mainData.classRoster.studentIds, mainData.originalClassRoster.studentIds);
		mainData.isDirty = isDirty;
	}

	watch(() => mainData.classRoster, () => { updateIsDirty(); }, { deep: true });
</script>

<template>
	<BaseEditDialog subjectName="Class Roster"
		:isFullscreen="true"
		:isDeleteAllowed="false"
		:isSaveAndNewAllowed="true"
		:shouldBeEditing="props.shouldBeShowing"
		:isDirty="mainData.isDirty"
		:hasCriticalError="mainData.hasCriticalError"
		:validationErrors="mainData.validationErrors"
		@loadingItem="onBaseLoadingItem"
		@beginSaving="onBaseBeginSaving"
		@saving="onBaseSaving"
		@endSaving="onBaseEndSaving"
		@itemUpdated="onBaseItemUpdated"
		@closed="onBaseClosed">
		<template v-slot:title><ClassAttendanceIcon/> Class Attendance</template>
		<div v-if="mainData.classRoster">
			<VRow>
				<VCol class="v-col-12 v-col-md-6">
					<VTextField id="classRosterDate"
						v-model="mainData.classRoster.date"
						type="date"
						label="Date"
						:disabled="mainData.isProcessing" />
					<VSelect id="classRosterTime"
						v-model="mainData.classRoster.time"
						:items="mainData.referenceData.classTimes"
						label="Time"
						:disabled="mainData.isProcessing" />
					<h5>Classes</h5>
					<VItemGroup v-model="mainData.classRoster.classIds"
						selectedClass="bg-primary"
						multiple>
						<VContainer>
							<VItem v-slot="{ isSelected, selectedClass, toggle }"
								v-for="lucentaryClass in mainData.referenceData.classes" :key="lucentaryClass.id"
								:value="lucentaryClass.id">
								<VCard :class="[selectedClass]"
									variant="flat"
									height="40"
									class="my-2 py-2 px-4 class"
									:data-id="lucentaryClass.id"
									@click="toggle">
									<div>{{ lucentaryClass.name }}</div>
								</VCard>
							</VItem>
						</VContainer>
					</VItemGroup>
				</VCol>
				<VCol class="v-col-12 v-col-md-6">
					<h5>Students</h5>
					<VItemGroup v-model="mainData.classRoster.studentIds"
						selectedClass="bg-primary"
						multiple>
						<VContainer>
							<VItem v-slot="{ isSelected, selectedClass, toggle }"
								v-for="student in mainData.referenceData.students" :key="student.id"
								:value="student.id">
								<VCard :class="[selectedClass]"
									variant="flat"
									height="40"
									class="my-2 py-2 px-4 student"
									:data-id="student.id"
									@click="toggle">
									<div>{{ student.name }}</div>
								</VCard>
							</VItem>
						</VContainer>
					</VItemGroup>
				</VCol>
			</VRow>
		</div>
	</BaseEditDialog>
</template>
