<script setup>
	import { ref } from "vue";

	const className = ref("className");
	const fontSize = ref("fontSize");

	defineExpose({className, fontSize});

	function getFontSize() {
		if (fontSize.value) {
			const lowerCaseFontSize = String(fontSize.value).toLocaleLowerCase();
			const allowedFontSizes = [ "18px", "24px", "36px", "48px" ];
			if (allowedFontSizes.includes(lowerCaseFontSize))
				return lowerCaseFontSize;
		}

		return "24px"
	}
</script>

<template>
	<i :class="`mdi mdi-${getFontSize()} ${className}`"></i>
</template>
