<script>
import { VCard, VCardText } from "vuetify/components";
import { useUserStore } from "@/stores";
import { Roles } from "@/UserRoles";
import EditInvoiceDialog from "@/modules/accounting/components/EditInvoiceDialog.vue";

export default {
	name: "DashboardView",

	components: {
		VCard, VCardText,
		EditInvoiceDialog,
	},

	created() {
		const userStore = useUserStore();
		this.account = userStore.getAccount();
	},

	data: () => ({
		account: null,
		isEditingInvoice: false,
		Roles: Roles,
	}),
}
</script>

<template>
	<div class="container">
		<h1>My Dashboard</h1>

		<div v-if="this.account.roles.includes(Roles.Director)" class="my-4">
			<h3>Director Activities</h3>
			<div class="row">
				<div class="col-auto my-1">
					<router-link :to="{ name: 'academicCourses' }" class="btn btn-primary text-left">
						<v-card class="mx-auto">
							<template v-slot:title>Course Management</template>
							<v-card-text>Allows you to create, edit, and delete courses for use by Instructors and Students.</v-card-text>
						</v-card>
					</router-link>
				</div>
			</div>
		</div>

		<div v-if="this.account.roles.includes(Roles.Instructor)" class="my-4">
			<h3>Instructor Activities</h3>
			<div class="row">
				<div class="col-auto my-1">
					<router-link :to="{ name: 'academicClasses' }" class="btn btn-primary text-left">
						<v-card class="mx-auto">
							<template v-slot:title>Class Management</template>
							<v-card-text>Allows you to create, edit, and delete classes associated with Courses for use by Students.</v-card-text>
						</v-card>
					</router-link>
				</div>
			</div>
		</div>

		<div v-if="this.account.roles.includes(Roles.Accountant)" class="my-4">
			<h3>Accountant Activities</h3>
			<div class="row">
				<div class="col-auto my-1">
					<router-link :to="{ name: 'accountingAccounts' }" class="btn btn-primary text-left">
						<v-card class="mx-auto">
							<template v-slot:title>G/L Account Management</template>
							<v-card-text>Allows you to create, edit, and delete General Ledger Accounts.</v-card-text>
						</v-card>
					</router-link>
				</div>
			</div>
		</div>

		<div v-if="this.account.roles.includes(Roles.Representative)" class="my-4">
			<h3>Representative Activities</h3>
			<div class="row">
				<div class="col-auto my-1">
					<a class="btn btn-primary text-left" @click="this.isEditingInvoice = true">
						<v-card class="mx-auto">
							<template v-slot:title>Generate Invoice</template>
							<v-card-text>Allows you to create an Invoice for a Customer.</v-card-text>
						</v-card>
					</a>
				</div>
			</div>
			<EditInvoiceDialog v-bind:shouldBeEditing="this.isEditingInvoice" @closed="this.isEditingInvoice = false"></EditInvoiceDialog>
		</div>

		<div v-if="this.account.roles.includes(Roles.Developer)" class="my-4">
			<h3>Developer Tools</h3>
			<div class="row">
				<div class="col-auto my-1">
					<router-link :to="{ name: 'developmentRecentOperations' }" class="btn btn-primary text-left">
						<v-card class="mx-auto">
							<template v-slot:title>Recent Operations</template>
							<v-card-text>View recent operations in the trace log.</v-card-text>
						</v-card>
					</router-link>
				</div>
				<div class="col-auto my-1">
					<router-link :to="{ name: 'developmentRecentErrors' }" class="btn btn-primary text-left">
						<v-card class="mx-auto">
							<template v-slot:title>Recent Errors</template>
							<v-card-text>View recent errors in the trace log.</v-card-text>
						</v-card>
					</router-link>
				</div>
			</div>
		</div>

		<section class="bg-light">
			<div class="container m-4">
				<p><strong>Username: </strong> {{ this.account.username }}</p>
				<p><strong>Name: </strong> {{ this.account.name }}</p>
				<p><strong>Director? </strong> {{ this.account.roles.includes(Roles.Director) ? "Yes" : "No" }}</p>
				<p><strong>Instructor? </strong> {{ this.account.roles.includes(Roles.Instructor) ? "Yes" : "No" }}</p>
				<p><strong>Representative? </strong> {{ this.account.roles.includes(Roles.Representative) ? "Yes" : "No" }}</p>
				<p><strong>Developer? </strong> {{ this.account.roles.includes(Roles.Developer) ? "Yes" : "No" }}</p>
				<p><strong>Student? </strong> {{ this.account.roles.includes(Roles.Student) ? "Yes" : "No" }}</p>
				<p><strong>Token: </strong> {{ this.account.token }}</p>
			</div>
		</section>
	</div>
</template>
