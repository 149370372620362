<script setup>
	import { nextTick, onMounted, reactive, watch } from "vue";
	import { VAlert, VBtn, VBtnToggle, VCard, VCardActions, VCardText, VCardTitle, VCol, VProgressLinear, VRow, VSpacer } from "vuetify/components";
	import { accountingService, executeServiceCall } from "@/services";
	import { AddPrimaryIcon, DeactivateIcon, DeleteIcon, ReactivateIcon } from "@/components";
	import { EditPayrollScheduleDialog, PayrollScheduleIcon } from "../components";

	const editPayrollScheduleInfo = reactive({
		isEditing: false,
		payrollScheduleId: null,
		wasPayrollScheduleUpdated: false,
	});
	const mainData = reactive({
		hasError: false,
		isLoading: false,
		payrollSchedules: null,
		viewSelection: 0,
	});

	function addPayrollSchedule() {
		editPayrollScheduleInfo.isEditing = false;
		editPayrollScheduleInfo.payrollScheduleId = null;
		editPayrollScheduleInfo.isEditing = true;
	}
	async function deactivatePayrollSchedule(payrollScheduleId) {
		mainData.isLoading = true;
		mainData.hasError = false;

		await executeServiceCall(() => accountingService.payrollSchedule.deactivate(payrollScheduleId))
			.then(async () => await loadPayrollSchedules())
			.catch(() => mainData.hasError = true)
			.finally(() => mainData.isLoading = false);
	}
	async function deletePayrollSchedule(payrollScheduleId) {
		mainData.isLoading = true;
		mainData.hasError = false;

		await executeServiceCall(() => accountingService.payrollSchedule.delete(payrollScheduleId))
			.then(async () => await loadPayrollSchedules())
			.catch(() => mainData.hasError = true)
			.finally(() => mainData.isLoading = false);
	}
	function editPayrollSchedule(payrollScheduleId) {
		editPayrollScheduleInfo.isEditing = false;
		editPayrollScheduleInfo.payrollScheduleId = null;
		if (!payrollScheduleId)
			return;

		editPayrollScheduleInfo.payrollScheduleId = payrollScheduleId;
		editPayrollScheduleInfo.isEditing = true;
	}
	async function loadPayrollSchedules() {
		mainData.isLoading = true;
		mainData.hasError = false;

		const listServiceCall = mainData.viewSelection === 0
			? accountingService.payrollSchedule.listActive
			: accountingService.payrollSchedule.listInactive;
		await executeServiceCall(listServiceCall)
			.then(({ data }) => mainData.payrollSchedules = data)
			.catch(() => mainData.hasError = true)
			.finally(() => mainData.isLoading = false);
	}
	async function onEditPayrollScheduleDialogClosed() {
		editPayrollScheduleInfo.isEditing = false;
		if (editPayrollScheduleInfo.wasPayrollScheduleUpdated) {
			await nextTick(async () => {
				await loadPayrollSchedules();
				editPayrollScheduleInfo.wasPayrollScheduleUpdated = false;
			});
		}
	}
	async function reactivatePayrollSchedule(payrollScheduleId) {
		mainData.isLoading = true;
		mainData.hasError = false;

		await executeServiceCall(() => accountingService.payrollSchedule.reactivate(payrollScheduleId))
			.then(async () => await loadPayrollSchedules())
			.catch(() => mainData.hasError = true)
			.finally(() => mainData.isLoading = false);
	}

	watch(() => mainData.viewSelection, async () => {
		await loadPayrollSchedules();
	});

	onMounted(async () => await loadPayrollSchedules());
</script>

<template>
	<div class="container my-4">
		<VRow>
			<VCol class="v-col-auto">
				<h1 class="col-auto"><PayrollScheduleIcon fontSize="48px" /> Payroll Schedules</h1>
			</VCol>
			<VSpacer />
			<VCol class="v-col-auto">
				<VBtnToggle v-model="mainData.viewSelection" :disabled="mainData.isLoading">
					<VBtn class="active-view-action">Active</VBtn>
					<VBtn class="inactive-view-action">Inactive</VBtn>
				</VBtnToggle>
			</VCol>
			<VSpacer />
			<VCol class="v-col-auto text-right">
				<VBtn class="ma-2 create-payroll-schedule-action"
					color="primary"
					variant="flat"
					size="large"
					:disabled="mainData.isLoading"
					@click="addPayrollSchedule">
					<AddPrimaryIcon class="mr-2"/> New Payroll Schedule
				</VBtn>
			</VCol>
		</VRow>

		<template v-if="mainData.hasError">
			<VAlert color="error" class="mb-4 main-error">Something went wrong.  Try again later.</VAlert>
		</template>
		<template v-if="mainData.isLoading">
			<VProgressLinear indeterminate color="primary" class="loading-payroll-schedules-indicator"></VProgressLinear>
		</template>

		<div :class="mainData.isLoading ? 'disabled row' : 'row'">
			<VAlert v-if="!mainData.payrollSchedules?.length" class="ma-4">(No Payroll Schedules)</VAlert>
			<VRow v-else>
				<VCol v-for="(payrollSchedule, i) in mainData.payrollSchedules" :key="i" class="v-col-6">
					<VCard class="payroll-schedule" :data-id="payrollSchedule.id">
						<VCardTitle class="clickable payroll-schedule-title"
							:data-id="payrollSchedule.id"
							@click="editPayrollSchedule(payrollSchedule.id)">{{ payrollSchedule.name }}</VCardTitle>
						<VCardSubtitle>{{ payrollSchedule.frequency }}</VCardSubtitle>
						<VCardText>Next Run Date: {{ payrollSchedule.nextRunDate }}</VCardText>
						<VCardActions>
							<VBtn v-if="payrollSchedule.isDeletable"
								class="delete-payroll-schedule-action"
								variant="plain"
								color="error"
								title="Delete Payroll Schedule"
								:disabled="mainData.isLoading"
								@click="deletePayrollSchedule(payrollSchedule.id)">
								<DeleteIcon/>
							</VBtn>
							<VBtn v-if="!payrollSchedule.isDeactivated" variant="plain"
								class="deactivate-payroll-schedule-action"
								color="error"
								title="Deactivate"
								:disabled="mainData.isLoading"
								@click="deactivatePayrollSchedule(payrollSchedule.id)">
								<DeactivateIcon/>
							</VBtn>
							<VBtn v-if="payrollSchedule.isDeactivated" variant="plain"
								class="reactivate-payroll-schedule-action"
								color="primary"
								title="Reactivate"
								:disabled="mainData.isLoading"
								@click="reactivatePayrollSchedule(payrollSchedule.id)">
								<ReactivateIcon/>
							</VBtn>
						</VCardActions>
					</VCard>
				</VCol>
			</VRow>
		</div>

		<EditPayrollScheduleDialog :payrollScheduleId="editPayrollScheduleInfo.payrollScheduleId"
			:shouldBeEditing="editPayrollScheduleInfo.isEditing"
			@payrollScheduleUpdated="editPayrollScheduleInfo.wasPayrollScheduleUpdated = true"
			@closed="onEditPayrollScheduleDialogClosed" />
	</div>
</template>

<style scoped>
	.v-card-title.clickable {
		cursor: pointer;
	}
	.v-card-title.clickable:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}
</style>
