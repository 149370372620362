<script>
import { VAlert, VBtn, VTable } from "vuetify/components";
import { VProgressLinear } from "vuetify/lib/components/index.mjs";
import { developmentService } from "@/services/developmentService";
import { getRouter } from "@/router";

export default {
	name: "DiagnosticsRecentOperationsView",

	components: {
		VAlert, VBtn, VProgressLinear, VTable
	},

	data: () => ({
		operations: null,
		hasInitialLoadError: false,
		hasLoadMoreError: false,
		hasMoreHeadEntries: false,
		hasMoreTailEntries: false,
		loading: true,
		timer: null,
		pageSize: 100,
	}),

	async created() {
		await this.initialize();
	},

	beforeDestroy() {
		clearInterval(this.timer);
	},

	methods: {
		async checkForAdditionalHeadEntries() {
			if (!this.operations || this.operations.length === 0)
				return;

			const firstId = this.operations[0].id;
			await developmentService.diagnostic.hasOperationsNewerThan(firstId)
				.then(({ data }) => {
					const hasMoreHeadEntries = data;
					this.setHasMoreHeadEntries(hasMoreHeadEntries);
				})
				.catch(error => {
					console.error(error);
				});
		},
		async getMoreOperations() {
			this.loading = true;
			this.hasLoadMoreError = false;

			const firstId = this.operations && this.operations.length > 0 ? this.operations[0].id : "";
			const lastId = this.operations && this.operations.length > 0 ? this.operations[this.operations.length - 1].id : "";
			await developmentService.diagnostic.additionalRecentOperations(this.pageSize, firstId, lastId)
				.then(({ data }) => {
					this.operations.push(...data.operationHeaders);
					this.hasMoreTailEntries = data.hasMoreTailEntries;
					this.hasMoreHeadEntries = data.hasMoreHeadEntries;
					this.loading = false;
				})
				.catch(error => {
					console.error(error);
					this.hasLoadMoreError = true;
					this.loading = false;
				});
		},
		handleClick(operationId) {
			getRouter().push({ name: "developmentOperationDetails", params: { operationId: operationId } });
		},
		async initialize() {
			this.loading = true;

			this.hasInitialLoadError = false;
			this.hasLoadMoreError = false;
			this.operations = null;
			this.hasMoreHeadEntries = false;
			this.hasMoreTailEntries = true;

			await developmentService.diagnostic.initialRecentOperations(this.pageSize)
				.then(response => {
					var responseData = response.data;
					this.operations = responseData.operationHeaders;
					this.hasMoreTailEntries = responseData.hasMoreTailEntries;
					this.loading = false;

					this.timer = setInterval(async () => {
						await this.checkForAdditionalHeadEntries();
					}, 5000);
				})
				.catch(error => {
					this.hasInitialLoadError = true;
					this.loading = false;
				});
		},
		async reloadAll() {
			clearInterval(this.timer);
			await this.initialize();
		},
		setHasMoreHeadEntries(value) {
			if (value === true) {
				this.hasMoreHeadEntries = true;
				clearInterval(this.timer);
			}
		},
	},
}
</script>

<template>
	<div class="container">
		<h1>Recent Operations</h1>

		<div v-if="this.hasInitialLoadError">
			<v-alert type="error">
				We couldn't retrieve the records.<br/>
				<v-btn @click="reloadAll" class="try-all-again">Click to Try Again</v-btn>
			</v-alert>
		</div>
		<div v-if="!this.hasInitialLoadError">
			<div v-if="this.hasMoreHeadEntries">
				<v-alert class="text-center">There are newer entries.<br/><v-btn @click="reloadAll" class="refresh-list">Click to Refresh</v-btn></v-alert>
			</div>

			<v-table class="table table-striped" density="compact">
				<thead>
					<tr>
						<th>Time</th>
						<th>Category</th>
						<th>Subject</th>
						<th>Details</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="operation in this.operations" :key="operation.id" v-on:click="handleClick(operation.operationId)" style="cursor: pointer;" :data-id="operation.id">
						<td>{{ operation.displayTime }}</td>
						<td>{{ operation.category }}</td>
						<td>{{ operation.subject }}</td>
						<td v-html="operation.details"></td>
					</tr>
				</tbody>
			</v-table>

			<div v-if="this.hasLoadMoreError">
				<v-alert type="error">
					We couldn't retrieve the records.<br/>
					<v-btn @click="getMoreOperations" class="try-more-again">Click to Try Again</v-btn>
				</v-alert>
			</div>
			<div v-if="!this.hasLoadMoreError">
				<v-btn v-if="this.hasMoreTailEntries" rounded="lg" elevation="12" block :loading="loading" @click="getMoreOperations" class="load-more">
					Load More
					<template v-slot:loader>
						<v-progress-linear indeterminate></v-progress-linear>
					</template>
				</v-btn>
				<div v-if="!this.hasMoreTailEntries">
					<v-alert text="(No more operations)" class="text-center"></v-alert>
				</div>
			</div>
		</div>
	</div>
</template>
