import { webApiService } from "./webApiService";

const developmentService = {
	diagnostic: {
		// Methods
		additionalRecentOperations: async function(maxCount, firstId, lastId) {
			return await webApiService.executeGet(`Development/Diagnostics/RecentOperations?MaxCount=${maxCount}&FirstId=${firstId}&LastId=${lastId}`)
		},
		hasErrorsNewerThan: async function(firstId) {
			return await webApiService.executeGet(`Development/Diagnostics/HasErrorsNewerThan?FirstId=${firstId}`);
		},
		hasOperationsNewerThan: async function(firstId) {
			return await webApiService.executeGet(`Development/Diagnostics/HasOperationsNewerThan?FirstId=${firstId}`);
		},
		initialRecentOperations: async function(maxCount) {
			return await webApiService.executeGet(`Development/Diagnostics/RecentOperations?MaxCount=${maxCount}`);
		},
		operationEntries: async function(operationId) {
			return await webApiService.executeGet(`Development/Diagnostics/OperationEntries?OperationId=${operationId}`);
		},
		recentErrors: async function(days) {
			return await webApiService.executeGet(`Development/Diagnostics/RecentErrors?Days=${days}`);
		},
	},
};

export { developmentService };
