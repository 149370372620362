import { academicService } from "./academicService";
import { accountingService } from "./accountingService";
import { authenticationService } from "./authenticationService";
import { developmentService } from "./developmentService";
import { executeServiceCall, serviceUrlRoot } from "./webApiService";

const fallbackValidationMessages = ["One or more field validations failed.  Check your inputs and try again."];

function getValidationErrorsFromResponse(response) {
	const { data } = response;
	if (!data)
		return ["No response was received."];

	if (Array.isArray(data))
		return data;

	return fallbackValidationMessages;
}

export { academicService, accountingService, authenticationService, developmentService, executeServiceCall, fallbackValidationMessages, getValidationErrorsFromResponse, serviceUrlRoot };
export { selfServiceService } from "./selfServiceService";
