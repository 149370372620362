<script setup>
	import { computed, reactive, watch } from "vue";
	import { accountingService, executeServiceCall } from "@/services";
	import { formatAmount, hasArrayChanged, parseNumber } from "@/utils";

	/**
	 * Public Interface
	 */
	const props = defineProps({
		payrollScheduleId: { type: String, required: false },
	});
	const emit = defineEmits(["payrollRunExecuted","update:isDirty"]);

	/**
	 * Fields
	 */
	const canCalculatePayroll = computed({
		get() {
			if (!mainData.payrollRun)
				return false;

			let hasAnySelected = false;
			for (let i = 0, employee; employee = mainData.payrollRun.employees[i]; i++) {
				if (employee.isSelected) {
					if (employee.isHourly && !employee.hours)
						return false;
					hasAnySelected = true;
				} 
			}
			return hasAnySelected;
		}
	});
	const canFinalizePayroll = computed({
		get() {
			if (!mainData.employeeCalculations)
				return false;
			return mainData.employeeCalculations.filter(employee => !employee.isVerified).length === 0;
		}
	});
	const mainData = reactive({
		employeeCalculations: null,
		hasError: false,
		isDirty: false,
		isProcessing: false,
		originalPayrollRun: null,
		originalEmployeeCalculations: null,
		payrollRun: null,
		step: 0,
	});
	const totalEmployerContributions = computed({
		get() {
			let total = 0;
			if (mainData.employeeCalculations)
				mainData.employeeCalculations.forEach(employee => employee.employerContributions.forEach(employerContribution => total += employerContribution.amount));
			return formatAmount(new Number(total.toFixed(2)), "0.00");
		}
	});
	const totalGrossPays = computed({
		get() {
			let total = 0;
			if (mainData.employeeCalculations)
				mainData.employeeCalculations.forEach(employee => total += employee.grossPay);
			return formatAmount(new Number(total.toFixed(2)), "0.00");
		}
	});
	const totalNetPays = computed({
		get () {
			let total = 0;
			if (mainData.employeeCalculations)
				mainData.employeeCalculations.forEach(employee => total += calculateNetPay(employee));
			return formatAmount(new Number(total.toFixed(2)), "0.00");
		}
	});
	const totalWithholdings = computed({
		get () {
			let total = 0;
			if (mainData.employeeCalculations)
				mainData.employeeCalculations.forEach(employee => employee.withholdings.forEach(withholding => total += withholding.amount));
			return formatAmount(new Number(total.toFixed(2)), "0.00");
		}
	});

	/**
	 * Functions
	 */
	function calculateEmployerContributions(employee) {
		let total = 0;
		if (!!employee) {
			employee.employerContributions.forEach(employerContribution => total += employerContribution.amount);
		}
		return new Number(total.toFixed(2));
	}
	function calculateNetPay(employee) {
		let netPay = 0;
		if (!!employee) {
			netPay = employee.grossPay;
			employee.withholdings.forEach(withholding => netPay -= withholding.amount);
		}
		return new Number(netPay.toFixed(2));
	}
	async function calculatePayroll() {
		mainData.hasError = false;
		mainData.isProcessing = true;

		const employees = JSON.parse(JSON.stringify(mainData.payrollRun.employees
			.filter(employee => !!employee.isSelected)
			.map(employee => { return { id: employee.id, hours: employee.hours || 0 }; })));
		const request = {
			payrollScheduleId: props.payrollScheduleId,
			employees: employees,
		};
		console.log(request);
		await executeServiceCall(() => accountingService.payrollRun.calculatePayroll(request))
			.then(({ data }) => {
				const employeeCalculations = data;
				employeeCalculations.forEach(employee => employee.isVerified = false);
				mainData.employeeCalculations = employeeCalculations;
				mainData.originalEmployeeCalculations = JSON.parse(JSON.stringify(mainData.employeeCalculations));
				mainData.step = 1;
			})
			.catch(() => mainData.hasError = true)
			.finally(() => mainData.isProcessing = false);
	}
	function emitIsDirtyChanged() { emit("update:isDirty", mainData.isDirty); }
	function emitPayrollRunExecuted() { emit("payrollRunExecuted"); }
	async function finalizePayroll() {
		mainData.isProcessing = true;
		mainData.hasError = false;

		const employeeCalculations = mainData.employeeCalculations
			.map(employeeCalculation => {
				return { id: employeeCalculation.id,
					grossPay: employeeCalculation.grossPay,
					withholdings: employeeCalculation.withholdings,
					employerContributions: employeeCalculation.employerContributions,
				};
			});
		await executeServiceCall(() => accountingService.payrollRun.finalizePayroll({ payrollScheduleId: props.payrollScheduleId, employeeCalculations: employeeCalculations }))
			.then(() => {
				mainData.step = 3;
				resetData();
				emitPayrollRunExecuted();
			})
			.catch(() => mainData.hasError = true)
			.finally(() => mainData.isProcessing = false);
	}
	async function loadInitialData() {
		mainData.isProcessing = true;
		mainData.hasError = false;

		await executeServiceCall(() => accountingService.payrollRun.employeesForPayrollRun(props.payrollScheduleId))
			.then(({ data }) => {
				const payrollRun = data;
				payrollRun.employees.forEach(employee => employee.isSelected = true);
				mainData.payrollRun = payrollRun;
				mainData.originalPayrollRun = JSON.parse(JSON.stringify(mainData.payrollRun));
			})
			.catch(() => mainData.hasError = true)
			.finally(() => mainData.isProcessing = false);
	}
	function resetData() {
		mainData.employeeCalculations = null;
		mainData.originalEmployeeCalculations = null;
		mainData.payrollRun = null;
		mainData.originalPayrollRun = null;
		mainData.isDirty = false;
	}
	function updateIsDirty() {
		if (!mainData.payrollRun || !mainData.originalPayrollRun)
			return;
		mainData.isDirty = !!mainData.employeeCalculations
			|| hasArrayChanged(mainData.payrollRun.employees, mainData.originalPayrollRun.employees, ["isSelected","hours"]);
	}

	/**
	 * Watches
	 */
	watch(() => mainData.isDirty, () => emitIsDirtyChanged());
	watch(() => ({...mainData.payrollRun}), () => { updateIsDirty(); }, { deep: true });
	watch(() => props.payrollScheduleId, async () => {
		if (!!props.payrollScheduleId)
			await loadInitialData();
		else
			resetData();
	}, { immediate: true });
</script>

<template>
	<div>
		<VProgressLinear v-if="mainData.isProcessing" class="payroll-run-loading-indicator" color="primary" indeterminate />
		
		<VAlert v-if="mainData.hasError" color="error" class="payroll-run-critical-error-message">Something went wrong.  Try again later.</VAlert>

		<VSheet v-if="!!mainData.payrollRun" height="100%">
			<h2>Payroll for '{{ mainData.payrollRun.payrollScheduleName }}' on {{ mainData.payrollRun.date }}</h2>
			<VCarousel
				v-model="mainData.step"
				:hideDelimiters="true"
				:showArrows="false"
				height="100%">
				<VCarouselItem>
					<VSheet height="100%">
						<VRow class="mt-4">
							<VCol class="offset-4 v-col-4 text-center"><h4>Choose Employees</h4></VCol>
							<VCol class="v-col-4 text-right">
								<VBtn color="primary" class="ml-4 calculate-payroll-action" :disabled="!canCalculatePayroll" @click="calculatePayroll">Calculate Payroll</VBtn>
							</VCol>
						</VRow>
						<VTable class="table-borderless">
							<tbody>
								<tr v-for="(employee, i) in mainData.payrollRun.employees" :key="i" :data-id="employee.id" class="employee">
									<td>
										<VCheckbox v-model="employee.isSelected"
											class="isSelectedInput"
											:label="employee.name" />
									</td>
									<td>
										<VTextField v-if="employee.isSelected && employee.isHourly"
											v-model="employee.hours"
											class="hoursInput"
											type="number"
											label="Hours"
											hideDetails />
										<span v-if="employee.isSelected && !employee.isHourly">(Salaried)</span>
									</td>
								</tr>
							</tbody>
						</VTable>
					</VSheet>
				</VCarouselItem>
				<VCarouselItem>
					<VSheet height="100%">
						<VRow class="mt-4">
							<VCol class="v-col-4">
								<VBtn color="primary" class="ml-4 back-to-employee-selection-action" @click="mainData.step = 0">Back to Employee Selection</VBtn>
							</VCol>
							<VCol class="v-col-4 text-center"><h4>Review Calculations</h4></VCol>
							<VCol class="v-col-4 text-right">
								<VBtn color="primary" class="ml-4 finalize-payroll-action" :disabled="!canFinalizePayroll" @click="finalizePayroll">Finalize Payroll</VBtn>
							</VCol>
						</VRow>
						<VRow>
							<VCol class="offset-2 v-col-8">
								<VTable density="compact">
									<tbody>
										<tr>
											<th class="text-right">Total Gross Wages &amp; Salaries</th>
											<th class="text-right">{{ totalGrossPays }}</th>
											<th class="text-right">Total Net Wages</th>
											<th class="text-right">{{ totalNetPays }}</th>
										</tr>
										<tr>
											<th class="text-right">Total Withholdings</th>
											<th class="text-right">{{ totalWithholdings }}</th>
											<th class="text-right">Total Employer Contributions</th>
											<th class="text-right">{{ totalEmployerContributions }}</th>
										</tr>
									</tbody>
								</VTable>
							</VCol>
						</VRow>
						<VCard v-for="(employee, i) in mainData.employeeCalculations" :key="i" class="ma-4 employee" :data-id="employee.id">
							<VCardTitle>
								<VCheckbox v-model="employee.isVerified" class="is-verified">
									<template v-slot:label>
										<div><strong>{{ employee.name }}</strong> pay Verified</div>
									</template>
								</VCheckbox>
							</VCardTitle>
							<VCardText>
								<VRow>
									<VCol class="v-col-12 v-col-md-6">
										<VTable density="compact">
											<tbody>
												<tr>
													<th>Gross</th>
													<th class="text-right">
														<div v-if="!employee.isEditingGrossPay"
															:class="employee.isVerified ? 'gross-pay' : 'clickable gross-pay'"
															@click="() => { if (employee.isVerified) return; employee.tempGrossPay = employee.grossPay; employee.isEditingGrossPay = true; }">
															{{ formatAmount(employee.grossPay, "0.00") }}
														</div>
														<div v-else>
															<VTextField :modelValue="formatAmount(employee.grossPay)"
																class="gross-pay-input"
																appendIcon="mdi-check-outline"
																autofocus
																@update:modelValue="value => employee.tempGrossPay = value"
																@click:append="() => { employee.grossPay = parseNumber(employee.tempGrossPay); employee.tempGrossPay = undefined; employee.isEditingGrossPay = undefined; }"
																@update:focused="isFocused => { if (isFocused) return; employee.grossPay = parseNumber(employee.tempGrossPay); employee.tempGrossPay = undefined; employee.isEditingGrossPay = undefined; }" />
														</div>
													</th>
												</tr>
												<tr v-for="(withholding, j) in employee.withholdings" :key="j" class="withholding" :data-id="withholding.name">
													<td class="pl-12">{{ withholding.name }}</td>
													<td class="text-right">
														<div v-if="!withholding.isEditing"
															:class="employee.isVerified ? 'amount' : 'clickable amount'"
															:data-id="withholding.name"
															@click="() => { if (employee.isVerified) return; withholding.tempAmount = withholding.amount; withholding.isEditing = true; }">
															{{ formatAmount(withholding.amount, "0.00") }}
														</div>
														<div v-else>
															<VTextField :modelValue="formatAmount(withholding.amount)"
																class="amount-input"
																appendIcon="mdi-check-outline"
																autofocus
																@update:modelValue="value => withholding.tempAmount = value"
																@click:append="() => { withholding.amount = parseNumber(withholding.tempAmount); withholding.tempAmount = undefined; withholding.isEditing = undefined; }"
																@update:focused="isFocused => { if (isFocused) return; withholding.amount = parseNumber(withholding.tempAmount); withholding.tempAmount = undefined; withholding.isEditing = undefined; }" />
														</div>
													</td>
												</tr>
												<tr>
													<th>Net</th>
													<th class="text-right">{{ formatAmount(calculateNetPay(employee)) }}</th>
												</tr>
											</tbody>
										</VTable>
									</VCol>
									<VCol class="v-col-12 v-col-md-6">
										<VTable density="compact">
											<tbody>
												<tr>
													<th>Employer Contributions</th>
													<th></th>
												</tr>
												<tr v-for="(employerContribution, j) in employee.employerContributions" :key="j" class="employer-contribution" :data-id="employerContribution.name">
													<td class="pl-12">{{ employerContribution.name }}</td>
													<td class="text-right">
														<div v-if="!employerContribution.isEditing"
															:class="employee.isVerified ? 'amount' : 'clickable amount'"
															@click="() => { if (employee.isVerified) return; employerContribution.tempAmount = employerContribution.amount; employerContribution.isEditing = true; }">
															{{ formatAmount(employerContribution.amount, "0.00") }}
														</div>
														<div v-else>
															<VTextField :modelValue="formatAmount(employerContribution.amount)"
																class="amount-input"
																appendIcon="mdi-check-outline"
																autofocus
																@update:modelValue="value => employerContribution.tempAmount = value"
																@click:append="() => { employerContribution.amount = parseNumber(employerContribution.tempAmount); employerContribution.tempAmount = undefined; employerContribution.isEditing = undefined; }"
																@update:focused="isFocused => { if (isFocused) return; employerContribution.amount = parseNumber(employerContribution.tempAmount); employerContribution.tempAmount = undefined; employerContribution.isEditing = undefined; }" />
														</div>
													</td>
												</tr>
												<tr>
													<th>Total Employer Contributions</th>
													<th class="text-right">{{ formatAmount(calculateEmployerContributions(employee)) }}</th>
												</tr>
											</tbody>
										</VTable>
									</VCol>
								</VRow>
							</VCardText>
						</VCard>
					</VSheet>
				</VCarouselItem>
				<VCarouselItem>
					<VSheet height="100%">
						Skip step
					</VSheet>
				</VCarouselItem>
				<VCarouselItem>
					<VSheet height="100%">
						<h2>Payroll successfully run.</h2>
					</VSheet>
				</VCarouselItem>
			</VCarousel>
		</VSheet>
	</div>
</template>

<style scoped>
	.clickable {
		cursor: pointer;
	}

	.v-table.table-borderless .v-table__wrapper > table > tbody > tr:not(:last-child) > td,
	.v-table.table-borderless .v-table__wrapper > table > tbody > tr:not(:last-child) > th {
		border-bottom: none transparent 0;
	}
</style>
