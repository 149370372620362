<script setup>
	import { BaseLayout, iconClassNames } from "@/components";

	const primaryLinks = [
		{ routeName: "myHome", title: "Home", icon: iconClassNames.home, class: "home-navigation" },
		{ routeName: "myPayStubs", title: "Pay Stubs", icon: iconClassNames.payStub, class: "pay-stubs-navigation" },
	];
	const secondaryLinks = [];
</script>

<template>
	<BaseLayout areaTitle="My"
		areaRouteName="my"
		:areaIcon="iconClassNames.dashboard"
		:primaryLinks="primaryLinks"
		:secondaryLinks="secondaryLinks" />
</template>
