<script setup>
	import { reactive, watch } from "vue";
	import { CloseIcon, SaveIcon } from "@/components";
	import { PayrollRunIcon, ViewPayrollRunForm } from "./";

	const props = defineProps({
		payrollRunId: { type: String, required: false },
		shouldBeShowing: { type: Boolean, required: true },
	});
	const emit = defineEmits(["closed"]);

	const dialog = reactive({
		isEditing: false,
		isProcessing: false,
		isSaving: false,
		isShowing: false,
	});

	function emitClosed() { emit("closed"); }
	function onDoneSaving(isSuccessful) {
		dialog.isSaving = false;
		if (isSuccessful)
			dialog.isShowing = false;
	}
	function save() { dialog.isSaving = true; }
	function stopEdit() { dialog.isShowing = false; }

	watch(() => dialog.isShowing, () => {
		if (!dialog.isShowing)
			emitClosed();
	});
	watch(() => props.shouldBeShowing, (current, previous) => {
		if (!previous && current) {
			dialog.isShowing = true;
		}
	}, { immediate: true });
</script>

<template>
	<VDialog v-model="dialog.isShowing"
		scrim="true"
		transition="dialog-bottom-transition"
		:fullscreen="true">
		<VCard>
			<VToolbar color="primary">
				<VToolbarTitle class="view-payroll-run-dialog-title"><PayrollRunIcon/> Payroll Run Details</VToolbarTitle>
				<VSpacer></VSpacer>
				<VBtn v-if="dialog.isEditing"
					title="Save"
					class="save-payroll-changes-action"
					:disabled="dialog.isProcessing"
					@click="save">
					<SaveIcon/>
				</VBtn>
				<VBtn title="Close"
					:disabled="dialog.isProcessing"
					@click="stopEdit">
					<CloseIcon/>
				</VBtn>
			</VToolbar>
			<VCardText>
				<ViewPayrollRunForm :payrollRunId="props.payrollRunId"
					:isSaving="dialog.isSaving"
					@isEditing="dialog.isEditing = true"
					@doneSaving="isSuccessful => onDoneSaving(isSuccessful)"/>
			</VCardText>
		</VCard>
	</VDialog>
</template>
