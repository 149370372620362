<script>
import { BIconLinkedin } from "bootstrap-icons-vue";
import lucentaryUrl from "@/assets/img/lucentary-large.png"
import chadUrl from "@/assets/img/chad.png"

export default {
	name: "AboutView",

	components: {
		BIconLinkedin
	},

	data: () => ({
		lucentaryUrl: lucentaryUrl,
		chadUrl: chadUrl
	}),
}
</script>

<template>
	<div class="container">
		<section class="about">
			<div class="container-fluid p-0">
				<div class="row g-0">
					<div class="col-lg-4 text-white showcase-img" :style="{ backgroundImage: 'url(' + lucentaryUrl + ')' }"></div>
					<div class="col-lg-8 my-auto showcase-text p-4">
						<h1>About Lucentary</h1>
						<p class="lead">
							Lucentary Academy is committed to providing real-world training and learning for software development professionals.
							All too often, professionals are taught the computer programming science but are never provided the tools and skills necessary to cultivate the art and craft behind software development.
						</p>
						<p class="lead mb-0">
							We are determined to improve the overall quality of software development practices and knowledge in the industry.
							Through hands-on labs, minimal lectures, and graduated course design, we are working to create a better environment for software development professionals to learn the truly <em>best</em> practices.
						</p>
					</div>
				</div>
				<div class="row g-0">
					<div class="col-lg-4 order-lg-2 text-white showcase-img" :style="{ backgroundImage: 'url(' + chadUrl + ')' }"></div>
					<div class="col-lg-8 order-lg-1 my-auto showcase-text p-4">
						<h2>About Chad</h2>
						<p class="lead">
							Chad Greer is the founder, director, and instructor for Lucentary Academy.
							He has led close to 200 projects for more than 50 different IT companies and organizations over the last 20+ years.
							His extremely broad knowledge has served him and his clients well; and, now his experience can serve the futures of the students of Lucentary Academy.
						</p>
						<p class="lead">
							When coming up with the idea for Lucentary Academy and designing the courses, Chad pulled heavily on his experience in the early 2000s as a martial arts school owner and college instructor.
							Chad is focused on providing a positive and rewarding experience for every person with whom he interacts, student or otherwise.
						</p>
						<p class="lead mb-0">
							<a href="https://www.linkedin.com/in/chadegreer" target="_blank"><BIconLinkedin /></a>
						</p>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>
