<script setup>
	import { reactive, watch } from "vue";
	import { VCheckbox, VCol, VCombobox, VFileInput, VRow, VSelect, VTextField } from "vuetify/components";
	import { accountingService, executeServiceCall, getValidationErrorsFromResponse } from "@/services";
	import { formatAmount, hasAnySimpleValueChanged, parseNumber } from "@/utils";
	import { BaseEditDialog } from "@/components";
	import { EmployeeIcon } from "./";

	const props = defineProps({
		employeeId: { type: String, required: false },
		shouldBeEditing: { type: Boolean, required: true },
	});
	const emit = defineEmits([ "closed", "employeeUpdated" ]);

	const mainData = reactive({
		employee: null,
		hasCriticalError: false,
		isDirty: false,
		originalSnapshot: null,
		referenceData: null,
		validationErrors: [],
	});
	const rules = {
		lucentaryEmailAddressFormat: value => !!value.match(/^[a-zA-Z0-9._-]+@lucentary\.com$/) || "A lucentary.com email address is required.",
		required: value => !!value || "This value is required.",
	};

	function emitClosed() { emit("closed"); }
	function emitEmployeeUpdated() { emit("employeeUpdated", mainData.employee.id); }
	async function loadEmployee() {
		resetErrors();

		await executeServiceCall(accountingService.employee.referenceData)
			.then(({ data }) => mainData.referenceData = data)
			.catch(() => mainData.hasCriticalError = true);
		if (mainData.hasCriticalError)
			return;

		if (!props.employeeId) {
			mainData.employee = accountingService.employee.initializeEmployee();
		}
		else {
			await executeServiceCall(() => accountingService.employee.byId(props.employeeId))
				.then(({ data }) => {
					const employee = data;
					for (const fieldName of accountingService.employee.getCollectionFieldNames())
						employee[fieldName] = [];
					mainData.employee = employee;
				})
				.catch(() => mainData.hasCriticalError = true);
			if (mainData.hasCriticalError)
				return;
		}

		mainData.originalSnapshot = JSON.parse(JSON.stringify(mainData.employee));
	}
	function onBaseBeginSaving(done) { mainData.isSaving = true; done(); }
	function onBaseClosed(done) {
		resetErrors();
		mainData.isDirty = false;
		mainData.originalSnapshot = null;
		mainData.employee = null;
		mainData.referenceData = null;
		emitClosed();
		done();
	}
	function onBaseEndSaving(done) { mainData.isSaving = false; done(); }
	function onBaseItemUpdated(done) { emitEmployeeUpdated(); done(); }
	async function onBaseLoadingItem(done) { await loadEmployee(); done(); }
	async function onBaseSaving(done) { await saveEmployee(); done(); }
	function resetErrors() {
		mainData.hasCriticalError = false;
		mainData.validationErrors = [];
	}
	async function saveEmployee() {
		resetErrors();

		await executeServiceCall(() => accountingService.employee.save(mainData.employee))
			.then(({ data }) => mainData.employee.id = String(data))
			.catch(({ response }) => {
				if (response?.status === 400)
					mainData.validationErrors = getValidationErrorsFromResponse(response);
				else
					mainData.hasCriticalError = true;
			});
	}
	function updateIsDirty() {
		if (!mainData.employee || !mainData.originalSnapshot)
			return;

		const isDirty = !!mainData.employee.i9FormFile || !!mainData.employee.w4FormFile || mainData.employee.identificationFiles.length > 0
			|| hasAnySimpleValueChanged(mainData.employee, mainData.originalSnapshot, accountingService.employee.getSimpleValueFieldNames());
		mainData.isDirty = isDirty;
	}

	watch(() => mainData.employee, () => { updateIsDirty(); }, { deep: true });
</script>

<template>
	<BaseEditDialog subjectName="Employee"
		:isDeleteAllowed="false"
		:shouldBeEditing="props.shouldBeEditing"
		:isDirty="mainData.isDirty"
		:hasCriticalError="mainData.hasCriticalError"
		:validationErrors="mainData.validationErrors"
		:isFullscreen="true"
		@beginSaving="onBaseBeginSaving"
		@endSaving="onBaseEndSaving"
		@loadingItem="onBaseLoadingItem"
		@saving="onBaseSaving"
		@itemUpdated="onBaseItemUpdated"
		@closed="onBaseClosed">
		<template v-slot:title><EmployeeIcon/> Employee Management</template>
		<div v-if="mainData.employee">
			<VRow>
				<VCol class="v-col-12 v-col-md-8">
					<VSheet class="pa-4">
						<div>
							<h5>Contact</h5>
							<VRow>
								<VCol class="v-col-4">
									<VTextField v-model="mainData.employee.firstName"
										id="employeeFirstName"
										label="First Name"
										:rules="[rules.required]"
										:disabled="mainData.isSaving"
										autofocus />
								</VCol>
								<VCol class="v-col-4">
									<VTextField v-model="mainData.employee.middleName"
										id="employeeMiddleName"
										label="Middle Name"
										:disabled="mainData.isSaving" />
								</VCol>
								<VCol class="v-col-4">
									<VTextField v-model="mainData.employee.lastName"
										id="employeeLastName"
										label="Last Name"
										:rules="[rules.required]"
										:disabled="mainData.isSaving" />
								</VCol>
							</VRow>
							<VRow>
								<VCol class="v-col-6">
									<VTextField v-model="mainData.employee.preferredName"
										id="employeePreferredName"
										label="Preferred Name (optional)"
										:disabled="mainData.isSaving" />
								</VCol>
								<VCol class="v-col-6">
									<VCombobox v-model="mainData.employee.emailAddress"
										id="employeeEmailAddress"
										:items="mainData.referenceData.availableEmailAddresses"
										label="Email Address"
										:rules="[rules.required,rules.lucentaryEmailAddressFormat]"
										:disabled="mainData.isSaving" />
								</VCol>
							</VRow>
							<VRow>
								<VCol class="v-col-6">
									<VTextField v-model="mainData.employee.mailingAddressLine1"
										id="employeeMailingAddressLine1"
										label="Address Line 1"
										hideDetails="auto"
										:rules="[rules.required]"
										:disabled="mainData.isSaving" />
								</VCol>
								<VCol class="v-col-6">
									<VTextField v-model="mainData.employee.mailingAddressLine2"
										id="employeeMailingAddressLine2"
										label="Address Line 2"
										hideDetails="auto"
										:disabled="mainData.isSaving" />
								</VCol>
							</VRow>
							<VRow>
								<VCol class="v-col-6">
									<VTextField v-model="mainData.employee.mailingAddressCity"
										id="employeeMailingAddressCity"
										label="City"
										hideDetails="auto"
										:rules="[rules.required]"
										:disabled="mainData.isSaving" />
								</VCol>
								<VCol class="v-col-2">
									<VCombobox v-model="mainData.employee.mailingAddressStateAbbreviation"
										id="employeeMailingAddressState"
										label="State"
										hideDetails="auto"
										:items="['TX']"
										:rules="[rules.required]"
										:disabled="mainData.isSaving" />
								</VCol>
								<VCol class="v-col-4">
									<VTextField v-model="mainData.employee.mailingAddressPostalCode"
										id="employeeMailingAddressPostalCode"
										label="Postal Code"
										hideDetails="auto"
										:rules="[rules.required]"
										:disabled="mainData.isSaving" />
								</VCol>
							</VRow>
						</div>
					</VSheet>
				</VCol>
				<VCol class="v-col-12 v-col-md-4">
					<VSheet border rounded class="pa-4">
						<div>
							<h5>Employment</h5>
							<VTextField v-model="mainData.employee.socialSecurityNumber"
								id="employeeSocialSecurityNumber"
								label="Social Security Number"
								:rules="[rules.required]"
								:disabled="mainData.isSaving" />
							<VTextField v-model="mainData.employee.hireDate"
								id="employeeHireDate"
								type="date"
								label="Hire Date"
								:rules="[rules.required]"
								:disabled="mainData.isSaving" />
							<VSelect v-model="mainData.employee.employeeType"
								id="employeeType"
								:items="['Salaried','Waged']"
								label="Type"
								:rules="[rules.required]"
								:disabled="mainData.isSaving" />
							<VTextField v-if="mainData.employee.employeeType === 'Salaried'"
								:model-value="formatAmount(mainData.employee.yearlySalary || '')"
								id="employeeYearlySalary"
								label="Yearly Salary"
								:rules="[rules.required]"
								:disabled="mainData.isSaving"
								@update:model-value="value => mainData.employee.tempYearlySalary = value"
								@update:focused="isFocused => { if (!isFocused) mainData.employee.yearlySalary = parseNumber(mainData.employee.tempYearlySalary); }" />
							<VTextField v-if="mainData.employee.employeeType === 'Waged'"
								:model-value="formatAmount(mainData.employee.hourlyWage)"
								id="employeeHourlyWage"
								label="Hourly Wage"
								:rules="[rules.required]"
								:disabled="mainData.isSaving"
								@update:model-value="value => mainData.employee.tempHourlyWage = value"
								@update:focused="isFocused => { if (!isFocused) mainData.employee.hourlyWage = parseNumber(mainData.employee.tempHourlyWage); }" />
							<VSelect v-model="mainData.employee.payrollScheduleId"
								id="employeePayrollSchedule"
								label="Payroll Schedule"
								:items="mainData.referenceData.payrollSchedules"
								itemValue="id"
								itemTitle="name"
								:rules="[rules.required]"
								:disabled="mainData.isSaving" />
						</div>
					</VSheet>
				</VCol>
			</VRow>
			<VSheet border rounded class="pa-4 mt-4">
				<div>
					<h5>Taxes</h5>
					<VRow>
						<VCol class="v-col-12 v-col-md-4">
							<VCheckbox v-model="mainData.employee.w4IsExempt"
								id="employeeW4IsExempt"
								label="Is Exempt?"
								:disabled="mainData.isSaving" />
						</VCol>
						<VCol class="v-col-12 v-col-md-4">
							<VSelect v-model="mainData.employee.w4FilingStatus"
								id="employeeW4FilingStatus"
								label="W-4 Filing Status (Step 1c)"
								:items="['Single','Married filing separately','Married filing jointly','Qualifying surviving spouse','Head of household']"
								:rules="[rules.required]"
								:disabled="mainData.isSaving || mainData.employee.w4IsExempt" />
						</VCol>
						<VCol class="v-col-12 v-col-md-4">
							<VTextField v-model="mainData.employee.w4AnnualWithholdingReductionAmount"
								id="employeeW4AnnualWithholdingReductionAmount"
								type="number"
								label="Annual Withholding Reduction Amount"
								hint="From W-4 Step 3."
								:disabled="mainData.isSaving || mainData.employee.w4IsExempt" />
						</VCol>
					</VRow>
					<VRow>
						<VCol class="v-col-12 v-col-md-4">
							<VTextField v-model="mainData.employee.w4AnnualWageForWithholdingIncreaseAmount"
								id="employeeW4AnnualWageForWithholdingIncreaseAmount"
								type="number"
								label="Increase Annual Wage for Withholding"
								hint="From W-4 Step 4(a)."
								:disabled="mainData.isSaving || mainData.employee.w4IsExempt" />
						</VCol>
						<VCol class="v-col-12 v-col-md-4">
							<VTextField v-model="mainData.employee.w4AnnualWageForWithholdingDecreaseAmount"
								id="employeeW4AnnualWageForWithholdingDecreaseAmount"
								type="number"
								label="Decrease Annual Wage for Withholding"
								hint="From W-4 Step 4(b)."
								:disabled="mainData.isSaving || mainData.employee.w4IsExempt" />
						</VCol>
						<VCol class="v-col-12 v-col-md-4">
							<VTextField v-model="mainData.employee.w4AdditionalWithholdingAmount"
								id="employeeW4AdditionalWithholdingAmount"
								type="number"
								label="Extra Withholding Per Pay Period"
								hint="From W-4 Step 4(c)."
								:disabled="mainData.isSaving || mainData.employee.w4IsExempt" />
						</VCol>
					</VRow>
					<VRow>
						<VCol class="v-col-12 v-col-md-4">
							<VFileInput v-model="mainData.employee.identificationFiles"
								id="employeeIdentificationFiles"
								label="Identification Files"
								class="employee-identification-files"
								:hint="mainData.employee.identificationFileCount > 0 ? `Uploading files will WIPE OUT the existing ${mainData.employee.identificationFileCount} files.  Make sure ALL files are uploaded at once.` : 'Make sure ALL files are uploaded at once.  This is mostly easily done by having all files in the same folder on your local computer.'"
								chips
								multiple
								showSize
								:persistentHint="!!mainData.employee.identificationFileCount"
								:disabled="mainData.isSaving" />
						</VCol>
						<VCol class="v-col-12 v-col-md-4">
							<VFileInput v-model="mainData.employee.i9FormFile"
								id="employeeI9FormFile"
								label="I-9 File"
								class="employee-i9-form-file"
								chips
								showSize
								:hint="mainData.employee.hasI9FormFile ? 'Uploading a file will WIPE OUT the existing file.' : undefined"
								:persistentHint="!!mainData.employee.hasI9FormFile"
								:disabled="mainData.isSaving" />
						</VCol>
						<VCol class="v-col-12 v-col-md-4">
							<VFileInput v-model="mainData.employee.w4FormFile"
								id="employeeW4FormFile"
								label="W-4 File"
								class="employee-w4-form-file"
								chips
								showSize
								:hint="mainData.employee.hasW4FormFile ? 'Uploading a file will WIPE OUT the existing file.' : undefined"
								:persistentHint="!!mainData.employee.hasW4FormFile"
								:disabled="mainData.isSaving" />
						</VCol>
					</VRow>
				</div>
			</VSheet>
		</div>
	</BaseEditDialog>
</template>
