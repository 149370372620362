const iconClassNames = {
	academic: "mdi-school",
	accounting: "mdi-currency-usd",
	account: "mdi-table-large",
	addPrimary: "mdi-plus",
	addSecondary: "mdi-plus-circle-outline",
	cardsView: "mdi-cards",
	classAttendance: "mdi-calendar-account",
	classes: "mdi-library",
	close: "mdi-close",
	course: "mdi-book-open",
	customerPayment: "mdi-cash",
	dashboard: "mdi-view-dashboard",
	deactivate: "mdi-playlist-remove",
	delete: "mdi-delete",
	edit: "mdi-pencil",
	employee: "mdi-account-tie",
	error: "mdi-close-circle-outline",
	exit: "mdi-exit-to-app",
	home: "mdi-home",
	info: "mdi-information-variant",
	invoice: "mdi-file-multiple",
	listView: "mdi-view-list",
	new: "mdi-new-box",
	paidInFull: "mdi-file-check",
	party: "mdi-folder-account",
	payrollRun: "mdi-account-cash-outline",
	payrollSchedule: "mdi-calendar-clock",
	payStub: "mdi-file-account-outline",
	print: "mdi-printer",
	product: "mdi-tag-multiple",
	prospect: "mdi-face-agent",
	reactivate: "mdi-playlist-check",
	refresh: "mdi-refresh",
	removePrimary: "mdi-minus",
	removeSecondary: "mdi-minus-circle-outline",
	runPayroll: "mdi-cash-check",
	save: "mdi-content-save",
	settings: "mdi-tune",
	transaction: "mdi-repeat",
	transactionHistory: "mdi-chart-timeline",
	viewDetails: "mdi-view-list",
	warning: "mdi-exclamation",
};

export default iconClassNames;
