import { createApp } from "vue";
import { createPinia } from "pinia";
import vuetify from "./plugins/vuetify";

import App from "./App.vue";
import { getRouter } from "./router";
import { loadFonts } from "./plugins/webfontloader";
import "bootstrap/dist/css/bootstrap.min.css";
import "@/assets/main.css";

loadFonts();

const app = createApp(App);

app.use(vuetify);
app.use(createPinia());
app.use(getRouter());

app.mount("#app");
