<script setup>
	import { reactive, watch } from "vue";
	import { academicService, executeServiceCall, getValidationErrorsFromResponse } from "@/services";
	import { hasAnySimpleValueChanged, hasArrayChanged } from "@/utils";
	import { BaseEditDialog } from "@/components";
	import { ClassIcon } from "../components";

	// Public Interface
	const props = defineProps({
		classId: { type: String, required: false },
		courseName: { type: String, required: false },
		courses: { type: Array, required: true },
		shouldBeEditing: { type: Boolean, required: true },
	});
	const emit = defineEmits([ "closed", "classUpdated" ]);

	// Data
	const mainData = reactive({
		hasCriticalError: false,
		ignoreIsDirty: false,
		isDeleteAllowed: false,
		isDirty: false,
		isEditing: false,
		isProcessing: false,
		isShowing: false,
		isUndeleteAllowed: false,
		lucentaryClass: null,
		originalClass: null,
		validationErrors: [],
	});

	// Functions
	async function deleteClass() {
		if (!props.classId)
			return;

		resetErrors();
		mainData.isProcessing = true;

		await executeServiceCall(() => academicService.class.delete(props.classId))
			.catch(error => {
				console.error(error);
				mainData.hasCriticalError = true;
			})
			.finally(() => mainData.isProcessing = false);
	};
	function emitClosed() { emit("closed"); }
	function emitClassUpdated() { emit("classUpdated", mainData.lucentaryClass.id); }
	function getObjectives() {
		const course = props.courses.find(course => course.name === mainData.lucentaryClass.courseName);
		if (!course)
			return [];
		return course.objectives;
	};
	async function loadClass() {
		resetErrors();

		if (!props.classId) {
			mainData.lucentaryClass = {
				name: "",
				courseName: props.courseName,
				description: "",
				objectives: [],
				materialCount: 0,
				isDeleted: false,
			};
			mainData.isProcessing = false;
			mainData.isDeleteAllowed = false;
			mainData.isUndeleteAllowed = false;
		}
		else {
			await executeServiceCall(() => academicService.class.byId(props.classId))
				.then(({ data }) => {
					mainData.lucentaryClass = data;
					mainData.isDeleteAllowed = !mainData.lucentaryClass.isDeleted;
					mainData.isUndeleteAllowed = mainData.lucentaryClass.isDeleted;
				})
				.catch(() => mainData.hasCriticalError = true)
				.finally(() => mainData.isProcessing = false);
		}

		mainData.originalClass = JSON.parse(JSON.stringify(mainData.lucentaryClass));
	};
	function onBaseBeginSaving(done) { mainData.isProcessing = true; done(); }
	function onBaseClosed(done) {
		resetErrors();
		mainData.isDirty = false;
		mainData.originalClass = null;
		mainData.lucentaryClass = null;
		emitClosed();
		done();
	}
	async function onBaseDeleting(done) { await deleteClass(); done(); }
	function onBaseEndSaving(done) { mainData.isProcessing = false; done(); }
	function onBaseItemDeleted(done) { emitClassUpdated(); done(); }
	function onBaseItemUndeleted(done) { emitClassUpdated(); done(); }
	function onBaseItemUpdated(done) { emitClassUpdated(); done(); }
	async function onBaseLoadingItem(done) { await loadClass(); done(); }
	async function onBaseSaving(done) { await saveClass(); done(); }
	async function onBaseUndeleting(done) { await reactivateClass(); done(); }
	async function reactivateClass() {
		if (!props.classId)
			return;

		resetErrors();
		mainData.isProcessing = true;

		await executeServiceCall(() => academicService.class.reactivate(props.classId))
			.catch(error => {
				console.error(error);
				mainData.hasCriticalError = true;
			})
			.finally(() => mainData.isProcessing = false);
	};
	function resetErrors() {
		mainData.hasCriticalError = false;
		mainData.validationErrors = [];
	}
	async function saveClass() {
		resetErrors();

		await executeServiceCall(() => academicService.class.save(mainData.lucentaryClass))
			.then(({ data }) => mainData.lucentaryClass.id = String(data))
			.catch(({ response }) => {
				if (response?.status === 400)
					mainData.validationErrors = getValidationErrorsFromResponse(response);
				else
					mainData.hasCriticalError = true;
			});
	}
	function updateIsDirty() {
		if (!mainData.lucentaryClass || !mainData.originalClass)
			return;

		const isDirty = hasAnySimpleValueChanged(
			mainData.lucentaryClass,
			mainData.originalClass,
			["name","courseName","description"])
			|| hasArrayChanged(mainData.lucentaryClass.objectives, mainData.originalClass.objectives);
		mainData.isDirty = isDirty;
	}

	watch(() => mainData.lucentaryClass, () => { updateIsDirty(); }, { deep: true });
</script>

<template>
	<BaseEditDialog subjectName="Class"
		:isFullscreen="true"
		:isDeleteAllowed="mainData.isDeleteAllowed"
		:isUndeleteAllowed="mainData.isUndeleteAllowed"
		:shouldBeEditing="props.shouldBeEditing"
		:isDirty="mainData.isDirty"
		:hasCriticalError="mainData.hasCriticalError"
		:validationErrors="mainData.validationErrors"
		@loadingItem="onBaseLoadingItem"
		@beginSaving="onBaseBeginSaving"
		@saving="onBaseSaving"
		@endSaving="onBaseEndSaving"
		@deleting="onBaseDeleting"
		@itemDeleted="onBaseItemDeleted"
		@undeleting="onBaseUndeleting"
		@itemUndeleted="onBaseItemUndeleted"
		@itemUpdated="onBaseItemUpdated"
		@closed="onBaseClosed">
		<template v-slot:title><ClassIcon/> Class Management</template>
		<div v-if="mainData.lucentaryClass">
			<VRow>
				<VCol class="v-col-12 v-col-md-8">
					<VTextField id="className"
						v-model="mainData.lucentaryClass.name"
						label="Name"
						hint="The name must be unique within the Course."
						:counter="50"
						:disabled="mainData.isProcessing"
						required
						autofocus />
				</VCol>
				<VCol class="v-col-12 v-col-md-4">
				<VSelect id="courseName"
					v-model="mainData.lucentaryClass.courseName"
					label="Course"
					:items="props.courses.map(course => course.name)"
					:disabled="mainData.isProcessing" />
				</VCol>
			</VRow>
			<VRow>
				<VCol class="v-col-12 v-col-md-6">
					<VTextarea id="classDescription"
						v-model="mainData.lucentaryClass.description"
						label="Description"
						hint="This description will be visible to Students."
						rows="8"
						:disabled="mainData.isProcessing" />
					<VFileInput
						v-model="mainData.lucentaryClass.materialFiles"
						label="Class Materials"
						:hint="mainData.lucentaryClass.materialCount > 0 ? `Uploading files will WIPE OUT the existing ${mainData.lucentaryClass.materialCount} files.  Make sure ALL files are uploaded at once.` : 'Make sure ALL files are uploaded at once.  This is mostly easily done by having all files in the same folder on your local computer.'"
						chips
						multiple
						show-size
						persistent-hint
						:disabled="mainData.isProcessing" />
				</VCol>
				<VCol class="v-col-12 v-col-md-6">
					<VInput>Objectives</VInput>
					<VCheckbox v-model="mainData.lucentaryClass.objectives"
						v-for="(objective, i) in getObjectives()" :key="i"
						class="objective"
						:label="objective"
						:value="objective"
						density="compact"
						:disabled="mainData.isProcessing" />
				</VCol>
			</VRow>
		</div>
	</BaseEditDialog>
</template>
