<script setup>
	import { reactive, watch } from "vue";
	import { VAlert, VBtn, VCard, VCardActions, VCardText, VCheckbox, VCol, VDialog, VProgressLinear, VRow, VSelect, VSnackbar, VSpacer, VTextarea, VTextField, VToolbar, VToolbarTitle } from "vuetify/components";
	import { accountingService, executeServiceCall } from "@/services";
	import { CloseIcon, DeleteIcon, SaveIcon } from "@/components";
	import PartyIcon from "./PartyIcon.vue";
	import { hasAnySimpleValueChanged } from "@/utils";
	import BaseEditDialog from "@/components/BaseEditDialog.vue";

	const props = defineProps({
		isDeleteAllowed: { type: Boolean, required: false, default: false },
		partyId: { type: String, required: false },
		shouldBeEditing: { type: Boolean, required: true },
	});
	const emit = defineEmits([ "closed", "partyDeleted", "partyUpdated" ]);

	const mainData = reactive({
		hasCriticalError: false,
		isDirty: false,
		originalSnapshot: null,
		party: null,
		referenceData: null,
		validationErrors: [],
	});
	const rules = {
		required: value => !!value || "This value is required.",
	};

	async function deleteParty() {
		resetErrors();
		if (!mainData.party.id)
			return;

		await executeServiceCall(() => accountingService.party.delete(mainData.party.id))
			.catch(() => mainData.hasCriticalError = true);
	}
	function emitClosed() { emit("closed"); }
	function emitPartyDeleted() { emit("partyDeleted"); }
	function emitPartyUpdated() { emit("partyUpdated", mainData.party.id); }
	async function loadParty() {
		resetErrors();

		await executeServiceCall(accountingService.party.referenceData)
			.then(({ data }) => mainData.referenceData = data)
			.catch(() => mainData.hasCriticalError = true);
		if (mainData.hasCriticalError)
			return;

		if (!props.partyId) {
			mainData.party = {
				name: null,
				isCustomer: false,
				isSupplier: false,
				customerInfo: {},
				supplierInfo: {},
			};
		}
		else {
			await executeServiceCall(() => accountingService.party.byId(props.partyId))
				.then(({ data }) => mainData.party = data)
				.catch(() => mainData.hasCriticalError = true);
			if (mainData.hasCriticalError)
				return;
		}

		mainData.originalSnapshot = JSON.parse(JSON.stringify(mainData.party));
	}
	function onBaseBeginSaving(done) { mainData.isSaving = true; done(); }
	function onBaseClosed(done) {
		resetErrors();
		mainData.isDirty = false;
		mainData.originalSnapshot = null;
		mainData.party = null;
		mainData.referenceData = null;
		emitClosed();
		done();
	}
	async function onBaseDeleting(done) { await deleteParty(); done(); }
	function onBaseEndSaving(done) { mainData.isSaving = false; done(); }
	function onBaseItemDeleted(done) { emitPartyDeleted(); done(); }
	function onBaseItemUpdated(done) { emitPartyUpdated(); done(); }
	async function onBaseLoadingItem(done) { await loadParty(); done(); }
	async function onBaseSaving(done) { await saveParty(); done(); }
	function resetErrors() {
		mainData.hasCriticalError = false;
		mainData.validationErrors = [];
	}
	async function saveParty() {
		resetErrors();

		await executeServiceCall(() => accountingService.party.save(mainData.party))
			.then(({ data }) => mainData.party.id = String(data))
			.catch(({ response }) => {
				if (response?.status === 400) {
					if (typeof response.data === "array")
						mainData.validationErrors = response.data;
					else
						mainData.validationErrors = ["One or more validations failed.  Check your inputs and try again."];
				}
				else {
					mainData.hasCriticalError = true;
				}
			});
	}
	function updateIsDirty() {
		if (!mainData.party || !mainData.originalSnapshot)
			return;

		const isDirty = hasAnySimpleValueChanged(mainData.party, mainData.originalSnapshot, ["name","isCustomer","isSupplier"])
			|| hasAnySimpleValueChanged(mainData.party.customerInfo, mainData.originalSnapshot.customerInfo, ["billingAddress","defaultAccountsReceivableAccountId","defaultTerms"]);
		mainData.isDirty = isDirty;
	}

	watch(() => mainData.party, () => { updateIsDirty(); }, { deep: true });
</script>

<template>
	<BaseEditDialog subjectName="Party"
		:isDeleteAllowed="props.isDeleteAllowed && !!props.partyId"
		:shouldBeEditing="props.shouldBeEditing"
		:isDirty="mainData.isDirty"
		:hasCriticalError="mainData.hasCriticalError"
		:validationErrors="mainData.validationErrors"
		@beginSaving="onBaseBeginSaving"
		@endSaving="onBaseEndSaving"
		@loadingItem="onBaseLoadingItem"
		@saving="onBaseSaving"
		@deleting="onBaseDeleting"
		@itemDeleted="onBaseItemDeleted"
		@itemUpdated="onBaseItemUpdated"
		@closed="onBaseClosed">
		<template v-slot:title><PartyIcon/> Party Management</template>
		<v-row v-if="mainData.party">
			<v-col class="v-col-12 v-col-md-4">
				<v-text-field v-model="mainData.party.name"
					id="partyName"
					label="Name"
					:rules="[rules.required]"
					:disabled="mainData.isSaving"
					autofocus />
			</v-col>
			<v-col class="v-col-12 v-col-md-4">
				<v-checkbox v-model="mainData.party.isCustomer"
					id="partyIsCustomer"
					label="Is a Customer?"
					:disabled="mainData.isSaving" />
				<v-textarea v-model="mainData.party.customerInfo.billingAddress"
					id="partyBillingAddress"
					label="Billing Address"
					rows="3"
					:disabled="mainData.isSaving || !mainData.party.isCustomer" />
				<v-select v-model="mainData.party.customerInfo.defaultAccountsReceivableAccountId"
					id="partyDefaultAccountsReceivableAccount"
					:items="mainData.referenceData?.accounts"
					item-value="id"
					item-title="name"
					label="Default A/R Account"
					:disabled="mainData.isSaving || !mainData.party.isCustomer" />
				<v-text-field v-model="mainData.party.customerInfo.defaultTerms"
					id="partyDefaultTerms"
					label="Default Terms"
					placeholder="e.g. NET 30 or NET NEXT 15TH"
					:disabled="mainData.isSaving || !mainData.party.isCustomer" />
			</v-col>
			<v-col class="v-col-12 v-col-md-4">
				<v-checkbox v-model="mainData.party.isSupplier"
					label="Is a Suppler?"
					:disabled="mainData.isSaving" />
			</v-col>
		</v-row>
	</BaseEditDialog>
</template>
