<script setup>
	import { reactive, watch } from "vue";
	import { VAutocomplete, VBtn, VCol, VDivider, VListItem, VListSubheader, VRow, VTable, VTextField } from "vuetify/components";
	import { AddSecondaryIcon, RemoveSecondaryIcon, TransactionIcon } from "@/components";
	import { accountingService, executeServiceCall } from "@/services";
	import { formatAmount, hasArrayChanged, hasAnySimpleValueChanged, parseNumber } from "@/utils";
	import BaseEditDialog from "@/components/BaseEditDialog.vue";

	const props = defineProps({ shouldBeEditing: Boolean, transactionId: String })
	const emit = defineEmits([ "closed", "transactionUpdated" ]);

	const mainData = reactive({
		hasCriticalError: false,
		isDirty: false,
		isSaving: false,
		originalSnapshot: null,
		referenceData: null,
		transaction: null,
		validationErrors: [],
	});
	const rules = {
		required: value => !!value || "You must enter a value.",
	};

	function addTransactionRow() {
		const newRow = { accountId: "", memo: "", debitAmount: 0.0, creditAmount: 0.0 };
		const { transactionRows } = mainData.transaction;

		// Pre-populate Memo if all values are the same.
		const distinctMemos = transactionRows
			.filter(row => row.memo?.length > 0)
			.map(row => row.memo)
			.filter((memo, index, array) => array.indexOf(memo) === index);
		if (distinctMemos.length === 1)
			newRow.memo = distinctMemos[0];

		// Set the Debit/Credit amount if the transaction is out of balance.
		const balances = getBalances();
		if (balances.totalCreditAmount > balances.totalDebitAmount) {
			newRow.debitAmount = balances.totalCreditAmount - balances.totalDebitAmount;
			newRow.debitAmountText = formatAmount(newRow.debitAmount);
		}
		else if (balances.totalDebitAmount > balances.totalCreditAmount) {
			newRow.creditAmount = balances.totalDebitAmount - balances.totalCreditAmount;
			newRow.creditAmountText = formatAmount(newRow.creditAmount);
		}

		transactionRows.push(newRow);
	}
	function buildAccountTypes() {
		const accountTypes = [ "Asset", "Liability", "Equity", "Income", "Cost of Goods", "Expense" ];
		let accountGroupings = [];

		accountTypes.forEach((accountType, i) => {
			accountGroupings.push({ props: { header: accountType } });
			mainData.referenceData.accounts.filter(account => account.accountType === accountType)
				.forEach(account => accountGroupings.push({ title: `${account.number}: ${account.name}`, value: account.id }));

			if (i < accountTypes.length - 1)
				accountGroupings.push({ props: { divider: true } });
		});

		mainData.referenceData.accountTypes = accountGroupings;
	}
	async function deleteTransaction() {
		resetErrors();
		if (!mainData.transaction?.id)
			return;

		await executeServiceCall(() => accountingService.transaction.delete(mainData.transaction.id))
			.catch(() => mainData.hasCriticalError = true);
	}
	function emitClosed() { emit("closed"); }
	function emitTransactionUpdated() { emit("transactionUpdated"); }
	function generateOriginalSnapshot() {
		mainData.originalSnapshot = JSON.parse(JSON.stringify(mainData.transaction));
		mainData.isDirty = false;
	}
	function getBalances() {
		let totalCreditAmount = 0;
		let totalDebitAmount = 0;
		mainData.transaction.transactionRows.forEach(row => {
			totalCreditAmount += row.creditAmount;
			totalDebitAmount += row.debitAmount;
		});
		return { totalCreditAmount: parseNumber(totalCreditAmount.toFixed(2)), totalDebitAmount: parseNumber(totalDebitAmount.toFixed(2)) };
	}
	function getTotalCredits() {
		const balances = getBalances();
		return formatAmount(balances.totalCreditAmount)
	}
	function getTotalDebits() {
		const balances = getBalances();
		return formatAmount(balances.totalDebitAmount)
	}
	function isOutOfBalance() {
		const balances = getBalances();
		return balances.totalCreditAmount != balances.totalDebitAmount;
	}
	async function loadTransactionData() {
		resetErrors();
		await executeServiceCall(accountingService.transaction.referenceData)
			.then(({ data }) => {
				mainData.referenceData = data;
				buildAccountTypes();
			})
			.catch(() => mainData.hasCriticalError = true);
		if (mainData.hasCriticalError)
			return;

		if (!props.transactionId) {
			mainData.transaction = {
				date: "",
				reference: "",
				partyId: "",
				transactionRows: [
					{ accountId: "", memo: "", debitAmount: 0.0, creditAmount: 0.0 },
				]
			};
		}
		else {
			await executeServiceCall(() => accountingService.transaction.byId(props.transactionId))
				.then(({ data }) => {
					mainData.transaction = data;
					mainData.transaction.transactionRows.forEach(transactionRow => {
						transactionRow.debitAmountText = formatAmount(transactionRow.debitAmount);
						transactionRow.creditAmountText = formatAmount(transactionRow.creditAmount);
					});
				})
				.catch(() => mainData.hasCriticalError = true);
		}

		if (!mainData.hasCriticalError)
			generateOriginalSnapshot();
	}
	function onBaseBeginSaving(done) { mainData.isSaving = true; done(); }
	function onBaseClosed(done) {
		resetErrors();
		mainData.isDirty = false;
		mainData.originalSnapshot = null;
		mainData.transaction = null;
		emitClosed();
		done();
	}
	async function onBaseDeleting(done) { await deleteTransaction(); done(); }
	function onBaseEndSaving(done) { mainData.isSaving = false; done(); }
	function onBaseItemDeleted(done) { emitTransactionUpdated(); done(); }
	function onBaseItemUpdated(done) { emitTransactionUpdated(); done(); }
	async function onBaseLoadingItem(done) { await loadTransactionData(); done(); }
	async function onBaseSaving(done) { await saveTransaction(); done(); }
	function removeTransactionRow(index) {
		mainData.transaction.transactionRows.splice(index, 1);
		while (mainData.transaction.transactionRows.length < 2)
			addTransactionRow();
	}
	function resetErrors() {
		mainData.hasCriticalError = false;
		mainData.validationErrors = [];
	}
	async function saveTransaction() {
		resetErrors();
		await executeServiceCall(() => accountingService.transaction.save(mainData.transaction))
			.catch(({ response }) => {
				if (response?.status === 400) {
					if (typeof response.data === "array")
						mainData.validationErrors = response.data;
					else
						mainData.validationErrors = ["One or more field validations failed.  Check the inputs and try again."];
				}
				else {
					mainData.hasCriticalError = true;
				}
			});
	}
	function setCreditAmount(transactionRow) {
		const parsedValue = parseNumber(transactionRow.creditAmountText);
		if (parsedValue == 0) {
			if (transactionRow.debitAmount == 0)
				transactionRow.creditAmount = 0.0;
		}
		else if (parsedValue > 0) {
			transactionRow.creditAmount = parsedValue;
			transactionRow.debitAmount = 0.0;
		}
		else {
			transactionRow.debitAmount = -parsedValue;
			transactionRow.creditAmount = 0.0;
		}
		transactionRow.creditAmountText = formatAmount(transactionRow.creditAmount);
		transactionRow.debitAmountText = formatAmount(transactionRow.debitAmount);
	}
	function setDebitAmount(transactionRow) {
		const parsedValue = parseNumber(transactionRow.debitAmountText);
		if (parsedValue == 0) {
			if (transactionRow.creditAmount == 0)
				transactionRow.debitAmount = 0.0;
		}
		else if (parsedValue > 0) {
			transactionRow.debitAmount = parsedValue;
			transactionRow.creditAmount = 0.0;
		}
		else {
			transactionRow.creditAmount = -parsedValue;
			transactionRow.debitAmount = 0.0;
		}
		transactionRow.creditAmountText = formatAmount(transactionRow.creditAmount);
		transactionRow.debitAmountText = formatAmount(transactionRow.debitAmount);
	}
	function updateIsDirty() {
		const { transaction, originalSnapshot } = mainData;
		if (!transaction || !originalSnapshot)
			return;

		const isDirty = hasAnySimpleValueChanged(transaction, originalSnapshot, ["date","reference","partyId"])
			|| hasArrayChanged(transaction.transactionRows, originalSnapshot.transactionRows, ["accountId","memo","debitAmount","creditAmount"]);
		mainData.isDirty = isDirty;
	}

	watch(() => mainData.transaction, updateIsDirty, { deep: true });
</script>

<template>
	<div>
		<BaseEditDialog subjectName="Transaction"
			:shouldBeEditing="props.shouldBeEditing"
			:isDirty="mainData.isDirty"
			:hasCriticalError="mainData.hasCriticalError"
			:validationErrors="mainData.validationErrors"
			:isDeleteAllowed="mainData.transaction?.isDeletable || false"
			:isSaveAllowed="mainData.transaction?.isEditable || true"
			@beginSaving="onBaseBeginSaving"
			@endSaving="onBaseEndSaving"
			@deleting="onBaseDeleting"
			@loadingItem="onBaseLoadingItem"
			@saving="onBaseSaving"
			@itemDeleted="onBaseItemDeleted"
			@itemUpdated="onBaseItemUpdated"
			@closed="onBaseClosed">
			<template v-slot:title><TransactionIcon/> {{ props.transactionId ? 'Edit Transaction' : 'New Transaction' }}</template>
			<div v-if="mainData.transaction">
				<v-row>
					<v-col class="v-col-12 v-col-md-4">
						<v-text-field id="date" v-model="mainData.transaction.date"
							type="date"
							label="Date"
							:readonly="mainData.transaction.isEditable === false"
							:rules="[rules.required]" :disabled="mainData.isSaving" />
					</v-col>
					<v-col class="v-col-12 v-col-md-4">
						<v-text-field id="reference" v-model="mainData.transaction.reference"
							label="Reference"
							:counter="20"
							:readonly="mainData.transaction.isEditable === false"
							:disabled="mainData.isSaving" />
					</v-col>
					<v-col class="v-col-12 v-col-md-4">
						<v-autocomplete id="party" v-model="mainData.transaction.partyId"
							:items="mainData.referenceData.parties"
							item-title="name"
							item-value="id"
							label="Party"
							:readonly="mainData.transaction.isEditable === false"
							:disabled="mainData.isSaving" />
					</v-col>
				</v-row>
				<v-row>
					<v-col class="v-col-12">
						<v-table density="compact">
							<colgroup>
								<col width="10%"/>
								<col width="30%"/>
								<col width="30%"/>
								<col width="10%"/>
								<col width="10%"/>
								<col width="10%"/>
							</colgroup>
							<thead style="background-color: white;">
								<th></th>
								<th>Account</th>
								<th>Memo</th>
								<th>Debit</th>
								<th>Credit</th>
								<th></th>
							</thead>
							<tbody>
								<tr v-for="(transactionRow, i) in mainData.transaction.transactionRows" class="text-sm">
									<td class="text-center">
										<v-btn v-if="mainData.transaction.isEditable !== false" color="secondary"
											size="x-small"
											:disabled="mainData.isSaving"
											@click="removeTransactionRow(i)">
											<RemoveSecondaryIcon font-size="18px"/>
										</v-btn>
									</td>
									<td>
										<v-autocomplete v-model="transactionRow.accountId"
											:items="mainData.referenceData.accountTypes"
											label="Account"
											hide-details
											:readonly="mainData.transaction.isEditable === false"
											:rules="[rules.required]" :disabled="mainData.isSaving">
											<template #item="data">
												<v-list-subheader v-if="data.props.header">{{ data.props.header }}</v-list-subheader>
												<v-divider v-else-if="data.props.divider"></v-divider>
												<v-list-item v-else v-bind="data.props"></v-list-item>
											</template>
										</v-autocomplete>
									</td>
									<td>
										<v-text-field v-model="transactionRow.memo"
											hide-details
											:readonly="mainData.transaction.isEditable === false"
											:disabled="mainData.isSaving" />
									</td>
									<td>
										<v-text-field v-model="transactionRow.debitAmountText"
											hide-details
											:readonly="mainData.transaction.isEditable === false"
											:disabled="mainData.isSaving"
											@update:focused="isFocused => { if (!isFocused) setDebitAmount(transactionRow); }" />
									</td>
									<td>
										<v-text-field v-model="transactionRow.creditAmountText"
											hide-details
											:readonly="mainData.transaction.isEditable === false"
											:disabled="mainData.isSaving"
											@update:focused="isFocused => { if (!isFocused) setCreditAmount(transactionRow); }" />
									</td>
									<td class="text-center">
										<v-btn v-if="i === mainData.transaction.transactionRows.length - 1 && mainData.transaction.isEditable !== false"
											color="primary"
											size="x-small"
											class="add-transaction-row-action"
											:disabled="mainData.isSaving"
											@click="addTransactionRow">
											<AddSecondaryIcon :fontSize="'18px'"/>
										</v-btn>
									</td>
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<td colspan="3" class="text-center">
										<span v-if="isOutOfBalance()" class="text-danger"><strong>Transaction is out of balance</strong></span>
									</td>
									<th>{{ getTotalDebits() }}</th>
									<th>{{ getTotalCredits() }}</th>
									<td></td>
								</tr>
							</tfoot>
						</v-table>
					</v-col>
				</v-row>
			</div>
		</BaseEditDialog>
	</div>
</template>

<style scoped>
	.v-table table tbody tr td {
		padding-left: 0;
		padding-right: 0;
	}
	.v-table table tbody tr td {
		height: 40px;
		border: none;
	}
	.v-table .v-table__wrapper > table > tbody > tr:not(:last-child) > td {
		border-bottom: none;
	}
	.text-sm :deep(input), .text-sm :deep(.v-autocomplete__selection-text) {
		font-size: 0.8em;
	}
</style>
