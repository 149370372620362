<script setup>
	import { reactive, watch } from "vue";
	import { CloseIcon, DeleteIcon, NewIcon, ReactivateIcon, SaveIcon } from "@/components";

	const props = defineProps({
		hasCriticalError: { type: Boolean, required: false, default: false },
		isDeleteAllowed: { type: Boolean, required: false, default: true },
		isDirty: { type: Boolean, required: false },
		isFullscreen: { type: Boolean, required: false },
		isPrintAllowed: { type: Boolean, required: false, default: false },
		isSaveAllowed: { type: Boolean, required: false, default: true },
		isSaveAndNewAllowed: { type: Boolean, required: false, default: false },
		isUndeleteAllowed: { type: Boolean, required: false, default: false },
		shouldBeEditing: { type: Boolean, required: true },
		subjectName: { type: String, required: true },
		validationErrors: { type: Array, required: false, default: null },
		width: { type: String, required: false },
	});
	const emit = defineEmits([ "beginSaving", "closed", "deleting", "endSaving", "itemDeleted", "itemUpdated", "itemUndeleted", "loadingItem", "resetData", "saving", "undeleting" ]);

	const mainData = reactive({
		hasData: false,
		ignoreIsDirty: false,
		isEditing: false,
		isLoading: false,
		isSaving: false,
		isShowing: false,
	});
	const promptDialog = reactive({
		isPromptingUser: false,
		promptConfirmationAction: null,
		userPrompt: "",
	});
	const snackbarInfo = reactive({
		isShowing: false,
		message: "",
	});

	async function completeDeleteOperation() {
		mainData.isSaving = true;

		return emitBeginSaving()
			.then(() => {
				return emitDeleting();
			})
			.then(async () => {
				if (!hasAnyErrors()) {
					await emitItemDeleted()
						.then(() => {
							showSnackbar(`The ${props.subjectName} was deleted successfully.`);
							mainData.ignoreIsDirty = true;
							stopEdit();
						});
				}
				mainData.isSaving = false;
				return emitEndSaving();
			});
	}
	async function completeEdit() {
		const promise = new Promise(resolve => {
			mainData.isSaving = true;

			return emitSaving()
				.then(async () => {
					const isSuccessful = !hasAnyErrors();
					if (isSuccessful) {
						return new Promise(async (innerResolve) => {
							await emitItemUpdated()
								.then(() => {
									showSnackbar(`The ${props.subjectName} was saved successfully.`);
									mainData.ignoreIsDirty = true;
									innerResolve(true);
								});
						});
					}
					else
						return Promise.resolve(false);
				})
				.then(isSuccessful => {
					mainData.isSaving = false;
					resolve(isSuccessful);
				});
		});
		return promise;
	}
	async function completeEditAndClose() {
		await completeEdit()
			.then(isSuccessful => {
				if (isSuccessful) {
					mainData.ignoreIsDirty = true;
					stopEdit();
				}
			});
	}
	async function completeEditAndNew() {
		await completeEdit()
			.then(async isSuccessful => {
				if (isSuccessful) {
					await emitResetData();
					await loadInitialData();
				}
			});
	}
	async function completeUndeleteOperation() {
		mainData.isSaving = true;

		return emitBeginSaving()
			.then(() => {
				return emitUndeleting();
			})
			.then(async () => {
				if (!hasAnyErrors()) {
					await emitItemUndeleted()
						.then(() => {
							showSnackbar(`The ${props.subjectName} was reactivated successfully.`);
							mainData.ignoreIsDirty = true;
							stopEdit();
						});
				}
				mainData.isSaving = false;
				return emitEndSaving();
			});
	}
	async function deleteItem() {
		promptUser(`Are you sure you want to delete this ${props.subjectName}?`, completeDeleteOperation);
	}
	async function emitAsPromise(emitName) {
		return new Promise((resolve) => {
			let isResolved = false;
			setTimeout(() => {
				if (!isResolved)
					resolve();
			}, 5000);
			emit(emitName, () => {
				isResolved = true;
				resolve();
			});
		});
	}
	async function emitBeginSaving() { return emitAsPromise("beginSaving"); }
	async function emitClosed() { return emitAsPromise("closed"); }
	async function emitDeleting() { return emitAsPromise("deleting"); }
	async function emitEndSaving() { return emitAsPromise("endSaving"); }
	async function emitItemDeleted() { return emitAsPromise("itemDeleted"); }
	async function emitItemUndeleted() { return emitAsPromise("itemUndeleted"); }
	async function emitItemUpdated() { return emitAsPromise("itemUpdated"); }
	async function emitLoadingItem() { return emitAsPromise("loadingItem"); }
	async function emitResetData() { return emitAsPromise("resetData"); }
	async function emitSaving() { return emitAsPromise("saving"); }
	async function emitUndeleting() { return emitAsPromise("undeleting"); }
	function hasAnyErrors() { return props.hasCriticalError || (props.validationErrors?.length || 0) !== 0; }
	async function loadInitialData() {
		mainData.isLoading = true;

		await emitLoadingItem()
			.then(() => {
				mainData.isLoading = false;
				mainData.hasData = !props.hasCriticalError;
			});
	}
	async function promptConfirmationAction() {
		promptDialog.isPromptingUser = false;
		await promptDialog.promptConfirmationAction();
	}
	function promptUser(text, confirmationAction) {
		promptDialog.userPrompt = text;
		promptDialog.promptConfirmationAction = confirmationAction;
		promptDialog.isPromptingUser = true;
	}
	function showSnackbar(message) {
		snackbarInfo.message = message;
		snackbarInfo.isShowing = true;
	}
	function stopEdit() {
		mainData.isShowing = false;
	}
	async function undeleteItem() {
		promptUser(`Are you sure you want to reactivate this ${props.subjectName}?`, completeUndeleteOperation);
	}

	watch(() => mainData.isEditing, () => {
		if (!mainData.isEditing)
			emitClosed();
	});
	watch(() => mainData.isShowing, () => {
		if (!mainData.isShowing && props.isDirty && !mainData.ignoreIsDirty) {
			promptUser("You have unsaved changes.  Are you sure you want to close and lose your unsaved changes?", () => { mainData.ignoreIsDirty = true; mainData.isShowing = false });
			mainData.isShowing = true;
		}
		mainData.isEditing = mainData.isShowing;
	});
	watch(() => props.shouldBeEditing, async () => {
		if (props.shouldBeEditing) {
			mainData.isEditing = true;
			mainData.isShowing = true;
			await loadInitialData();
		}
	});
</script>

<template>
	<div>
		<VDialog v-model="mainData.isShowing"
			class="base-dialog"
			:width="(props.isFullscreen ? undefined : (props.width || '80%'))"
			scrim="true"
			:transition="(props.isFullscreen ? 'dialog-bottom-transition' : undefined)"
			:fullscreen="props.isFullscreen">
			<VCard>
				<VToolbar color="primary">
					<VToolbarTitle><slot name="title"></slot></VToolbarTitle>
					<VSpacer />
					<VBtn v-if="props.isUndeleteAllowed"
						title="Reactivate"
						class="mx-2 reactivate-action"
						:disabled="mainData.isSaving || mainData.isLoading"
						@click="undeleteItem">
						<ReactivateIcon/>
					</VBtn>
					<VBtn v-if="props.isDeleteAllowed"
						title="Delete"
						color="danger"
						class="mx-2 delete-action"
						:disabled="mainData.isSaving || mainData.isLoading"
						@click="deleteItem">
						<DeleteIcon/>
					</VBtn>
					<VBtn v-if="props.isSaveAndNewAllowed"
						title="Save and New"
						class="mx-2 save-and-new-action"
						:disabled="!props.isDirty || mainData.isSaving || mainData.isLoading"
						@click="completeEditAndNew">
						<NewIcon/>
					</VBtn>
					<VBtn v-if="props.isSaveAllowed"
						title="Save and Close"
						class="mx-2 save-action"
						:disabled="!props.isDirty || mainData.isSaving || mainData.isLoading"
						@click="completeEditAndClose">
						<SaveIcon/>
					</VBtn>
					<VBtn title="Close"
						:disabled="mainData.isSaving || mainData.isLoading"
						@click="stopEdit">
						<CloseIcon/>
					</VBtn>
				</VToolbar>
				<VCardText>
					<VAlert v-if="props.hasCriticalError" color="error" class="mb-4 critical-error-message">Something went wrong.  Try again later.</VAlert>
					<VProgressLinear v-if="mainData.isLoading || mainData.isSaving" indeterminate color="primary loading-indicator" />
					<div v-if="mainData.hasData">
						<VAlert v-if="props.validationErrors?.length > 0"
							title="Problems"
							color="warning"
							type="warning"
							density="compact"
							class="mb-4">
							<ul class="validation-messages">
								<li v-for="error in props.validationErrors">{{ error }}</li>
							</ul>
						</VAlert>
						<div>
							<slot></slot>
						</div>
					</div>
				</VCardText>
			</VCard>
		</VDialog>

		<VSnackbar v-model="snackbarInfo.isShowing" timeout="4000" rounded="pill">
			<span class="dialog-message">{{ snackbarInfo.message }}</span>
			<template v-slot:actions>
				<VBtn color="primary" variant="text" @click="snackbarInfo.isShowing = false">Close</VBtn>
			</template>
		</VSnackbar>

		<VDialog v-model="promptDialog.isPromptingUser" width="auto" scrim="true" class="confirm-cancel-dialog">
			<VCard>
				<VCardText>{{ promptDialog.userPrompt }}</VCardText>
				<VCardActions>
					<VBtn color="primary" class="yes-action" @click="promptConfirmationAction">Yes</VBtn>
					<VBtn color="secondary" @click="promptDialog.isPromptingUser = false" class="cancel-action">Cancel</VBtn>
				</VCardActions>
			</VCard>
		</VDialog>
	</div>
</template>
