<script setup>
	import { computed, reactive, watch } from "vue";
	import { VAlert, VCheckbox, VCol, VDivider, VFadeTransition, VListItem, VListSubheader, VRow, VSelect, VTable, VTextField } from "vuetify/components";
	import { accountingService, executeServiceCall, getValidationErrorsFromResponse } from "@/services";
	import { formatAmount, hasArrayChanged, hasAnySimpleValueChanged, parseNumber } from "@/utils";
	//import { AddSecondaryIcon, RemoveSecondaryIcon, iconClassNames } from "@/components";
	import BaseEditDialog from "@/components/BaseEditDialog.vue";
	import CustomerPaymentIcon from "./CustomerPaymentIcon.vue";
	import { iconClassNames } from "@/components";

	const props = defineProps({
		customerId: { type: String, required: false },
		customerPaymentId: { type: String, required: false },
		selectedInvoiceId: { type: String, required: false },
		shouldBeEditing: { type: Boolean, required: true },
	});
	const emit = defineEmits([ "closed", "customerPaymentDeleted", "customerPaymentUpdated" ]);

	const mainData = reactive({
		hasCriticalError: false,
		customerPayment: null,
		isDirty: false,
		isLoading: false,
		isSaving: false,
		latestSnapshot: null,
		originalSnapshot: null,
		referenceData: null,
		validationErrors: [],
	});
	const rules = {
		required: value => !!value || "This value is required.",
	};
	const unappliedBalance = computed(() => {
		if (!mainData.customerPayment)
			return 0;
		var balance = mainData.customerPayment.amount;
		mainData.customerPayment.invoiceAllocations.forEach(invoice => balance -= invoice.amount);
		balance -= mainData.customerPayment.prepaymentCreditAmount;
		const value = Number(Number(balance).toFixed(2));
		return value;
	});

	function buildPrepaymentAccountsByAccountType() {
		const accountTypes = [ "Asset", "Liability", "Equity", "Income", "Cost of Goods", "Expense" ];
		let accountGroupings = [];

		accountTypes.forEach((accountType, i) => {
			const accounts = mainData.referenceData.prepaymentCreditAccounts.filter(account => account.accountType === accountType)
			if (accounts.length > 0) {
				accountGroupings.push({ props: { header: accountType } });
				accounts.forEach(account => accountGroupings.push({ title: `${account.number}: ${account.name}`, value: account.id }));

				if (i < accountTypes.length - 1)
					accountGroupings.push({ props: { divider: true } });
			}
		});

		if (accountGroupings[accountGroupings.length - 1].props?.divider === true)
			accountGroupings.splice(accountGroupings.length - 1, 1);

		mainData.referenceData.prepaymentAccountsByAccountType = accountGroupings;
	}
	async function deleteCustomerPayment() {
		resetErrors();
		if (!mainData.customerPayment.id)
			return;
		await executeServiceCall(() => accountingService.customerPayment.delete(mainData.customerPayment.id))
			.catch(() => mainData.hasCriticalError = true);
	}
	function emitClosed() { emit("closed"); }
	function emitCustomerPaymentDeleted() { emit("customerPaymentDeleted"); }
	function emitCustomerPaymentUpdated() { emit("customerPaymentUpdated"); }
	function generateLatestSnapshot() { mainData.latestSnapshot = JSON.parse(JSON.stringify(mainData.customerPayment)); }
	async function loadCustomerPayment() {
		mainData.isLoading = true;
		resetErrors();
		resetData();
		const referenceDataCall = props.customerPaymentId
			? () => accountingService.customerPayment.referenceDataForCustomerPayment(props.customerPaymentId)
			: ( props.customerId
				? () => accountingService.customerPayment.referenceDataForCustomer(props.customerId)
				: () => accountingService.customerPayment.referenceDataForInvoice(props.selectedInvoiceId)
			);
		await executeServiceCall(referenceDataCall)
			.then(({ data }) => {
				mainData.referenceData = data;
				buildPrepaymentAccountsByAccountType();
			})
			.catch(() => mainData.hasCriticalError = true);
		if (mainData.hasCriticalError) {
			mainData.isLoading = false;
			return;
		}

		if (!props.customerPaymentId) {
			const today = new Date(Date.now());
			mainData.customerPayment = {
				partyId: mainData.referenceData.partyId,
				date: today.getFullYear() + "-" + ("00" + (today.getMonth()+1)).slice(-2) + "-" + ("00" + today.getDate()).slice(-2),
				amount: 0.0,
				depositAccountId: null,
				reference: null,
				memo: null,
				invoiceAllocations: mainData.referenceData.invoices.map(invoice => { return { isSelected: false, invoiceId: invoice.id, amount: 0.0, invoice: invoice }; }),
				prepaymentCreditAccountId: null,
				prepaymentCreditAmount: 0.0,
			};
		}
		else {
			await executeServiceCall(() => accountingService.customerPayment.byId(props.customerPaymentId))
				.then(({ data }) => mainData.customerPayment = data)
				.catch(() => mainData.hasCriticalError = true);
		}

		if (!mainData.hasCriticalError) {
			mergeInvoiceAllocationsWithReferenceInvoices();
			mainData.originalSnapshot = JSON.parse(JSON.stringify(mainData.customerPayment));
			mainData.isDirty = false;
		}
		mainData.isLoading = false;
	}
	function mergeInvoiceAllocationsWithReferenceInvoices() {
		const invoiceAllocations = mainData.customerPayment.invoiceAllocations;
		const invoices = mainData.referenceData.invoices;
		invoices.forEach(invoice => {
			const allocation = invoiceAllocations.find(allocation => allocation.invoiceId === invoice.id);
			if (allocation) {
				allocation.invoice = invoice;
				allocation.isSelected = allocation.amount > 0;
			}
			else {
				invoiceAllocations.push({ isSelected: false, invoiceId: invoice.id, amount: 0.0, invoice: invoice });
			}
		});
		const allocationsWithoutInvoices = invoiceAllocations.filter(allocation => !allocation.invoice);
		if (allocationsWithoutInvoices.length > 0)
			console.error("Allocations is missing ReferenceData Invoice: ", allocationsWithoutInvoices);
	}
	function onBaseBeginSaving(done) { mainData.isSaving = true; done(); }
	function onBaseClosed(done) {
		resetErrors();
		resetData();
		emitClosed();
		done();
	}
	async function onBaseDeletingItem(done) { await deleteCustomerPayment(); done(); }
	function onBaseEndSaving(done) { mainData.isSaving = false; done(); }
	function onBaseItemDeleted(done) { emitCustomerPaymentDeleted(); done(); }
	function onBaseItemUpdated(done) { emitCustomerPaymentUpdated(); done(); }
	async function onBaseLoadingItem(done) { await loadCustomerPayment(); done(); }
	async function onBaseResetData(done) {
		resetErrors();
		resetData();
		done();
	}
	async function onBaseSaving(done) { await saveCustomerPayment().then(() => done()); }
	function onCurrentAmountChanged() {
		if (!mainData.customerPayment || mainData.customerPayment.invoiceAllocations.filter(allocation => allocation.isSelected).length > 0)
			return;
		var remainingAmount = parseNumber(mainData.customerPayment.amount);
		for (let i = 0, allocation; (allocation = mainData.customerPayment.invoiceAllocations[i]) && remainingAmount > 0; i++) {
			allocation.isSelected = true;
			const invoiceBalance = allocation.invoice.unpaidBalance;
			allocation.amount = Number(Math.min(remainingAmount, invoiceBalance).toFixed(2));
			remainingAmount = Number(Number(remainingAmount - allocation.amount).toFixed(2));
		}
	}
	function onInvoiceAllocationAmountChanged(i) {
		const allocation = mainData.customerPayment.invoiceAllocations[i];
		const shouldBeSelected = allocation.amount !== 0;
		if (allocation.isSelected != shouldBeSelected)
			allocation.isSelected = shouldBeSelected;
	}
	function onInvoiceAllocationSelectedChanged(i) {
		const allocation = mainData.customerPayment.invoiceAllocations[i];
		if (!allocation.isSelected) {
			allocation.amount = 0;
			return;
		}

		let remainingBalance = unappliedBalance.value;
		remainingBalance += allocation.amount;
		if (remainingBalance > 0) {
			const amountToApply = Math.min(remainingBalance, allocation.invoice.unpaidBalance);
			allocation.amount = amountToApply;
		}
	}
	function resetData() {
		mainData.customerPayment = null;
		mainData.originalSnapshot = null;
		mainData.isDirty = false;
		mainData.referenceData = null;
	}
	function resetErrors() {
		mainData.hasCriticalError = false;
		mainData.validationErrors = [];
	}
	async function saveCustomerPayment() {
		await executeServiceCall(() => accountingService.customerPayment.save(mainData.customerPayment))
			.catch(({ response }) => {
				if (response?.status === 400)
					mainData.validationErrors = getValidationErrorsFromResponse(response);
				else
					mainData.hasCriticalError = true;
			});
	}
	function updateIsDirty() {
		const { customerPayment, originalSnapshot } = mainData;
		if (!customerPayment || !originalSnapshot)
			return;

		const isDirty = hasAnySimpleValueChanged(customerPayment, originalSnapshot, ["date","reference","depositAcountId","amount","memo"])
			|| hasArrayChanged(customerPayment.invoiceAllocations, originalSnapshot.invoiceAllocations, ["isSelected","amount"]);
		mainData.isDirty = isDirty;
	}

	watch(() => ({...mainData.customerPayment}), (current, previous) => {
		if (mainData.isLoading)
			return;
		updateIsDirty();
		if (current.amount !== previous.amount)
			onCurrentAmountChanged();
		if (mainData.customerPayment?.invoiceAllocations && mainData.latestSnapshot?.invoiceAllocations) {
			for (let i = 0, currentAllocation; currentAllocation = mainData.customerPayment.invoiceAllocations[i]; i++) {
				const previousAllocation = mainData.latestSnapshot.invoiceAllocations[i];
				if (currentAllocation.amount !== previousAllocation.amount)
					onInvoiceAllocationAmountChanged(i);
			}
		}
		generateLatestSnapshot(current);
	}, { deep: true });
</script>

<template>
	<div>
		<BaseEditDialog subjectName="Customer Payment"
			:shouldBeEditing="props.shouldBeEditing"
			:isFullscreen="true"
			:isDeleteAllowed="!!props.customerPaymentId"
			:isDirty="mainData.isDirty"
			:hasCriticalError="mainData.hasCriticalError"
			:validationErrors="mainData.validationErrors"
			@beginSaving="onBaseBeginSaving"
			@endSaving="onBaseEndSaving"
			@deletingItem="onBaseDeletingItem"
			@loadingItem="onBaseLoadingItem"
			@saving="onBaseSaving"
			@resetData="onBaseResetData"
			@itemDeleted="onBaseItemDeleted"
			@itemUpdated="onBaseItemUpdated"
			@closed="onBaseClosed">
			<template v-slot:title><CustomerPaymentIcon/> Customer Payment</template>
			<div v-if="mainData.customerPayment">
				<v-row>
					<v-col class="v-col-3">
						<v-text-field v-model="mainData.customerPayment.date"
							type="date"
							label="Payment Date"
							:rules="[rules.required]"
							:disabled="mainData.isSaving"
							autofocus />
					</v-col>
					<v-col class="v-col-3">
						<v-text-field v-model="mainData.customerPayment.reference"
							label="Reference"
							:counter="20"
							:disabled="mainData.isSaving" />
					</v-col>
					<v-col class="v-col-6"> Payment from <strong>{{ mainData.referenceData.partyName }}</strong></v-col>
				</v-row>
				<v-row>
					<v-col class="v-col-3">
						<v-select v-model="mainData.customerPayment.depositAccountId"
							label="Deposit Account"
							:items="mainData.referenceData.depositAccounts"
							item-value="id"
							item-title="name"
							:rules="[rules.required]"
							:disabled="mainData.isSaving" />
					</v-col>
					<v-col class="v-col-3">
						<v-text-field :modelValue="formatAmount(mainData.customerPayment.amount)"
							label="Deposit Amount"
							:rules="[rules.required]"
							:disabled="mainData.isSaving"
							@update:modelValue="value => mainData.customerPayment.pendingAmount = value"
							@update:focused="isFocused => { if (!isFocused) mainData.customerPayment.amount = parseNumber(mainData.customerPayment.pendingAmount); }" />
					</v-col>
					<v-col class="v-col-6">
						<v-text-field v-model="mainData.customerPayment.memo"
							label="Memo"
							hideDetails
							:disabled="mainData.isSaving" />
					</v-col>
				</v-row>
				<v-row>
					<v-col class="v-col-10 offset-1">
						<h5>Invoice Selection</h5>
						<v-table density="compact">
							<thead>
								<tr>
									<th></th>
									<th>Date</th>
									<th>Number</th>
									<th>Total</th>
									<th>Remaining</th>
									<th>Amount to Apply</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(invoiceAllocation, i) in mainData.customerPayment.invoiceAllocations" :key="invoiceAllocation.invoiceId">
									<td>
										<v-checkbox :modelValue="invoiceAllocation.isSelected"
											style="margin-top: 25%;"
											:disabled="mainData.isSaving"
											@update:modelValue="isChecked => { invoiceAllocation.isSelected = isChecked; onInvoiceAllocationSelectedChanged(i); }" />
									</td>
									<td>{{ invoiceAllocation.invoice?.date }}</td>
									<td>{{ invoiceAllocation.invoice?.number }}</td>
									<td>{{ formatAmount(invoiceAllocation.invoice?.total) }}</td>
									<td>{{ formatAmount(invoiceAllocation.invoice?.unpaidBalance) }}</td>
									<td>
										<v-text-field :model-value="formatAmount(invoiceAllocation.amount)"
											hideDetails
											:prependIcon="invoiceAllocation.amount === invoiceAllocation.invoice?.unpaidBalance ? iconClassNames.paidInFull : undefined"
											:disabled="mainData.isSaving"
											@update:modelValue="value => invoiceAllocation.pendingAmount = value"
											@update:focused="isFocused => { if (!isFocused) invoiceAllocation.amount = parseNumber(invoiceAllocation.pendingAmount); }" />
									</td>
								</tr>
							</tbody>
						</v-table>
					</v-col>
				</v-row>
				<v-row><v-col class="v-col-10 offset-1"><h5>Unapplied Amounts</h5></v-col></v-row>
				<v-row>
					<v-col class="v-col-3 offset-1">
						<v-text-field :modelValue="formatAmount(mainData.customerPayment.prepaymentCreditAmount)"
							label="Prepayment Credit Amount"
							:disabled="mainData.isSaving"
							@update:modelValue="value => mainData.customerPayment.pendingPrepaymentCreditAmount = value"
							@update:focused="isFocused => { if (!isFocused) mainData.customerPayment.prepaymentCreditAmount = parseNumber(mainData.customerPayment.pendingPrepaymentCreditAmount); }" />
					</v-col>
					<v-col class="v-col-3">
						<v-select v-model="mainData.customerPayment.prepaymentCreditAccountId"
							label="Prepayment Credit Account"
							:items="mainData.referenceData.prepaymentAccountsByAccountType"
							:disabled="mainData.isSaving">
							<template #item="data">
								<v-list-subheader v-if="data.props.header">{{ data.props.header }}</v-list-subheader>
								<v-divider v-else-if="data.props.divider"></v-divider>
								<v-list-item v-else v-bind="data.props"></v-list-item>
							</template>
						</v-select>
					</v-col>
					<v-col class="v-col-4">
						<v-fade-transition>
							<v-alert v-if="unappliedBalance !== 0" color="error" icon="$warning">Remaining unapplied amount: {{ formatAmount(unappliedBalance) }}</v-alert>
						</v-fade-transition>
					</v-col>
				</v-row>
			</div>
		</BaseEditDialog>
	</div>
</template>

<style scoped>
	.v-table table tbody tr td {
		padding-left: 0;
		padding-right: 0;
	}
	.v-table table tbody tr td {
		height: 40px;
		border: none;
	}
	.v-table .v-table__wrapper > table > tbody > tr:not(:last-child) > td {
		border-bottom: none;
	}
	.text-sm :deep(input), .text-sm :deep(.v-autocomplete__selection-text) {
		font-size: 0.8em;
	}
</style>
