<script setup>
	import { onMounted, reactive } from "vue";
	import { BIconCalendarWeek, BIconKeyboard, BIconPatchCheck, BIconPeople, BIconPersonUp, BIconPersonPlus, BIconPersonWorkspace, BIconPhone, BIconPiggyBank } from "bootstrap-icons-vue";
	import promoVideoUrl from "@/assets/video/promo.mp4";
	import { executeServiceCall, selfServiceService } from "@/services";

	const data = reactive({
		requestMoreInfo: {
			contactInfo: {
				emailAddress: "",
				name: "",
				text: "",
			},
			isError: false,
			isShowing: false,
			isSuccess: false,
			topic: "",
		},
		showAd: false,
	});

	function requestMoreInfo(topic) {
		data.requestMoreInfo.topic = topic;
		data.requestMoreInfo.isShowing = true;
	}
	async function submitRequestForMoreInfo() {
		data.requestMoreInfo.isShowing = false;

		data.requestMoreInfo.contactInfo.phoneNumber = "n/a";
		data.requestMoreInfo.contactInfo.recaptchaResponse = "skip";

		await executeServiceCall(() => selfServiceService.public.submitContactRequest(data.requestMoreInfo.contactInfo))
			.then(() => data.requestMoreInfo.isSuccess = true)
			.catch(error => {
				console.error(error);
				data.requestMoreInfo.isError = true;
			});
	}

	onMounted(() => {
		setTimeout(() => data.showAd = true, 1750);
	});
</script>

<template>
	<div>
		<!-- Masthead-->
		<header class="masthead">
			<div class="container position-relative">
				<div class="row justify-content-center">
					<div class="text-center mt-4 mb-4">
						<h1 class="text-h3 fw-bold">Your Tech Training Partner for Career Growth</h1>
						<h2 class="text-h4 mt-2"><a style="text-decoration: none;" href="tel: 817-704-0300">817-704-0300</a></h2>
						<div>
							<video controls autoplay loop muted style="width: 60%; height: 60%;">
								<source :src="promoVideoUrl" type="video/mp4"/>
							</video>
						</div>
					</div>
				</div>
			</div>
		</header>

		<!-- Icons Grid-->
		<a name="students"></a>
		<section class="features-icons bg-light text-center">
			<div class="container">
				<h2>For Students</h2>
				<div class="row">
					<div class="col-lg-4">
						<div class="features-icons-item mx-auto mb-5 mb-lg-3">
							<div class="features-icons-icon d-flex"><BIconKeyboard class="m-auto text-primary"/></div>
							<h4>Software Development Classes</h4>
							<p class="lead mb-0">Learn how computer programmers become professional software developers.  With focus on Agility, DevOps, Test-Driven Development, Pair Programming and many more elite practices, jump start your career.</p>
							<div class="mt-2">
								<VBtn color="primary" @click="requestMoreInfo('Software Development Classes')">More Info</VBtn>
							</div>
						</div>
					</div>
					<div class="col-lg-4">
						<div class="features-icons-item mx-auto mb-5 mb-lg-3">
							<div class="features-icons-icon d-flex"><BIconPersonWorkspace class="m-auto text-primary"/></div>
							<h4>Apprenticeship Programs</h4>
							<p class="lead mb-0">Paid Apprenticeships combine the Software Development Classes with on-the-job training that can launch you into a career of software development.</p>
							<div class="mt-2">
								<VBtn color="primary" @click="requestMoreInfo('Apprenticeship Programs')">More Info</VBtn>
							</div>
						</div>
					</div>
					<div class="col-lg-4">
						<div class="features-icons-item mx-auto mb-5 mb-lg-3">
							<div class="features-icons-icon d-flex"><BIconPeople class="m-auto text-primary"/></div>
							<h4>Programming Classes for Kids</h4>
							<p class="lead mb-0">These classes help your child build a solid foundation in topics important for computer science and computer programming.  While also fun and engaging, the curriculum provides a structured approach to real-world concepts.</p>
							<div class="mt-2">
								<VBtn color="primary" @click="requestMoreInfo('Programming Classes for Kids')">More Info</VBtn>
							</div>
						</div>
					</div>
				</div>
				<div class="row justify-content-center">
					<div class="col-lg-4">
						<div class="features-icons-item mx-auto mb-5 mb-lg-3">
							<div class="features-icons-icon d-flex"><BIconPhone class="m-auto text-primary"/></div>
							<h4>Computer Classes for Older Adults</h4>
							<p class="lead mb-0">Learn the basic fundamentals for how to use computers, including your phone.  Rely on friendly and patient instruction to help you grasp the basics.</p>
							<div class="mt-2">
								<VBtn color="primary" @click="requestMoreInfo('Computer Classes for Older Adults')">More Info</VBtn>
							</div>
						</div>
					</div>
					<div class="col-lg-4">
						<div class="features-icons-item mx-auto mb-5 mb-lg-3">
							<div class="features-icons-icon d-flex"><BIconPiggyBank class="m-auto text-primary"/></div>
							<h4>Financial Literacy Programs</h4>
							<p class="lead mb-0">We believe everyone deserves an education in financial literacy.  Whether it's the basics of investing or getting out of debt, this program can equip you to build your own financial plan.</p>
							<div class="mt-2">
								<VBtn color="primary" @click="requestMoreInfo('Financial Literacy Programs')">More Info</VBtn>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- Image Showcases-->
		<a name="companies"></a>
		<section class="features-icons text-center">
			<div class="container">
				<h2>For Companies</h2>
				<div class="row justify-content-center">
					<div class="col-lg-5">
						<div class="features-icons-item mx-auto mb-5 mb-lg-3">
							<div class="features-icons-icon d-flex"><BIconPersonPlus class="m-auto text-primary"/></div>
							<h4>Low-Cost Development Contracting</h4>
							<p class="lead mb-0">Get your IT projects done using our Apprentices with an hourly rate as low as $45.  Also, convert an Apprentice to your full-time employee with no hiring or conversion costs.</p>
							<div class="mt-2">
								<VBtn color="primary" @click="requestMoreInfo('Low-Cost Development Contracting')">More Info</VBtn>
							</div>
						</div>
					</div>
					<div class="col-lg-5">
						<div class="features-icons-item mx-auto mb-5 mb-lg-3">
							<div class="features-icons-icon d-flex"><BIconPatchCheck class="m-auto text-primary"/></div>
							<h4>Expert Consulting</h4>
							<p class="lead mb-0">Leverage Chad's extensive experience for Personnel evaluation, Employee growth planning, Architectural evaluations &amp; recommendations, Technology roadmapping, Leadership &amp; transformation planning, or Project rescue.</p>
							<div class="mt-2">
								<VBtn color="primary" @click="requestMoreInfo('Expert Consulting')">More Info</VBtn>
							</div>
						</div>
					</div>
				</div>
				<div class="row justify-content-center">
					<div class="col-lg-5">
						<div class="features-icons-item mx-auto mb-5 mb-lg-3">
							<div class="features-icons-icon d-flex"><BIconPersonUp class="m-auto text-primary"/></div>
							<h4>Employee Mentoring &amp; Growth</h4>
							<p class="lead mb-0">Outsource your development staff's personal and career growth to an expert in the industry.  This includes one-on-one time, mentoring sessions, and career guidance &amp; planning for each employee.</p>
							<div class="mt-2">
								<VBtn color="primary" @click="requestMoreInfo('Employee Mentoring & Growth')">More Info</VBtn>
							</div>
						</div>
					</div>
					<div class="col-lg-5">
						<div class="features-icons-item mx-auto mb-5 mb-lg-3">
							<div class="features-icons-icon d-flex"><BIconCalendarWeek class="m-auto text-primary"/></div>
							<h4>Boot Camps</h4>
							<p class="lead mb-0">A condensed version of our Software development classes, these courses take your development staff through an accelerated program to instill quality practices quickly.</p>
							<div class="mt-2">
								<VBtn color="primary" @click="requestMoreInfo('Boot Camps')">More Info</VBtn>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="bg-light border-bottom">
			<div class="container px-5 py-5">
				<div class="text-center mb-5">
					<h2 class="fw-bolder">Instructor / Consulting testimonials</h2>
					<p class="lead mb-0">What Others Say about Chad Greer</p>
				</div>
				<div class="row gx-5 justify-content-center">
					<div class="col-lg-6">
						<div class="card mb-4">
							<div class="card-body p-4">
								<div class="d-flex">
									<div class="flex-shrink-0"><i class="mdi mdi-comment-quote text-primary fs-1"></i></div>
									<div class="ms-4">
										<p class="mb-1">Chad is a phenomenal mentor and teacher. This is an area he clearly puts emphasis on. In my time working with him, this focus had an undeniable impact on my professional growth.</p>
										<div class="small text-muted">- Geoff A</div>
									</div>
								</div>
							</div>
						</div>
						<div class="card mb-4">
							<div class="card-body p-4">
								<div class="d-flex">
									<div class="flex-shrink-0"><i class="mdi mdi-comment-quote text-primary fs-1"></i></div>
									<div class="ms-4">
										<p class="mb-1">Chad is very talented when it comes to technical matters, and also knows how to train and mentor individuals to help them reach their maximum potential as well.</p>
										<div class="small text-muted">- Joshua L</div>
									</div>
								</div>
							</div>
						</div>
						<div class="card mb-4">
							<div class="card-body p-4">
								<div class="d-flex">
									<div class="flex-shrink-0"><i class="mdi mdi-comment-quote text-primary fs-1"></i></div>
									<div class="ms-4">
										<p class="mb-1">Chad is one of the most energetic, passionate people I've worked with. Chad's great sense of pride and ownership in his work enables him to proactively learn new technologies and ways to solve problems.</p>
										<div class="small text-muted">- Clay A</div>
									</div>
								</div>
							</div>
						</div>
						<div class="card mb-4">
							<div class="card-body p-4">
								<div class="d-flex">
									<div class="flex-shrink-0"><i class="mdi mdi-comment-quote text-primary fs-1"></i></div>
									<div class="ms-4">
										<p class="mb-1">Chad is a leader whose enthusiasm and demand for excellence inspire the same out of others. He is also a great communicator that can effectively express his thoughts on subjects that are typically difficult to understand.</p>
										<div class="small text-muted">- Josh S</div>
									</div>
								</div>
							</div>
						</div>
						<div class="card mb-4">
							<div class="card-body p-4">
								<div class="d-flex">
									<div class="flex-shrink-0"><i class="mdi mdi-comment-quote text-primary fs-1"></i></div>
									<div class="ms-4">
										<p class="mb-1">Chad was a great person to work with and a real guru of software development. He always drove me to be the best developer I could be.</p>
										<div class="small text-muted">- Justin E</div>
									</div>
								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-body p-4">
								<div class="d-flex">
									<div class="flex-shrink-0"><i class="mdi mdi-comment-quote text-primary fs-1"></i></div>
									<div class="ms-4">
										<p class="mb-1">Chad is without a doubt one of the most innovative software architects I've known and a very powerful technical leader. He has a talent for inspiring passion in others.</p>
										<div class="small text-muted">- Chad S</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>

	<VDialog v-model="data.requestMoreInfo.isShowing">
		<VCard :title="data.requestMoreInfo.topic">
			<VCardText>
				<div>
					<VTextField v-model="data.requestMoreInfo.contactInfo.name" label="Your name" />
					<VTextField v-model="data.requestMoreInfo.contactInfo.emailAddress" label="Phone # or Email" />
					<VTextarea v-model="data.requestMoreInfo.contactInfo.text" label="What would you like to know?" />
				</div>
			</VCardText>
			<VCardActions>
				<VSpacer />
				<VBtn text="Done" @click="submitRequestForMoreInfo" />
				<VBtn text="Cancel" @click="data.requestMoreInfo.isShowing = false" />
			</VCardActions>
		</VCard>
	</VDialog>

	<VSnackbar v-model="data.requestMoreInfo.isSuccess" timeout="4000" rounded="pill" color="primary">
		<span class="dialog-message">Your request was sent.</span>
		<template v-slot:actions>
			<VBtn color="white" variant="text" @click="data.requestMoreInfo.isSuccess = false">Close</VBtn>
		</template>
	</VSnackbar>

	<VSnackbar v-model="data.requestMoreInfo.isError" timeout="4000" rounded="pill" color="danger">
		<span class="dialog-message">Something went wrong.  Try again later, or give us a call at <a href="tel: 817-704-0300">817-704-0300</a>.</span>
		<template v-slot:actions>
			<VBtn color="white" variant="text" @click="data.requestMoreInfo.isError = false">Close</VBtn>
		</template>
	</VSnackbar>
</template>
