<script>
import { VAlert, VBtn, VCol, VProgressLinear, VRow, VSpacer, VTable } from "vuetify/components";
import { BIconPlusSquare } from "bootstrap-icons-vue";
import { accountingService } from "@/services";
import { useRoute } from 'vue-router';
import EditTransactionDialog from "../components/EditTransactionDialog.vue";

export default {
	name: "AccountLedgerView",

	components: {
		VAlert, VBtn, VCol, VProgressLinear, VRow, VSpacer, VTable,
		BIconPlusSquare,
		EditTransactionDialog,
	},

	async created() {
		const route = useRoute();
		this.accountId = route.params.accountId;
		await this.loadJournalEntriesView();
	},

	data: () => ({
		accountId: null,
		editingInfo: {
			transactionId: null,
			isEditing: false,
		},
		journalEntriesView: {
			transactions: [],
			hasError: false,
			isLoading: false,
		},
		wasTransactionModified: false,
	}),

	methods: {
		addTransaction() {
			this.editingInfo.isEditing = false;
			this.editingInfo.transactionId = null;
			this.editingInfo.isEditing = true;
		},
		async closeEditTransactionDialog() {
			this.editingInfo.isEditing = false;
			if (this.wasTransactionModified) {
				await this.$nextTick(async () => {
					await this.loadJournalEntriesView();
					this.wasTransactionModified = false;
				});
			}
		},
		calculateRunningBalances() {
			let balance = 0.0;
			for (let i = 0; i < this.journalEntriesView.transactions.length; i++) {
				let transactionAmount = this.journalEntriesView.transactions[i].debitAmount - this.journalEntriesView.transactions[i].creditAmount;
				if (!this.journalEntriesView.account.isDebitPositive)
					transactionAmount = -transactionAmount;
				balance += transactionAmount;
				this.journalEntriesView.transactions[i].runningBalance = balance.toFixed(2);
			}
		},
		async editTransaction(transactionId) {
			if (!transactionId)
				return;

			this.editingInfo.isEditing = false;
			this.editingInfo.transactionId = transactionId;
			this.editingInfo.isEditing = true;
		},
		formatAmount(value) {
			if (value == 0)
				return "";
			return new Intl.NumberFormat("en-US", {
				maximumFractionDigits: 2,
				minimumFractionDigits: 2
			}).format(value);
		},
		getEndingBalance() {
			if (this.journalEntriesView.transactions.length === 0)
				return 0.0;
			return this.journalEntriesView.transactions[this.journalEntriesView.transactions.length - 1].runningBalance;
		},
		async handleError(error) {
			console.error(error);
			if (error.response && error.response.status === 401) {
				let isRecoverable = false;
				await authenticationService.renewToken()
					.then(async isRenewed => {
						if (!isRenewed)
							await authenticationService.signIn(() => isRecoverable = true);
					});
				return new Promise(r => r(isRecoverable));
			}
			return new Promise(r => r(false));
		},
		async loadJournalEntriesView() {
			await this.$nextTick();

			let isRetrying = false;
			do {
				this.journalEntriesView.isLoading = true;
				this.journalEntriesView.hasError = false;

				await accountingService.transaction.ledger(this.accountId)
					.then(({ data }) => {
						this.journalEntriesView.account = data.account;
						this.journalEntriesView.transactions = data.transactions;
						this.calculateRunningBalances();
						this.journalEntriesView.isLoading = false;
					})
					.catch(async error => {
						await this.handleError(error)
							.then(isRecoverable => {
								if (isRecoverable) {
									isRetrying = true;
									return;
								}
								else {
									this.journalEntriesView.hasError = true;
									this.journalEntriesView.isLoading = false;
								}
							});
					});
			}
			while (isRetrying);
		},
	},
}
</script>

<template>
	<div class="container my-4">
		<v-row>
			<v-col class="v-col-auto">
				<h1 class="col-auto">{{ this.journalEntriesView.account?.name || "Account" }} Ledger</h1>
			</v-col>
			<v-spacer></v-spacer>
			<v-col class="v-col-auto text-right">
				<v-btn color="primary" variant="flat" size="large" class="ma-2 create-transaction-action" :disabled="this.journalEntriesView.isLoading" @click="addTransaction()"><BIconPlusSquare class="mr-2"/>Add Transaction</v-btn>
			</v-col>
		</v-row>

		<template v-if="this.journalEntriesView.hasError">
			<v-alert color="error" class="mb-4">Something went wrong.  Try again later.</v-alert>
		</template>
		<template v-if="this.journalEntriesView.isLoading">
			<v-progress-linear indeterminate color="primary"></v-progress-linear>
		</template>
		<template :class="this.journalEntriesView.isLoading ? 'disabled row' : 'row'">
			<v-table class="table-striped" density="compact">
				<thead>
					<th>Ref</th>
					<th>Date</th>
					<th>To/From</th>
					<th>Debit</th>
					<th>Credit</th>
					<th>Balance</th>
				</thead>
				<tbody>
					<tr v-for="transaction in this.journalEntriesView.transactions" :class="transaction.id ? 'clickable transaction' : 'transaction'" @click="editTransaction(transaction.id)" :data-id="transaction.id">
						<td>{{ transaction.reference }}</td>
						<td>{{ transaction.displayDate }}</td>
						<td>{{ transaction.partyName }}</td>
						<td class="text-right">{{ this.formatAmount(transaction.debitAmount) }}</td>
						<td class="text-right">{{ this.formatAmount(transaction.creditAmount) }}</td>
						<td class="text-right">{{ this.formatAmount(transaction.runningBalance) }}</td>
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<th></th>
						<th></th>
						<th></th>
						<th></th>
						<th></th>
						<th class="text-right">${{ this.formatAmount(this.getEndingBalance()) }}</th>
					</tr>
				</tfoot>
			</v-table>
		</template>

		<EditTransactionDialog :transactionId="this.editingInfo.transactionId"
			:shouldBeEditing="this.editingInfo.isEditing"
			@closed="closeEditTransactionDialog"
			@transactionUpdated="this.wasTransactionModified = true" />
	</div>
</template>

<style scoped>
	.v-theme--light.v-table.table-striped tbody tr.clickable:hover {
		background-color: rgba(0, 0, 0, 0.1);
		cursor: pointer;
	}
</style>
