<script setup>
	import { onMounted, reactive, watch } from 'vue';
	import { VAlert, VBtn, VCard, VCardActions, VCardText, VCol, VDialog, VProgressLinear, VRow, VSelect } from "vuetify/components";
	import { accountingService, executeServiceCall } from '@/services';
	import { CloseIcon, SaveIcon } from '@/components';
	import { hasAnySimpleValueChanged } from '@/utils';

	const mainData = reactive({
		hasError: false,
		isDirty: false,
		isProcessing: false,
		isShowingSuccessMessage: false,
		originalSnapshot: null,
		referenceData: null,
		settings: null,
	});
	const promptDialog = reactive({
		isPromptingUser: false,
		promptConfirmationAction: null,
		userPrompt: "",
	});

	function formatTaxRate(value) {
		const numberValue = typeof value === 'number' ? value : Number(value);
		if (numberValue === NaN)
			numberValue = null;

		if (!numberValue)
			return null;

		return Number(numberValue.toFixed(2));
	}
	async function loadSettings() {
		mainData.hasError = false;
		mainData.isProcessing = true;

		await executeServiceCall(accountingService.settings.currentPayroll)
			.then(({ data }) => {
				mainData.referenceData = data.referenceData;
				mainData.settings = data.settings;
				mainData.originalSnapshot = JSON.parse(JSON.stringify(data.settings));
				mainData.isDirty = false;
			})
			.catch(error => { console.error(error); mainData.hasError = true; })
			.finally(() => mainData.isProcessing = false);
	}
	function revertSettings() {
		promptDialog.userPrompt = "You will lose any changes you've made.  Are you sure you want to revert these changes?";
		promptDialog.promptConfirmationAction = revertSettingsConfirmed;
		promptDialog.isPromptingUser = true;
	}
	function revertSettingsConfirmed() {
		mainData.hasError = false;
		mainData.isProcessing = true;

		mainData.isDirty = false;
		mainData.settings = JSON.parse(JSON.stringify(mainData.originalSnapshot));

		mainData.isProcessing = false;
		promptDialog.isPromptingUser = false;
	}
	async function saveSettings() {
		mainData.hasError = false;
		mainData.isProcessing = true;

		await executeServiceCall(() => accountingService.settings.savePayroll(mainData.settings))
			.then(() => showSuccessMessage())
			.catch(() => mainData.hasError = true)
			.finally(() => mainData.isProcessing = false);
	}
	function showSuccessMessage() {
		mainData.isShowingSuccessMessage = true;
	}
	function updateIsDirty() {
		if (!mainData.settings || ! mainData.originalSnapshot)
			return;
		mainData.isDirty = hasAnySimpleValueChanged(mainData.settings, mainData.originalSnapshot, accountingService.settings.getSimplePayrollSettingsFields());
	}

	onMounted(async () => {
		await loadSettings();
	});

	watch(() => mainData.settings, () => { updateIsDirty(); }, { deep: true });
</script>

<template>
	<div>
		<VProgressLinear v-if="mainData.isProcessing" color="primary" class="payroll-settings-loading-indicator" indeterminate />
		<VAlert v-if="mainData.hasError" color="error" class="mb-4 payroll-settings-critical-error-message">Something went wrong.  Try again later.</VAlert>
		<div v-if="mainData.settings">
			<VRow>
				<VCol class="v-col-12 v-col-md-6">
					<VSelect v-model="mainData.settings.payrollPayableAccountId"
						id="payrollSettingsPayrollPayableAccount"
						label="Payroll Payable Account"
						:items="mainData.referenceData.liabilityAccounts"
						itemValue="id"
						itemTitle="name"
						:disabled="mainData.isProcessing" />
				</VCol>
				<VCol class="v-col-12 v-col-md-6">
					<VSelect v-model="mainData.settings.salariesAndWagesExpenseAccountId"
						id="payrollSettingsSalariesAndWagesExpenseAccount"
						label="Salaries & Wages Expense Account"
						:items="mainData.referenceData.expenseAccounts"
						itemValue="id"
						itemTitle="name"
						:disabled="mainData.isProcessing" />
				</VCol>
			</VRow>
			<VRow>
				<VCol class="v-col-12 v-col-md-6">
					<VSelect v-model="mainData.settings.payrollTaxesPayableAccountId"
						id="payrollSettingsPayrollTaxesPayableAccount"
						label="Payroll Taxes Payable Account"
						:items="mainData.referenceData.liabilityAccounts"
						itemValue="id"
						itemTitle="name"
						:disabled="mainData.isProcessing" />
				</VCol>
				<VCol class="v-col-12 v-col-md-6">
					<VSelect v-model="mainData.settings.employerTaxesExpenseAccountId"
						id="payrollSettingsEmployerTaxesExpenseAccount"
						label="Employer Taxes Expense Account"
						:items="mainData.referenceData.expenseAccounts"
						itemValue="id"
						itemTitle="name"
						:disabled="mainData.isProcessing" />
				</VCol>
			</VRow>
			<VRow>
				<VCol class="v-col-12 v-col-md-6">
					<VTextField :modelValue="mainData.settings.texasSutaTaxRate"
						id="payrollSettingsTexasSutaTaxRate"
						label="TX SUTA Tax Rate"
						:items="mainData.referenceData.liabilityAccounts"
						itemValue="id"
						itemTitle="name"
						suffix="%"
						:disabled="mainData.isProcessing"
						@update:modelValue="value => mainData.settings.tempTexasSutaTaxRate = value"
						@update:focused="isFocused => { if (!isFocused) { mainData.settings.texasSutaTaxRate = formatTaxRate(mainData.settings.tempTexasSutaTaxRate); mainData.settings.tempTexasSutaTaxRate = undefined } }" />
				</VCol>
			</VRow>
			<div class="text-center">
				<VBtn class="save-payroll-settings-action"
					color="primary"
					title="Save Changes"
					:disabled="mainData.isProcessing || !mainData.isDirty"
					@click="saveSettings">
					<SaveIcon/>
				</VBtn>
				<VBtn class="revert-payroll-settings-action ml-4"
					color="secondary"
					title="Cancel and Undo Changes"
					:disabled="mainData.isProcessing || !mainData.isDirty"
					@click="revertSettings">
					<CloseIcon/>
				</VBtn>
			</div>
		</div>
		<VSnackbar v-model="mainData.isShowingSuccessMessage" timeout="4000" rounded="pill">
			<span class="save-payroll-settings-success-message">The Payroll Settings were saved successfully.</span>
			<template v-slot:actions>
				<v-btn color="primary" variant="text" @click="mainData.isShowingSuccessMessage = false">Close</v-btn>
			</template>
		</VSnackbar>
		<VDialog v-model="promptDialog.isPromptingUser" width="auto" scrim="true" class="confirm-cancel-dialog">
			<VCard>
				<VCardText>{{ promptDialog.userPrompt }}</VCardText>
				<VCardActions>
					<VBtn color="primary" class="yes-action" @click="promptDialog.promptConfirmationAction">Yes</VBtn>
					<VBtn color="secondary" @click="promptDialog.isPromptingUser = false" class="cancel-action">Cancel</VBtn>
				</VCardActions>
			</VCard>
		</VDialog>
	</div>
</template>
