<script setup>
	import { nextTick, onMounted, reactive } from "vue";
	import { VAlert, VBtn, VBtnToggle, VCard, VCardActions, VCardText, VCardTitle, VCheckbox, VCol, VDivider, VProgressLinear, VRow, VSpacer, VTable } from "vuetify/components";
	import { accountingService, executeServiceCall } from "@/services";
	import { AddPrimaryIcon, CardsViewIcon, ListViewIcon } from "@/components";
	import { CustomerPaymentIcon, EditCustomerPaymentDialog, EditPartyDialog, PartyIcon, TransactionHistoryIcon, ViewCustomerTransactionHistoryDialog } from "../components";

	const customerTransactionHistoryInfo = reactive({
		customerId: "",
		isShowing: false,
	});
	const editCustomerPaymentInfo = reactive({
		customerId: null,
		hasAnyChanges: false,
		isEditing: false,
	});
	const editPartyInfo = reactive({
		isEditing: false,
		partyId: null,
	});
	const partiesView = reactive({
		hasError: false,
		isLoading: false,
		parties: [],
		viewSelection: 0,
	});

	function addParty() {
		editPartyInfo.partyId = null;
		editPartyInfo.isEditing = true;
	}
	function createCustomerPaymentFor(customerId) {
		editCustomerPaymentInfo.isEditing = false;
		editCustomerPaymentInfo.customerId = null;
		editCustomerPaymentInfo.customerId = customerId;
		editCustomerPaymentInfo.isEditing = true;
	}
	async function editParty(partyId) {
		if (!partyId)
			return;

		editPartyInfo.partyId = partyId;
		editPartyInfo.isEditing = true;
	}
	async function loadPartiesView() {
		partiesView.isLoading = true;
		partiesView.hasError = false;

		await executeServiceCall(accountingService.party.list)
			.then(({ data }) => partiesView.parties = data)
			.catch(() => partiesView.hasError = true)
			.finally(() => partiesView.isLoading = false);
	}
	async function onEditPartyDialogClosed() {
		editPartyInfo.isEditing = false;
		if (editPartyInfo.wasPartyUpdated) {
			await nextTick(async () => {
				await loadPartiesView();
				editPartyInfo.wasPartyUpdated = false;
			});
		}
	}
	async function onCustomerPaymentDialogClosed() {
		editCustomerPaymentInfo.isEditing = false;
		if (editCustomerPaymentInfo.hasAnyChanges) {
			await loadPartiesView();
			editCustomerPaymentInfo.hasAnyChanges = false;
		}
	}
	function onCustomerPaymentUpdated() { editCustomerPaymentInfo.hasAnyChanges = true; }
	function onViewCustomerTransactionHistoryDialogClosed() {
		customerTransactionHistoryInfo.isShowing = false;
	}
	function viewTransactionHistory(customerId) {
		customerTransactionHistoryInfo.isShowing = false;
		customerTransactionHistoryInfo.customerId = null;
		customerTransactionHistoryInfo.customerId = customerId;
		customerTransactionHistoryInfo.isShowing = true;
	}

	onMounted(async () => await loadPartiesView());
</script>

<template>
	<div class="container my-4">
		<VRow>
			<VCol class="v-col-auto">
				<h1 class="col-auto"><PartyIcon fontSize="48px" /> Party Management</h1>
			</VCol>
			<VSpacer />
			<VCol class="v-col-auto">
				<VBtnToggle v-model="partiesView.viewSelection" :disabled="partiesView.isLoading">
					<VBtn><CardsViewIcon/> Card View</VBtn>
					<VBtn><ListViewIcon/> List View</VBtn>
				</VBtnToggle>
			</VCol>
			<VSpacer />
			<VCol class="v-col-auto text-right">
				<VBtn class="ma-2 create-party-action"
					color="primary"
					variant="flat"
					size="large"
					:disabled="partiesView.isLoading"
					@click="addParty">
					<AddPrimaryIcon class="mr-2"/> New Party
				</VBtn>
			</VCol>
		</VRow>

		<template v-if="partiesView.hasError">
			<VAlert color="error" class="mb-4">Something went wrong.  Try again later.</VAlert>
		</template>
		<template v-if="partiesView.isLoading">
			<VProgressLinear indeterminate color="primary"></VProgressLinear>
		</template>

		<template :class="partiesView.isLoading ? 'disabled row' : 'row'">
			<VRow v-if="partiesView.viewSelection === 0">
				<VCol v-for="party in partiesView.parties" class="v-col-3">
					<VCard>
						<VCardTitle @click="editParty(party.id)" class="clickable">{{ party.name }}</VCardTitle>
						<VCardText>
							<VDivider></VDivider>
							<VCheckbox :readonly="true" :isDisabled="true" label="Is Customer?" :modelValue="party.isCustomer" hideDetails="true" density="compact"></VCheckbox>
							<VCheckbox :readonly="true" :isDisabled="true" label="Is Supplier?" :modelValue="party.isSupplier" hideDetails="true" density="compact"></VCheckbox>
						</VCardText>
						<VCardActions>
							<VBtn v-if="party.hasOpenInvoices"
								class="create-invoice-payment-action"
								variant="plain"
								color="primary"
								title="Pay Invoice"
								:disabled="partiesView.isLoading"
								@click="createCustomerPaymentFor(party.id)">
								<CustomerPaymentIcon/>
							</VBtn>
							<VBtn v-if="party.hasTransactions"
								variant="plain"
								color="primary"
								title="View Invoice and Payment History"
								:disabled="partiesView.isLoading"
								@click="viewTransactionHistory(party.id)">
								<TransactionHistoryIcon/>
							</VBtn>
						</VCardActions>
					</VCard>
				</VCol>
			</VRow>
			<VTable v-if="partiesView.viewSelection === 1" class="table-striped">
				<thead>
					<tr>
						<th></th>
						<th>Name</th>
						<th>Is Customer?</th>
						<th>Is Supplier?</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="party in partiesView.parties" :key="party.id">
						<td>
							<VBtn v-if="party.hasOpenInvoices"
								class="create-invoice-payment-action"
								variant="plain"
								color="primary"
								title="Pay Invoice"
								:disabled="partiesView.isLoading"
								@click="createCustomerPaymentFor(party.id)">
								<CustomerPaymentIcon/>
							</VBtn>
							<VBtn v-if="party.hasTransactions"
								variant="plain"
								color="primary"
								title="View Invoice and Payment History"
								:disabled="partiesView.isLoading"
								@click="viewTransactionHistory(party.id)">
								<TransactionHistoryIcon/>
							</VBtn>
						</td>
						<td @click="editParty(party.id)" class="clickable">{{ party.name }}</td>
						<td @click="editParty(party.id)" class="clickable">{{ party.isCustomer ? "Yes" : "" }}</td>
						<td @click="editParty(party.id)" class="clickable">{{ party.isSupplier ? "Yes" : "" }}</td>
					</tr>
				</tbody>
			</VTable>
		</template>

		<EditPartyDialog :partyId="editPartyInfo.partyId"
			:shouldBeEditing="editPartyInfo.isEditing"
			:isDeleteAllowed="true"
			@partyUpdated="editPartyInfo.wasPartyUpdated = true"
			@partyDeleted="editPartyInfo.wasPartyUpdated = true"
			@closed="onEditPartyDialogClosed" />
		<EditCustomerPaymentDialog :customerId="editCustomerPaymentInfo.customerId"
			:shouldBeEditing="editCustomerPaymentInfo.isEditing"
			@invoicePaymentUpdated="onCustomerPaymentUpdated"
			@closed="onCustomerPaymentDialogClosed" />
		<ViewCustomerTransactionHistoryDialog :customerId="customerTransactionHistoryInfo.customerId"
			:shouldBeShowing="customerTransactionHistoryInfo.isShowing"
			@closed="onViewCustomerTransactionHistoryDialogClosed" />
	</div>
</template>

<style scoped>
	.v-card-title.clickable {
		cursor: pointer;
	}
	.v-card-title.clickable:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}
	.v-theme--light.VTable.table-striped tbody tr:hover {
		background-color: rgba(0, 0, 0, 0.1);
		cursor: pointer;
	}
</style>
