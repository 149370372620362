import axios from "axios";
import { useUserStore } from "@/stores";
import { authenticationService } from "./authenticationService";

// Data
const serviceUrlRoot = process.env.VUE_APP_SERVICE_URL_ROOT || "https://localhost:7110/api";

// Functions
const createConfig = (userToken) => {
	return { headers: { "auth-token": userToken, "Cache-Control": "no-cache", "Pragma": "no-cache", "Expires": "0" } };
};
const resolveUrl = path => {
	return `${serviceUrlRoot}/${path}`;
};

async function handleError(error) {
	console.error(error);
	if (error.response && error.response.status === 401) {
		let isRecoverable = false;
		await authenticationService.renewToken()
			.then(async isRenewed => {
				if (!isRenewed)
					await authenticationService.signIn(() => isRecoverable = true);
			});
		return new Promise(r => r(isRecoverable));
	}
	return new Promise(r => r(false));
}

async function executeServiceCall(serviceCall) {
	let isRetrying = false;
	let lastError = null;
	do {
		try {
			var result = await serviceCall();
			return Promise.resolve(result);
		}
		catch (error) {
			const isRecoverable = await handleError(error);
			if (isRecoverable) {
				isRetrying = true;
			}
			lastError = error;
		}
	}
	while (isRetrying);

	return Promise.reject(lastError);
};

// webApiService
const webApiService = {
	executeGet: async (url) => {
		const userToken = useUserStore().getAccessToken();
		const getPromise = userToken
			? axios.get(resolveUrl(url), createConfig(userToken))
			: axios.get(resolveUrl(url));
		return await getPromise;
	},
	executePost: async (url, data) => {
		const userToken = useUserStore().getAccessToken();
		const postPromise = userToken
			? axios.post(resolveUrl(url), data, createConfig(userToken))
			: axios.post(resolveUrl(url), data);
		return await postPromise;
	},
	resolveUrl: resolveUrl
};

export { webApiService, serviceUrlRoot, executeServiceCall };
