<script>
import { VCard, VCardText, VDivider, VFadeTransition, VList, VListItem, VNavigationDrawer } from "vuetify/components";
import { useUserStore } from "@/stores";
import { Roles } from "@/UserRoles";
import { getRouter, developmentTemplateRoute } from "@/router";

export default {
	name: "DevelopmentTemplateView",

	components: {
		VCard, VCardText, VDivider, VFadeTransition, VList, VListItem, VNavigationDrawer,
	},

	created() {
		const userStore = useUserStore();
		this.account = userStore.getAccount();
	},

	data: () => ({
		account: null,
		isEditingInvoice: false,
		Roles: Roles,
	}),

	methods: {
		isRouteAuthorized(routeName) {
			const route = developmentTemplateRoute.children
				.find(childRoute => childRoute.name === routeName);
			if (!route)
				return false;
			if (!route.meta || !route.meta.authorize)
				return true;
			return this.account.roles.filter(role => route.meta.authorize.includes(role)).length > 0;
		},
		isUserInAnyRole(roles) {
			return this.account.roles.filter(role => roles.includes(role)).length > 0;
		},
		navigateTo(to) {
			getRouter().push(to);
		},
	},
}
</script>

<template>
	<div>
		<v-navigation-drawer color="primary">
			<v-list>
				<v-list-item title="Development" prepend-icon="mdi-code-braces"></v-list-item>
			</v-list>
			<v-divider></v-divider>
			<v-list density="compact" nav>
				<v-list-item v-if="this.isRouteAuthorized('developmentHome')" title="Home" prepend-icon="mdi-code-braces" @click="navigateTo({ name: 'developmentHome' })"></v-list-item>
				<v-list-item v-if="this.isRouteAuthorized('developmentRecentOperations')" title="Recent Operations" prepend-icon="mdi-format-list-bulleted" @click="navigateTo({ name: 'developmentRecentOperations' })"></v-list-item>
				<v-list-item v-if="this.isRouteAuthorized('developmentRecentErrors')" title="Recent Errors" prepend-icon="mdi-close-octagon-outline" @click="navigateTo({ name: 'developmentRecentErrors' })"></v-list-item>
			</v-list>
		</v-navigation-drawer>

		<router-view v-slot="{ Component }">
			<v-fade-transition mode="out-in" :hide-on-leave="true">
				<component :is="Component"/>
			</v-fade-transition>
		</router-view>
	</div>
</template>
