const Roles = {
	Accountant: "Accountant",
	Developer: "Developer",
	Director: "Director",
	Instructor: "Instructor",
	Representative: "Representative",
	Student: "Student"
};

const RoleIds = {
	Accountant: "09b2bf7d-87c9-4e05-9303-80a961756c0b",
	Developer: "cdbed3fc-bc87-47e1-bdab-5243fab213aa",
	Director: "d11e6c4c-569a-4201-8060-d21dd0649bfc",
	Instructor: "28466beb-a89c-4a6d-92b4-f00e331279ae",
	Representative: "1f15f012-16b1-4fe7-8412-3d932b97598e",
	Student: "f822eb07-13de-432c-b50c-1fed97baf680"
};

export { Roles, RoleIds };
