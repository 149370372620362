<script setup>
	import { nextTick, reactive, ref, watch } from "vue";
	import { VBtn, VCol, VRow, VSelect, VSpacer, VTable, VTextarea, VTextField } from "vuetify/components";
	import { accountingService, executeServiceCall, getValidationErrorsFromResponse } from "@/services";
	import { formatAmount, hasArrayChanged, hasAnySimpleValueChanged, parseNumber } from "@/utils";
	import { AddSecondaryIcon, RemoveSecondaryIcon, iconClassNames } from "@/components";
	import BaseEditDialog from "@/components/BaseEditDialog.vue";
	import EditPartyDialog from "./EditPartyDialog.vue";
	import EditProductDialog from "./EditProductDialog.vue";
	import InvoiceIcon from "./InvoiceIcon.vue";

	const props = defineProps({
		shouldBeEditing: Boolean,
		originalInvoiceId: String,
		disableSaveAndNewButton: Boolean,
	});
	const emit = defineEmits([ "closed", "invoicesUpdated" ]);

	const editPartyInfo = reactive({
		isEditing: false,
		partyId: null,
	});
	const editProductInfo = reactive({
		isEditing: false,
		lineIndex: -1,
		productId: null,
	});
	const invoiceNumber = ref(null);
	const mainData = reactive({
		currentInvoiceId: null,
		hasCriticalError: false,
		invoice: null,
		isDirty: false,
		isSaving: false,
		originalSnapshot: null,
		referenceData: reactive(null),
		validationErrors: [],
	});
	const rules = {
		required: value => !!value || "This value is required.",
	};

	function addInvoiceLine() {
		const newLine = { productId: null, notes: null, quantity: null, unitPrice: null };
		const { lines } = mainData.invoice;
		lines.push(newLine);
	}
	function editParty() {
		editPartyInfo.partyId = mainData.invoice.partyId;
		editPartyInfo.isEditing = true;
	}
	function emitClosed() { emit("closed"); }
	function emitInvoicesUpdated() { emit("invoicesUpdated"); }
	function ensureNumbers() {
		if (!mainData.invoice)
			return;

		for (var i = 0, line; line = mainData.invoice.lines[i]; i++) {
			if (line.quantity)
				line.quantity = Number(parseNumber(line.quantity).toFixed(2));
			if (line.unitPrice)
				line.unitPrice = Number(parseNumber(line.unitPrice).toFixed(2));
		}
	}
	function getCustomerBillingAddress() {
		if (!mainData.invoice || !mainData.invoice.partyId)
			return "";
		return mainData.referenceData.parties.find(party => party.id == mainData.invoice.partyId).billingAddress || "";
	}
	function generateOriginalSnapshot() {
		mainData.originalSnapshot = JSON.parse(JSON.stringify(mainData.invoice));
		mainData.isDirty = false;
	}
	function getLineAmount(line) {
		let amount = 0;
		if (line && line.quantity && line.unitPrice) {
			amount = line.quantity * line.unitPrice;
		}
		return amount;
	}
	function getLineDisplayAmount(line) {
		const amount = getLineAmount(line);
		return formatAmount(amount);
	}
	function getSubtotal() {
		let subtotal = 0;
		if (mainData.invoice) {
			mainData.invoice.lines.forEach(line => {
				subtotal += getLineAmount(line);
			});
		}
		return formatAmount(subtotal);
	}
	function getTotal() {
		const subtotal = getSubtotal();
		if (subtotal)
			return "$ " + subtotal;
		return "";
	}
	function handlePartyChanged() {
		if (!mainData.invoice)
			return;

		if (mainData.invoice.partyId === "0") {
			editPartyInfo.isEditing = false;
			editPartyInfo.partyId = null;
			editPartyInfo.isEditing = true;
			mainData.invoice.partyId = null;
		} else {
			const party = mainData.referenceData.parties.find(p => p.id === mainData.invoice.partyId);
			if (party) {
				if (party.defaultAccountsReceivableAccountId)
					mainData.invoice.accountsReceivableAccountId = party.defaultAccountsReceivableAccountId;
				if (party.defaultTerms)
					mainData.invoice.terms = party.defaultTerms;
			}
		}
	}
	async function loadInvoice() {
		resetErrors();
		if (!await refreshReferenceData())
			return;

		if (!mainData.currentInvoiceId) {
			const today = new Date(Date.now());
			mainData.invoice = {
				number: mainData.referenceData.nextInvoiceNumber,
				date: today.getFullYear() + "-" + ("00" + (today.getMonth()+1)).slice(-2) + "-" + ("00" + today.getDate()).slice(-2),
				partyId: null,
				accountsReceivableAccountId: null,
				terms: null,
				lines: [
					{ productId: null, notes: null, quantity: null, unitPrice: null },
					{ productId: null, notes: null, quantity: null, unitPrice: null },
					{ productId: null, notes: null, quantity: null, unitPrice: null },
				]
			};
		}
		else {
			await executeServiceCall(() => accountingService.invoice.byId(mainData.currentInvoiceId))
				.then(({ data }) => mainData.invoice = data)
				.catch(() => mainData.hasCriticalError = true);
		}

		if (!mainData.hasCriticalError)
			generateOriginalSnapshot();
	}
	function onBaseBeginSaving(done) { mainData.isSaving = true; done(); }
	function onBaseClosed(done) {
		resetErrors();
		mainData.isDirty = false;
		mainData.originalSnapshot = null;
		mainData.invoice = null;
		mainData.referenceData = null;
		emitClosed();
		done();
	}
	function onBaseEndSaving(done) { mainData.isSaving = false; done(); }
	function onBaseItemUpdated(done) { emitInvoicesUpdated(); done(); }
	async function onBaseLoadingItem(done) { await loadInvoice(); done(); }
	async function onBaseResetData(done) {
		resetErrors();
		mainData.currentInvoiceId = null;
		mainData.isDirty = false;
		mainData.originalSnapshot = null;
		mainData.invoice = null;
		mainData.referenceData = null;
		done();
	}
	async function onBaseSaving(done) { await saveInvoice(); done(); }
	function promptIfNewProductRequest() {
		if (mainData.invoice) {
			const newProductLineIndex = mainData.invoice.lines.map(line => line.productId).indexOf("0");
			if (newProductLineIndex >= 0) {
				editProductInfo.lineIndex = newProductLineIndex;
				editProductInfo.isEditing = false;
				editProductInfo.productId = null;
				editProductInfo.isEditing = true;
				mainData.invoice.lines[newProductLineIndex].productId = null;
			}
		}
	}
	async function refreshAndSelectParty(id) {
		await refreshReferenceData()
			.then(async isSuccessful => {
				if (isSuccessful) {
					await nextTick(() => {
						mainData.invoice.partyId = id;
					});
				}
			});
		// if (!await refreshReferenceData())
		// 	return;
		// await nextTick(() => {  });
	}
	async function refreshAndSelectProduct(id) {
		if (!await refreshReferenceData())
			return;
		await nextTick(() => {
			mainData.invoice.lines[editProductInfo.lineIndex].productId = id;
			setLineDefaults(editProductInfo.lineIndex);
		});
	}
	async function refreshReferenceData() {
		await executeServiceCall(accountingService.invoice.referenceData)
			.then(({ data }) => {
				const referenceData = data;
				referenceData.parties.push({ id: "0", name: "(Add Customer...)" });
				referenceData.products.push({ id: "0", name: "(Add Product...)" });
				mainData.referenceData = referenceData;
			})
			.catch(() => {
				mainData.referenceData = null;
				mainData.hasCriticalError = true;
			});
		return !mainData.hasCriticalError;
	}
	function removeInvoiceLine(index) {
		mainData.invoice.lines.splice(index, 1);
		if (mainData.invoice.lines.length === 0)
			addInvoiceLine();
	}
	function resetErrors() {
		mainData.hasCriticalError = false;
		mainData.validationErrors = [];
	}
	async function saveInvoice() {
		ensureNumbers();
		await executeServiceCall(() => accountingService.invoice.save(mainData.invoice))
			.catch(({ response }) => {
				if (response?.status === 400)
					mainData.validationErrors = getValidationErrorsFromResponse(response);
				else
					mainData.hasCriticalError = true;
			});
	}
	function setLineDefaults(lineIndex) {
		const line = mainData.invoice?.lines[lineIndex];
		if (!line?.productId)
			return;

		const product = mainData.referenceData.products.find(product => product.id === line.productId);
		if (!product || !product.unitPrice || line.unitPrice)
			return;

		line.unitPrice = new Number(product.unitPrice).toFixed(2);
	}
	function updateIsDirty() {
		const { invoice, originalSnapshot } = mainData;
		if (!invoice || !originalSnapshot)
			return;

		const isDirty = hasAnySimpleValueChanged(invoice, originalSnapshot, ["number","date","partyId","accountsReceivableAccountId","terms"])
			|| hasArrayChanged(invoice.lines, originalSnapshot.lines, ["productId","notes","quantity","unitPrice"]);
		mainData.isDirty = isDirty;
	}

	watch(() => ({...mainData.invoice}), (current, previous) => {
		updateIsDirty();
		if (current?.partyId !== previous?.partyId)
			handlePartyChanged();
		promptIfNewProductRequest();
	}, { deep: true });
	watch(() => props.originalInvoiceId, () => mainData.currentInvoiceId = props.originalInvoiceId);

	mainData.currentInvoiceId = props.originalInvoiceId;
</script>

<template>
	<div>
		<BaseEditDialog subjectName="Invoice"
			:shouldBeEditing="props.shouldBeEditing"
			:isFullscreen="true"
			:isDeleteAllowed="false"
			:isPrintAllowed="!!mainData.currentInvoiceId"
			:isSaveAndNewAllowed="!props.disableSaveAndNewButton"
			:isDirty="mainData.isDirty"
			:hasCriticalError="mainData.hasCriticalError"
			:validationErrors="mainData.validationErrors"
			@beginSaving="onBaseBeginSaving"
			@endSaving="onBaseEndSaving"
			@loadingItem="onBaseLoadingItem"
			@saving="onBaseSaving"
			@resetData="onBaseResetData"
			@itemUpdated="onBaseItemUpdated"
			@closed="onBaseClosed">
			<template v-slot:title><InvoiceIcon/> Invoice Management</template>
			<div v-if="mainData.invoice">
				<v-row>
					<v-col class="v-col-12 v-col-md-4">
						<v-text-field id="invoiceNumber" ref="invoiceNumber" v-model="mainData.invoice.number"
							type="number"
							label="Invoice Number"
							:placeholder="`${mainData.referenceData?.nextInvoiceNumber} is next`"
							:rules="[rules.required]"
							:disabled="mainData.isSaving"
							autofocus />
						<v-text-field id="invoiceDate" v-model="mainData.invoice.date"
							type="date"
							label="Date"
							:rules="[rules.required]"
							:disabled="mainData.isSaving" />
					</v-col>
					<v-col class="v-col-12 v-col-md-4">
						<v-select id="invoiceParty"
							v-model="mainData.invoice.partyId"
							:items="mainData.referenceData.parties"
							item-value="id"
							item-title="name"
							label="Customer"
							:rules="[rules.required]"
							:disabled="mainData.isSaving" />
						<v-textarea :model-value="getCustomerBillingAddress()"
							class="invoice-billing-address"
							label="Billing Address"
							variant="outlined"
							:append-icon="iconClassNames.edit"
							rows="3"
							readonly
							:disabled="!mainData.invoice.partyId"
							@click:append="editParty()" />
					</v-col>
					<v-col class="v-col-12 v-col-md-4">
						<v-select id="invoiceAccountsReceivableAccount"
							v-model="mainData.invoice.accountsReceivableAccountId"
							:items="mainData.referenceData.accounts"
							item-value="id"
							item-title="name"
							label="Accounts Receivable Account"
							:rules="[rules.required]"
							:disabled="mainData.isSaving" />
						<v-text-field id="invoiceTerms" v-model="mainData.invoice.terms"
							label="Due Date and Discount Terms"
							placeholder="e.g. NET 30 or NET NEXT 15TH"
							:counter="20"
							:rules="[rules.required]"
							:disabled="mainData.isSaving" />
					</v-col>
				</v-row>
				<v-row>
					<v-col class="v-col-12">
						<v-table density="compact">
							<colgroup>
								<col width="5%"/>
								<col width="25%"/>
								<col width="45%"/>
								<col width="6%"/>
								<col width="7%"/>
								<col width="7%"/>
								<col width="5%"/>
							</colgroup>
							<thead>
								<th></th>
								<th>Product</th>
								<th>Notes</th>
								<th>Quantity</th>
								<th>Unit Price</th>
								<th>Amount</th>
								<th></th>
							</thead>
							<tbody>
								<tr v-for="(invoiceLine, i) in mainData.invoice.lines" class="text-sm invoice-line" :data-i="i">
									<td class="text-center">
										<v-btn color="secondary"
											class="remove-line-action"
											size="x-small"
											@click="removeInvoiceLine(i)"
											:disabled="mainData.isSaving">
											<RemoveSecondaryIcon fontSize="18px"/>
										</v-btn>
									</td>
									<td>
										<v-select v-model="invoiceLine.productId"
											class="line-product"
											:items="mainData.referenceData.products"
											item-value="id"
											item-title="name"
											hide-details
											:rules="[rules.required]"
											:disabled="mainData.isSaving"
											@update:model-value="setLineDefaults(i)" />
									</td>
									<td>
										<v-text-field v-model="invoiceLine.notes"
											class="line-notes"
											hide-details
											:disabled="mainData.isSaving" />
									</td>
									<td>
										<v-text-field v-model="invoiceLine.quantity"
											type="number"
											class="line-quantity"
											hide-details
											:disabled="mainData.isSaving" />
									</td>
									<td>
										<v-text-field v-model="invoiceLine.unitPrice"
											type="number"
											class="line-unit-price"
											hide-details
											:disabled="mainData.isSaving" />
									</td>
									<td class="text-right">
										{{ getLineDisplayAmount(invoiceLine) }}
									</td>
									<td class="text-center">
										<v-btn v-if="i === mainData.invoice.lines.length - 1"
											class="add-line-action"
											color="primary"
											size="x-small"
											:disabled="mainData.isSaving"
											@click="addInvoiceLine">
											<AddSecondaryIcon fontSize="18px"/>
										</v-btn>
									</td>
								</tr>
							</tbody>
						</v-table>
					</v-col>
				</v-row>
				<v-row>
					<v-spacer></v-spacer>
					<v-col class="v-col-12 v-col-md-3">
						<v-table density="compact">
							<tbody>
								<tr>
									<th>Subtotal</th>
									<th class="text-right">{{ getSubtotal() }}</th>
								</tr>
								<tr>
									<th>Tax Rate</th>
									<th class="text-right">0.00%</th>
								</tr>
								<tr>
									<th>Tax</th>
									<th class="text-right">-</th>
								</tr>
								<tr>
									<th><h5>TOTAL</h5></th>
									<th class="text-right"><h5>{{ getTotal() }}</h5></th>
								</tr>
							</tbody>
						</v-table>
					</v-col>
					<v-col class="v-col-12 v-col-md-1"></v-col>
				</v-row>
			</div>
		</BaseEditDialog>

		<EditPartyDialog :partyId="editPartyInfo.partyId"
			:shouldBeEditing="editPartyInfo.isEditing"
			:isDeleteAllowed="false"
			@closed="editPartyInfo.isEditing = false"
			@partyUpdated="async (id) => await refreshAndSelectParty(id)"/>

		<EditProductDialog :productId="editProductInfo.productId"
			:shouldBeEditing="editProductInfo.isEditing"
			@closed="editProductInfo.isEditing = false"
			@productUpdated="async (id) => await refreshAndSelectProduct(id)"/>
	</div>
</template>

<style scoped>
	.v-table table tbody tr td {
		padding-left: 0;
		padding-right: 0;
	}
	.v-table table tbody tr td {
		height: 40px;
		border: none;
	}
	.v-table .v-table__wrapper > table > tbody > tr:not(:last-child) > td {
		border-bottom: none;
	}
	.text-sm :deep(input), .text-sm :deep(.v-autocomplete__selection-text) {
		font-size: 0.8em;
	}
</style>
