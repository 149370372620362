<script>
import { VAlert, VBtn, VBtnToggle, VCard, VCardText, VCardTitle, VCol, VDivider, VProgressLinear, VRow, VSpacer, VTable } from "vuetify/components";
import { accountingService, executeServiceCall } from "@/services";
import { formatAmount } from "@/utils";
import EditProductDialog from "../components/EditProductDialog.vue";
import ProductIcon from "../components/ProductIcon.vue";

export default {
	name: "ProductManagementView",

	components: {
		VAlert, VBtn, VBtnToggle, VCard, VCardText, VCardTitle, VCol, VDivider, VProgressLinear, VRow, VSpacer, VTable,
		EditProductDialog, ProductIcon,
	},

	async created() {
		await this.loadProductsView();
	},

	data: () => ({
		editingInfo: {
			isEditing: false,
			productId: null,
		},
		productsView: {
			hasError: false,
			isLoading: false,
			products: [],
			viewSelection: 0,
		},
	}),

	methods: {
		addProduct() {
			this.editingInfo.productId = null;
			this.editingInfo.isEditing = true;
		},
		async editProduct(productId) {
			if (!productId)
				return;

			this.editingInfo.productId = productId;
			this.editingInfo.isEditing = true;
		},
		formatAmount(value) {
			return formatAmount(value);
		},
		async loadProductsView() {
			this.productsView.isLoading = true;
			this.productsView.hasError = false;

			await executeServiceCall(accountingService.product.list)
				.then(({ data }) => {
					this.productsView.products = data;
				})
				.catch(() => {
					this.productsView.hasError = true;
				})
				.finally(() => this.productsView.isLoading = false);
		},
		async onEditProductDialogClosed() {
			this.editingInfo.isEditing = false;
			if (this.editingInfo.wasProductUpdated) {
				await this.$nextTick(async () => {
					await this.loadProductsView();
					this.editingInfo.wasProductUpdated = false;
				});
			}
		},
	},
}
</script>

<template>
	<div class="container my-4">
		<v-row>
			<v-col class="v-col-auto">
				<h1 class="col-auto"><ProductIcon></ProductIcon> Product Management</h1>
			</v-col>
			<v-spacer></v-spacer>
			<v-col class="v-col-auto">
				<v-btn-toggle v-model="this.productsView.viewSelection" :disabled="this.productsView.isLoading">
					<v-btn><i class="mdi mdi-24px mdi-cards"></i> Card View</v-btn>
					<v-btn><i class="mdi mdi-24px mdi-view-list"></i> List View</v-btn>
				</v-btn-toggle>
			</v-col>
			<v-spacer></v-spacer>
			<v-col class="v-col-auto text-right">
				<v-btn color="primary" variant="flat" size="large" class="ma-2 create-product-action" :disabled="this.productsView.isLoading" @click="addProduct"><i class="mdi mdi-plus mr-2"></i> New Product</v-btn>
			</v-col>
		</v-row>

		<template v-if="this.productsView.hasError">
			<v-alert color="error" class="mb-4">Something went wrong.  Try again later.</v-alert>
		</template>
		<template v-if="this.productsView.isLoading">
			<v-progress-linear indeterminate color="primary"></v-progress-linear>
		</template>

		<template :class="this.productsView.isLoading ? 'disabled row' : 'row'">
			<v-row v-if="this.productsView.viewSelection === 0">
				<v-col v-for="product in this.productsView.products" class="v-col-4">
					<v-card @click="editProduct(product.id)" class="clickable">
						<v-card-title>{{ product.name }}</v-card-title>
						<v-card-subtitle>{{ product.productType }}</v-card-subtitle>
						<v-card-text>
							<v-divider></v-divider>
							<div>
								<strong>Unit Price: </strong> {{ this.formatAmount(product.unitPrice) }}
							</div>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			<v-table v-if="this.productsView.viewSelection === 1" class="table-striped">
				<thead>
					<tr>
						<th>Name</th>
						<th>Type</th>
						<th>Unit Price</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="product in this.productsView.products" @click="editProduct(product.id)" class="clickable">
						<td>{{ product.name }}</td>
						<td>{{ product.productType }}</td>
						<td>{{ this.formatAmount(product.unitPrice) }}</td>
					</tr>
				</tbody>
			</v-table>
		</template>

		<EditProductDialog :productId="this.editingInfo.productId"
			:shouldBeEditing="this.editingInfo.isEditing"
			@productUpdated="this.editingInfo.wasProductUpdated = true"
			@closed="onEditProductDialogClosed" />
	</div>
</template>

<style scoped>
	.clickable {
		cursor: pointer;
	}
	.clickable:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}
	.v-theme--light.v-table.table-striped tbody tr.clickable:hover {
		background-color: rgba(0, 0, 0, 0.1);
		cursor: pointer;
	}
</style>
