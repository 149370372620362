<script>
import { VAlert, VCol, VProgressLinear, VRow, VTable } from "vuetify/components";
import lucentaryLogoUrl from "@/assets/img/lucentary-color-nobg.svg";
import { useRoute } from 'vue-router';
import { accountingService } from "@/services";

export default {
	name: "InvoicePrintView",

	components: {
		VAlert, VCol, VProgressLinear, VRow, VTable,
	},

	data: () => ({
		hasError: false,
		invoice: null,
		invoiceId: "",
		isLoading: true,
		lucentaryLogoUrl: lucentaryLogoUrl,
	}),

	async created() {
		const route = useRoute();
		this.invoiceId = route.params.id;
		await this.loadInvoice();
	},

	methods: {
		formatAmount(value) {
			if (value == 0)
				return "";
			return new Intl.NumberFormat("en-US", {
				maximumFractionDigits: 2,
				minimumFractionDigits: 2
			}).format(value);
		},
		getInvoiceSubtotal() {
			var total = 0;
			this.invoice.lines.forEach(line => total += line.quantity * line.unitPrice);
			return total;
		},
		getInvoiceTotal() {
			return this.getInvoiceSubtotal();
		},
		async loadInvoice() {
			this.isLoading = true;
			this.hasLoadingError = false;
			this.invoice = null;

			await accountingService.invoice.printView(this.invoiceId)
				.then(({ data }) => {
					this.invoice = data;
					this.isLoading = false;
				})
				.catch(error => {
					console.error(error);
					this.hasError = true;
					this.isLoading = false;
				});
		},
	},
}
</script>

<template>
	<div class="invoice-view">
		<template v-if="this.isLoading">
			<v-progress-linear indeterminate color="primary"></v-progress-linear>
		</template>
		<template v-if="this.hasError">
			<v-alert color="error" class="mb-4">Something went wrong.  Try again later.</v-alert>
		</template>
		<template v-if="this.invoice">
			<v-row>
				<v-col class="v-col-5">
					<img :src="lucentaryLogoUrl" height="50" />
					<p class="pl-4">
						<strong>
						6103 Tiffany Park Ct
						<br/>
						Arlington, TX  76016
						<br/>
						Phone: 720-206-5741
						</strong>
					</p>
				</v-col>
				<v-col class="v-col-5 offset-2">
					<h1 class="primary">Invoice</h1>
					<v-table density="compact">
						<tbody>
							<tr>
								<td><strong>Invoice #</strong></td>
								<td>{{ this.invoice.number }}</td>
							</tr>
							<tr>
								<td><strong>Date</strong></td>
								<td>{{ this.invoice.date }}</td>
							</tr>
							<tr>
								<td><strong>Customer</strong></td>
								<td>{{ this.invoice.partyName }}</td>
							</tr>
							<tr>
								<td><strong>Terms</strong></td>
								<td>{{ this.invoice.terms }}</td>
							</tr>
							<tr>
								<td><strong>Bill To</strong></td>
								<td v-html="this.invoice.billingAddress"></td>
							</tr>
						</tbody>
					</v-table>
				</v-col>
			</v-row>
			<v-table density="compact" class="mt-4">
				<thead>
					<tr>
						<th>Item</th>
						<th>Notes</th>
						<th>Qty</th>
						<th>Unit Price</th>
						<th>Amount</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="line in this.invoice.lines">
						<td>{{ line.productName }}</td>
						<td>{{ line.notes }}</td>
						<td class="text-right">{{ line.quantity }}</td>
						<td class="text-right">{{ this.formatAmount(line.unitPrice) }}</td>
						<td class="text-right">{{ this.formatAmount(line.quantity * line.unitPrice) }}</td>
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<td colspan="2" rowspan="4" class="primary text-center"><strong><em>Thank you for your business!</em></strong></td>
						<td colspan="2">Subtotal</td>
						<td class="text-right">{{ this.formatAmount(this.getInvoiceSubtotal()) }}</td>
					</tr>
					<tr>
						<td colspan="2">Tax Rate</td>
						<td class="text-right">0.00%</td>
					</tr>
					<tr>
						<td colspan="2">Tax</td>
						<td class="text-right">-</td>
					</tr>
					<tr>
						<td colspan="2" class="primary"><strong>TOTAL</strong></td>
						<td class="text-right"><strong>$ {{ this.formatAmount(this.getInvoiceTotal()) }}</strong></td>
					</tr>
				</tfoot>
			</v-table>
		</template>
		<component :is="'style'">
			header.v-toolbar, footer.v-footer, nav.v-navigation-drawer {
				display: none;
			}
		</component>
	</div>
</template>

<style scoped>
	.invoice-view {
		position: absolute;
		top: 0;
		left: 0;
		margin: 0;
		padding: 0;
		width: 100%;
		font-size: 0.8rem;
	}
	.primary {
		color: var(--bs-primary);
	}
</style>
