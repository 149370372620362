// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
	theme: {
		defaultTheme: "light",
		themes: {
			light: {
				colors: {
					primary: "#3f72af",
					secondary: "#6c757d",
					error: "#dc3545",
					info: "#0dcaf0",
					success: "#198754",
					warning: "#ffc107"
				}
			}
		}
	}

}
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
)
