<script setup>
	import { reactive, watch } from "vue";
	import { VCol, VRow, VSelect, VTextField } from "vuetify/components";
	import { accountingService, executeServiceCall, getValidationErrorsFromResponse } from "@/services";
	import { PayrollScheduleIcon } from "./";
	import { hasAnySimpleValueChanged } from "@/utils";
	import { BaseEditDialog } from "@/components";

	const props = defineProps({
		payrollScheduleId: { type: String, required: false },
		shouldBeEditing: { type: Boolean, required: true },
	});
	const emit = defineEmits([ "closed", "payrollScheduleUpdated" ]);

	const mainData = reactive({
		frequencies: ['Weekly', 'Bi-weekly', 'Semi-monthly', 'Monthly'],
		hasCriticalError: false,
		isDirty: false,
		originalSnapshot: null,
		payrollSchedule: null,
		referenceData: null,
		validationErrors: [],
	});
	const rules = {
		required: value => !!value || "This value is required.",
	};

	function emitClosed() { emit("closed"); }
	function emitPayrollScheduleUpdated() { emit("payrollScheduleUpdated", mainData.payrollSchedule.id); }
	async function loadPayrollSchedule() {
		resetErrors();

		if (!props.payrollScheduleId) {
			mainData.payrollSchedule = {
				name: null,
				frequency: null,
				dayOfWeek: null,
				firstDayOfMonth: null,
				secondDayOfMonth: null,
				earliestRunDate: null,
			};
		}
		else {
			await executeServiceCall(() => accountingService.payrollSchedule.byId(props.payrollScheduleId))
				.then(({ data }) => mainData.payrollSchedule = data)
				.catch(() => mainData.hasCriticalError = true);
			if (mainData.hasCriticalError)
				return;
		}

		mainData.originalSnapshot = JSON.parse(JSON.stringify(mainData.payrollSchedule));
	}
	function onBaseBeginSaving(done) { mainData.isSaving = true; done(); }
	function onBaseClosed(done) {
		resetErrors();
		mainData.isDirty = false;
		mainData.originalSnapshot = null;
		mainData.payrollSchedule = null;
		mainData.referenceData = null;
		emitClosed();
		done();
	}
	function onBaseEndSaving(done) { mainData.isSaving = false; done(); }
	function onBaseItemUpdated(done) { emitPayrollScheduleUpdated(); done(); }
	async function onBaseLoadingItem(done) { await loadPayrollSchedule(); done(); }
	async function onBaseSaving(done) { await savePayrollSchedule(); done(); }
	function resetErrors() {
		mainData.hasCriticalError = false;
		mainData.validationErrors = [];
	}
	async function savePayrollSchedule() {
		resetErrors();

		await executeServiceCall(() => accountingService.payrollSchedule.save(mainData.payrollSchedule))
			.then(({ data }) => mainData.payrollSchedule.id = String(data))
			.catch(({ response }) => {
				if (response?.status === 400)
					mainData.validationErrors = getValidationErrorsFromResponse(response);
				else
					mainData.hasCriticalError = true;
			});
	}
	function updateIsDirty() {
		if (!mainData.payrollSchedule || !mainData.originalSnapshot)
			return;

		const isDirty = hasAnySimpleValueChanged(mainData.payrollSchedule, mainData.originalSnapshot, ["name","frequency","dayOfWeek", "firstDayOfMonth", "secondDayOfMonth", "earliestRunDate"]);
		mainData.isDirty = isDirty;
	}

	watch(() => mainData.payrollSchedule, () => { updateIsDirty(); }, { deep: true });
</script>

<template>
	<BaseEditDialog subjectName="Payroll Schedule"
		:isDeleteAllowed="false"
		:shouldBeEditing="props.shouldBeEditing"
		:isDirty="mainData.isDirty"
		:hasCriticalError="mainData.hasCriticalError"
		:validationErrors="mainData.validationErrors"
		:isFullscreen="true"
		@beginSaving="onBaseBeginSaving"
		@endSaving="onBaseEndSaving"
		@loadingItem="onBaseLoadingItem"
		@saving="onBaseSaving"
		@itemUpdated="onBaseItemUpdated"
		@closed="onBaseClosed">
		<template v-slot:title><PayrollScheduleIcon /> Payroll Schedule Management</template>
		<v-row v-if="mainData.payrollSchedule">
			<v-col class="v-col-12 v-col-md-4">
				<v-text-field v-model="mainData.payrollSchedule.name"
					id="payrollScheduleName"
					label="Name"
					:rules="[rules.required]"
					:disabled="mainData.isSaving"
					autofocus />
			</v-col>
			<v-col class="v-col-12 v-col-md-4">
				<v-select v-model="mainData.payrollSchedule.frequency"
					id="payrollScheduleFrequency"
					:items="mainData.frequencies"
					label="Payroll Frequency"
					:rules="[rules.required]"
					:disabled="mainData.isSaving" />

				<v-select v-if="mainData.payrollSchedule.frequency === 'Weekly' || mainData.payrollSchedule.frequency === 'Bi-weekly'"
					v-model="mainData.payrollSchedule.dayOfWeek"
					id="payrollScheduleDayOfWeek"
					:items="['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']"
					label="Day of Week"
					:disabled="mainData.isSaving" />

				<v-text-field v-if="mainData.payrollSchedule.frequency === 'Monthly' || mainData.payrollSchedule.frequency === 'Semi-monthly'"
					v-model="mainData.payrollSchedule.firstDayOfMonth"
					id="payrollScheduleFirstDayOfMonth"
					type="number"
					:label="mainData.payrollSchedule.frequency === 'Monthly' ? 'Day of Month' : 'First Day of Month'"
					:disabled="mainData.isSaving" />
				<v-text-field v-if="mainData.payrollSchedule.frequency === 'Semi-monthly'"
					v-model="mainData.payrollSchedule.secondDayOfMonth"
					id="payrollScheduleSecondDayOfMonth"
					type="number"
					label="Second Day of Month"
					:disabled="mainData.isSaving" />
			</v-col>
			<v-col class="v-col-12 v-col-md-4">
				<v-text-field v-model="mainData.payrollSchedule.earliestRunDate"
					id="payrollScheduleEarliestRunDate"
					type="date"
					label="Earliest Run Date"
					:rules="[rules.required]"
					:disabled="mainData.isSaving" />
			</v-col>
		</v-row>
	</BaseEditDialog>
</template>
