<script setup>
	import lucentaryLogoSvgUrl from "@/assets/img/lucentary-color-nobg.svg";
	import { reactive, ref, watch } from "vue";
	import jsPDF from "jspdf";
	import html2canvas from "html2canvas";
	import { PrintIcon } from "@/components";
	import { executeServiceCall, selfServiceService } from "@/services";
	import { formatAmount } from "@/utils";

	const props = defineProps({
		payStubId: { type: String, required: false },
	});

	const mainData = reactive({
		hasError: false,
		isProcessing: false,
		payStub: null,
	});
	const payStubElement = ref(null);

	function downloadPayStub() {
		mainData.isPrinting = true;

		var canvasElement = document.createElement("canvas");
		canvasElement.setAttribute('width', payStubElement.value.clientWidth + 20);
		canvasElement.setAttribute('height', payStubElement.value.clientHeight);
		html2canvas(payStubElement.value, { canvas: canvasElement })
			.then(canvas => {
				var wid;
				var hgt;
				var img = canvas.toDataURL("image/png", wid = canvas.width, hgt = canvas.height);
				var hratio = hgt / wid;
				var doc = new jsPDF({
					orientation: "landscape",
					format: [203, 101]
				});
				var width = doc.internal.pageSize.width;
				var height = doc.internal.pageSize.height;
				var height = width * hratio;
				doc.addImage(img, "JPEG", width*.025, 10, width*.95, height*.95);
				doc.save(`PayStub-${mainData.payStub.sortableDate}.pdf`);

				mainData.isPrinting = false;
			});
	}
	async function loadPayStubDetails() {
		mainData.hasError = false;
		mainData.isProcessing = true;

		if (!props.payStubId) {
			mainData.payStub = null;
			mainData.isProcessing = false;
			return;
		}

		await executeServiceCall(() => selfServiceService.payStub.byId(props.payStubId))
			.then(({ data }) => mainData.payStub = data)
			.catch(error => { console.error(error); mainData.hasError = true; })
			.finally(() => mainData.isProcessing = false);
	}

	watch(() => props.payStubId, async () => await loadPayStubDetails(), { immediate: true });
</script>

<template>
	<div>
		<VProgressLinear
			v-if="mainData.isProcessing"
			color="primary"
			class="processing-indicator"
			indeterminate/>
		<VAlert v-if="mainData.hasError"
			color="error"
			class="mb-4 critical-error-message">Something went wrong.  Try again later.</VAlert>
		<VAlert v-if="!mainData.isProcessing && !mainData.hasError && !mainData.payStub"
			class="no-pay-stub-detail">No pay stub details are available.</VAlert>
		<template v-if="mainData.payStub">
			<div ref="payStubElement">
				<VRow>
					<VCol class="v-col-12 v-col-md-6">
						<img :src="lucentaryLogoSvgUrl" height="50" />
					</VCol>
					<VCol class="v-col-12 v-col-md-6 text-right"><h2>Pay Stub</h2></VCol>
				</VRow>
				<h5 class="text-right fst-italic">For {{ mainData.payStub.employeeName }}</h5>
				<VRow class="fs-4">
					<VCol class="v-col-12 v-col-md-6">
						<div>Paid on <span class="pay-stub-date fw-bold">{{ mainData.payStub.date }}</span></div>
						<div class="mt-2">For period <span class="pay-stub-period-begin-date fw-bold">{{ mainData.payStub.periodBeginDate }}</span> to <span class="pay-stub-period-end-date fw-bold">{{ mainData.payStub.periodEndDate }}</span></div>
						<div v-if="mainData.payStub.isHourly" class="mt-2">Hourly wage $<span class="pay-stub-hourly-wage fw-bold">{{ formatAmount(mainData.payStub.hourlyWage) }}</span></div>
						<div v-else class="mt-2">Yearly salary $<span class="pay-stub-yearly-salary fw-bold">{{ formatAmount(mainData.payStub.yearlySalary) }}</span></div>
						<div v-if="mainData.payStub.isHourly" class="mt-2"><span class="pay-stub-hours fw-bold">{{ mainData.payStub.hours.toFixed(2) }}</span> hrs this period</div>
					</VCol>
					<VCol class="v-col-12 v-col-md-6">
						<VTable>
							<thead>
								<tr>
									<th>Gross pay</th>
									<th class="pay-stub-gross-pay text-right">{{ formatAmount(mainData.payStub.grossPay) }}</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(withholdingName, i) in Object.keys(mainData.payStub.withholdings)"
									:key="i">
									<td class="pl-12 pay-stub-withholding-label">{{ withholdingName }}</td>
									<td class="text-right pay-stub-withholding-amount">{{ formatAmount(mainData.payStub.withholdings[withholdingName]) }}</td>
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<th>Net pay</th>
									<th class="pay-stub-net-pay text-right fw-bold">$ {{ formatAmount(mainData.payStub.netPay) }}</th>
								</tr>
							</tfoot>
						</VTable>
					</VCol>
				</VRow>
			</div>
			<div class="mt-12 text-center mb-12">
				<VBtn color="primary" @click="downloadPayStub">
					<PrintIcon/>
				</VBtn>
			</div>
		</template>
	</div>
</template>
