<script setup>
	import { nextTick, onMounted, reactive, watch } from "vue";
	import { BIconPlusSquare } from "bootstrap-icons-vue";
	import { academicService, executeServiceCall } from "@/services";
	import { CourseIcon, EditCourseDialog } from "../components";

	// Data
	const editCourseDialog = reactive({
		courseId: null,
		hasChanges: false,
		isEditing: false,
	});
	const mainData = reactive({
		courses: [],
		hasError: false,
		isLoading: false,
		viewChoice: 0,
	});

	// Functions
	async function addCourse() {
		await new Promise(resolve => {
			editCourseDialog.courseId = null;
			editCourseDialog.isEditing = false;
			resolve();
		})
		.then(async () => {
			await nextTick(() => {
				editCourseDialog.courseId = null;
				editCourseDialog.isEditing = true;
			});
		});
	};
	async function editCourse(id) {
		await new Promise(resolve => {
			editCourseDialog.courseId = null;
			editCourseDialog.isEditing = false;
			resolve();
		})
		.then(async () => {
			await nextTick(() => {
				editCourseDialog.courseId = id;
				editCourseDialog.isEditing = true;
			});
		});
	};
	function getCourseName(course) {
		if (course.isDeleted)
			return `${course.name} [Deleted]`;
		return course.name;
	};
	async function loadCourseList() {
		mainData.isLoading = true;
		mainData.hasError = false;

		const includeActive = mainData.viewChoice <= 1;
		const includeDeleted = mainData.viewChoice >= 1;
		await executeServiceCall(() => academicService.course.list(includeActive, includeDeleted))
			.then(({ data }) => mainData.courses = data)
			.catch(error => {
				console.error(error);
				mainData.hasError = true;
			})
			.finally(() => mainData.isLoading = false);
	};
	async function onEditCourseDialogClosed() {
		editCourseDialog.courseId = null;
		editCourseDialog.isEditing = false;
		if (editCourseDialog.hasChanges) {
			await nextTick(async () => {
				await loadCourseList();
				editCourseDialog.hasChanges = false;
			});
		}
	};

	watch(() => mainData.viewChoice, async () => await loadCourseList());

	onMounted(async () => await loadCourseList());
</script>

<template>
	<div class="container my-4">
		<div class="row">
			<div class="col">
				<div class="row">
					<h1 class="col-auto"><CourseIcon fontSize="48px"/> Courses</h1>
				</div>
			</div>
			<div class="col text-center">
				<VBtnToggle v-model="mainData.viewChoice" :disabled="mainData.isLoading">
					<VBtn>Active</VBtn>
					<VBtn class="view-all-action">All</VBtn>
					<VBtn class="view-deleted-action">Deleted</VBtn>
				</VBtnToggle>
			</div>
			<div class="col text-right">
				<VBtn color="primary" variant="flat" size="large" class="ma-2 create-course-action" @click="addCourse" :disabled="mainData.isLoading"><BIconPlusSquare/>&nbsp;&nbsp;Add Course</VBtn>
			</div>
		</div>

		<VAlert v-if="mainData.hasError" color="error" class="mb-4 critical-error-message">Something went wrong.  Try again later.</VAlert>

		<VProgressLinear v-if="mainData.isLoading" indeterminate color="primary" class="loading-indicator" />

		<div :class="mainData.isLoading ? 'disabled row' : 'row'">
			<div v-if="!mainData.isLoading && mainData.courses.length === 0">
				<VAlert class="text-center">We couldn't find any courses.  Click the <strong>Add Course</strong> button to create a new one.</VAlert>
			</div>
			<div v-if="mainData.courses.length > 0">
				<VCard v-for="course in mainData.courses"
					:key="course.id"
					width="400"
					class="col-12 col-md-4 ma-4 course"
					elevation="4"
					:data-id="course.id"
					@click="editCourse(course.id)">
					<VCardTitle class="name">{{ getCourseName(course) }}</VCardTitle>
					<VCardSubtitle class="twc-status">{{ course.twcStatus }}</VCardSubtitle>
					<VCardText>
						<div class="row">
							<div class="col-6">
								<strong>Total hours:</strong> <span class="total-hours">{{ course.totalHours }}</span><br/>
								<strong>Objectives:</strong> <span class="objective-count">{{ course.objectiveCount }}</span>
							</div>
							<div class="col-6">
								<strong>Cost: </strong> <span class="cost">${{ course.cost }}</span>
							</div>
						</div>
					</VCardText>
				</VCard>
			</div>
		</div>

		<EditCourseDialog :courseId="editCourseDialog.courseId"
			:shouldBeEditing="editCourseDialog.isEditing"
			@courseUpdated="editCourseDialog.hasChanges = true"
			@closed="onEditCourseDialogClosed" />
	</div>
</template>
