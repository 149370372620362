<script>
import { VAlert, VBtn, VTable } from "vuetify/components";
import { VProgressLinear } from "vuetify/lib/components/index.mjs";
import { developmentService } from "@/services/developmentService";
import { getRouter } from "@/router";

export default {
	name: "DiagnosticsRecentErrorsView",

	components: {
		VAlert, VBtn, VProgressLinear, VTable
	},

	data: () => ({
		days: 7,
		errors: null,
		hasInitialLoadError: false,
		hasLoadMoreError: false,
		hasMoreHeadEntries: false,
		isLoading: true,
		timer: null,
	}),

	async created() {
		await this.initialize();
	},

	beforeDestroy() {
		clearInterval(this.timer);
	},

	methods: {
		async checkForAdditionalHeadEntries() {
			if (!this.errors || this.errors.length === 0)
				return;

			const firstId = this.errors[0].id;
			await developmentService.diagnostic.hasErrorsNewerThan(firstId)
				.then(({ data }) => {
					const hasMoreHeadEntries = data;
					this.setHasMoreHeadEntries(hasMoreHeadEntries);
				})
				.catch(error => {
					console.error(error);
				});
		},
		handleClick(operationId) {
			getRouter().push({ name: "developmentOperationDetails", params: { operationId: operationId } });
		},
		async initialize() {
			this.isLoading = true;

			this.hasInitialLoadError = false;
			this.hasLoadMoreError = false;
			this.errors = null;
			this.hasMoreHeadEntries = false;

			await developmentService.diagnostic.recentErrors(this.days)
				.then(({ data }) => {
					this.errors = data;
					this.isLoading = false;

					this.timer = setInterval(async () => {
						await this.checkForAdditionalHeadEntries();
					}, 5000);
				})
				.catch(error => {
					console.error(error);
					this.hasInitialLoadError = true;
					this.isLoading = false;
				});
		},
		async reloadAll() {
			clearInterval(this.timer);
			await this.initialize();
		},
		setHasMoreHeadEntries(value) {
			if (value === true) {
				this.hasMoreHeadEntries = true;
				clearInterval(this.timer);
			}
		},
	},
}
</script>

<template>
	<div class="container">
		<h1>Recent Errors</h1>

		<div v-if="this.isLoading">
			<v-progress-linear indeterminate></v-progress-linear>
		</div>
		<div v-if="!this.isLoading">
			<div v-if="this.hasInitialLoadError">
				<v-alert type="error" class="load-error">
					We couldn't retrieve the records.<br/>
					<v-btn @click="reloadAll" class="try-all-again">Click to Try Again</v-btn>
				</v-alert>
			</div>
			<div v-if="!this.hasInitialLoadError">
				<div v-if="this.hasMoreHeadEntries">
					<v-alert class="text-center">There are newer errors.<br/><v-btn @click="reloadAll" class="refresh-list">Click to Refresh</v-btn></v-alert>
				</div>

				<div v-if="this.errors && this.errors.length > 0">
					<v-table class="table table-striped" density="compact" style="font-size: 0.8rem;">
						<thead>
							<tr>
								<th>Time</th>
								<th>Category</th>
								<th>Subject</th>
								<th>Details</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="error in this.errors" :key="error.id" v-on:click="handleClick(error.operationId)" style="cursor: pointer;" :data-id="error.id">
								<td>{{ error.displayTime }}</td>
								<td>{{ error.category }}</td>
								<td>{{ error.subject }}</td>
								<td v-html="error.details"></td>
							</tr>
						</tbody>
					</v-table>
				</div>
				<div v-if="!this.errors || this.errors.length === 0">
					<v-alert class="text-center empty-message">There are no recent errors.<br/><v-btn @click="reloadAll" class="refresh-list">Click to Refresh</v-btn></v-alert>
				</div>
			</div>
		</div>
	</div>
</template>
