import { useUserStore } from "@/stores";
import { getRouter } from "@/router";
import { Roles, RoleIds } from "@/UserRoles";
import { trace } from "@/utils";

const groupIdToRoleMappings = [
	{ id: RoleIds.Accountant, role: Roles.Accountant },
	{ id: RoleIds.Developer, role: Roles.Developer },
	{ id: RoleIds.Director, role: Roles.Director },
	{ id: RoleIds.Instructor, role: Roles.Instructor },
	{ id: RoleIds.Representative, role: Roles.Representative },
	{ id: RoleIds.Student, role: Roles.Student }
];

let _serviceInstance = null;
async function getServiceInstance() {
	if (!_serviceInstance) {
		const module = await import("@/services/MsalServiceInstance");
		_serviceInstance = new module.MsalServiceInstance(groupIdToRoleMappings);
		await _serviceInstance.initialize();
	}

	return _serviceInstance;
};
function resetServiceInstance() {
	_serviceInstance = null;
	const userStore = getUserStore();
	userStore.clearAccount();
};
function useServiceInstance(serviceInstance) {
	_serviceInstance = serviceInstance;
};

let cachedUserStore = null;
const getUserStore = () => {
	if (!cachedUserStore) {
		cachedUserStore = useUserStore();
	}
	return cachedUserStore;
};

const authenticationService = {
	getActiveAccount: async function() {
		const userStore = getUserStore();
		var account = userStore.getAccount();
		if (account) {
			trace("Using stored user account.");
			return account;
		}

		const serviceInstance = await getServiceInstance();
		account = await serviceInstance.completeSignIn();
		if (account) {
			trace("Sign-in completed.  Using new account.");
			userStore.setAccount(account);
			return account;
		}

		trace("No active account exists.");
		return null;
	},
	performRedirect: function() {
		var redirectUrl = getUserStore().popRedirectUrl();
		trace("Redirecting to post-login page: ", redirectUrl);
		getRouter().push({ path: redirectUrl });
	},
	renewToken: async function() {
		const account = await this.getActiveAccount();
		if (account)
			this.performRedirect();
		return new Promise(r => r(account !== null));
	},
	signIn: async function (onSuccess, onError) {
		const serviceInstance = await getServiceInstance();
		const account = await serviceInstance.completeSignIn();
		if (account) {
			if (onSuccess)
				onSuccess(account);
			return account;
		}
		await serviceInstance.signIn();
	},
	signOut: async function (onSuccess, onError) {
		getUserStore().clearAccount();
		const serviceInstance = await getServiceInstance();
		await serviceInstance.signOut()
			.then(() => {
				getRouter().push({ name: "home" });
				if (onSuccess)
					onSuccess();
			})
			.catch(error => {
				if (onError)
					onError(error);
			});
	}
};

export { authenticationService, resetServiceInstance, useServiceInstance };
