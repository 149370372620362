import { defineStore, storeToRefs } from "pinia";
import { trace } from "@/utils";

export const useUserStore = defineStore({
	id: "UserStore",
	state: () => ({
		account: null, // { username: String, name: String, roles: [String], token }
		returnUrl: null
	}),
	actions: {
		clearAccount() {
			this.account = null;
			localStorage.removeItem("UserAccessToken");
		},
		getAccessToken() {
			if (!this.account)
				return null;

			return this.account.token;
		},
		getAccount() {
			if (!this.account) {
				const storedAccount = localStorage.getItem("UserAccessToken");
				if (!!storedAccount) {
					try {
						this.account = JSON.parse(storedAccount);
						if (!!this.account?.expiration) {
							this.account.expiration = new Date(this.account.expiration);
							if (this.account.expiration < new Date(Date.now())) {
								trace("Account expired; requiring sign-in.");
								this.clearAccount();
							}
						}
					}
					catch (error) {
						console.error("Stored account is invalid.", error);
						this.clearAccount();
					}
				}
			}
			return this.account;
		},
		popRedirectUrl() {
			const returnUrl = this.returnUrl;
			return returnUrl ?? "/my";
		},
		setAccount(token) {
			this.account = token;
			localStorage.setItem("UserAccessToken", JSON.stringify(token));
		},
		setRedirectUrl(returnUrl) {
			this.returnUrl = returnUrl;
		}
	}
});
