import { webApiService } from "./webApiService";

const accountingService = {
	account: {
		delete: async function (id) {
			// 200, empty
			return await webApiService.executePost(`Accounting/Account/Delete`, { id });
		},
		byId: async function(id) {
			// 200, <AccountDetail>
			return await webApiService.executeGet(`Accounting/Account/ById?Id=${id}`);
		},
		list: async function() {
			// 200, [<AccountHeader>]
			return await webApiService.executeGet("Accounting/Account/List");
		},
		save: async function(account) {
			// 200, { id: <String> }
			// 400, { accountaccountType: [<String>], name: [<String>], number: [<String>], description: [<String>] }
			return await webApiService.executePost("Accounting/Account/Save", account);
		},
	},
	invoice: {
		byId: async function(invoiceId) {
			// 200 <InvoiceDetail>
			return await webApiService.executeGet(`Accounting/Invoice/ById?Id=${invoiceId}`);
		},
		listOpen: async function() {
			// 200: [<InvoiceHeader>]
			return await webApiService.executeGet("Accounting/Invoice/ListOpen");
		},
		printView: async function(invoiceId) {
			// 200: <InvoicePrintDetail>
			return await webApiService.executeGet(`Accounting/Invoice/PrintView?Id=${invoiceId}`);
		},
		referenceData: async function() {
			// 200: { accounts: [<AccountLookup>], nextInvoiceNumber: <Number>, parties: [<PartyLookup>], products: [<ProductLookup>] }
			return await webApiService.executeGet("Accounting/Invoice/ReferenceData");
		},
		save: async function(invoice) {
			// 200, { id: <String> }
			// 400: [<String>]
			return await webApiService.executePost("Accounting/Invoice/Save", invoice);
		},
	},
	customerPayment: {
		/**
		 * @param {String} customerPaymentId 
		 * @returns 200, {
		 * 		id: String,
		 * 		date: String,
		 * 		amount: Number,
		 * 		depositAccountId: String,
		 * 		reference: String,
		 * 		memo: String,
		 * 		invoiceAllocations: [
		 * 			{ invoiceId: String, amount: Number }
		 * 		],
		 * 		prepaymentCreditAccountId: String,
		 * 		prepaymentCreditAmount: Number
		 * }
		 */
		byId: async function(customerPaymentId) {
			return await webApiService.executeGet(`Accounting/CustomerPayment/ById?Id=${customerPaymentId}`);
		},
		/**
		 * @param {String} customerPaymentId 
		 * @returns 200
		 */
		delete: async function(customerPaymentId) {
			return await webApiService.executePost("Accounting/CustomerPayment/Delete", { id: customerPaymentId });
		},
		/**
		 * @param {String} customerPaymentId The optional identifier of the Invoice Payment being edited, or a null reference if an Invoice Payment is being created.
		 * @param {String} customerId The identifier of the customer whose data should be retrieved.
		 * @returns 200, {
		 * 		partyName: String,
		 * 		defaultDepositAccountId: String,
		 * 		defaultPrepaymentCreditAccountId: String,
		 * 		invoices: [],
		 * 		depositAccounts: [],
		 * 		prepaymentCreditAccounts: [],
		 * }
		 */
		referenceDataForCustomer: async function(customerId) {
			return await webApiService.executeGet(`Accounting/CustomerPayment/ReferenceDataForParty?PartyId=${customerId}`);
		},
		referenceDataForCustomerPayment: async function(customerPaymentId) {
			return await webApiService.executeGet(`Accounting/CustomerPayment/ReferenceDataForCustomerPayment?CustomerPaymentId=${customerPaymentId}`);
		},
		/**
		 * @param {String} customerPaymentId The optional identifier of the Invoice Payment being edited, or a null reference if an Invoice Payment is being created.
		 * @param {String} invoiceId The identifier of the first invoice to which to apply payments.  This will be used to look up the Customer's information.
		 * @returns 200, {
		 * 		partyName: String,
		 * 		defaultDepositAccountId: String,
		 * 		defaultPrepaymentCreditAccountId: String,
		 * 		invoices: [],
		 * 		depositAccounts: [],
		 * 		prepaymentCreditAccounts: [],
		 * }
		 */
		referenceDataForInvoice: async function(invoiceId) {
			return await webApiService.executeGet(`Accounting/CustomerPayment/ReferenceDataForInvoice?InvoiceId=${invoiceId}`);
		},
		/**
		 * 
		 * @param {Object} customerPayment {
		 * 		id?: String,
		 * 		date: String,
		 * 		amount: Number,
		 * 		depositAccountId: String,
		 * 		reference: String,
		 * 		memo: String,
		 * 		invoiceAllocations: [
		 * 			{ invoiceId: String, amount: Number }
		 * 		],
		 * 		prepaymentCreditAccountId: String,
		 * 		prepaymentCreditAmount: Number
		 * }
		 * @returns 200, String
		 * 	-or- 400, [String]
		 */
		save: async function(customerPayment) {
			return await webApiService.executePost("Accounting/CustomerPayment/Save", customerPayment);
		},
	},
	employee: {
		byId: async function (employeeId) {
			return await webApiService.executeGet(`Accounting/Employee/ById?Id=${employeeId}`);
		},
		getCollectionFieldNames: function () {
			return ["identificationFiles", "i9FormFile", "w4FormFile"];
		},
		getSimpleValueFieldNames: function () {
			return ["emailAddress","employeeType","firstName","hireDate","hourlyWage","lastName","mailingAddressLine1","mailingAddressLine2","mailingAddressCity","mailingAddressPostalCode","mailingAddressStateAbbreviation","middleName","payrollScheduleId","socialSecurityNumber","w4AdditionalWithholdingAmount","w4AnnualWageForWithholdingDecreaseAmount","w4AnnualWageForWithholdingIncreaseAmount","w4AnnualWithholdingReductionAmount","w4FilingStatus","w4IsExempt","yearlySalary"];
		},
		initializeEmployee: function () {
			var employee = {};
			for (const field of this.getSimpleValueFieldNames())
				employee[field] = null;
			for (const field of this.getCollectionFieldNames())
				employee[field] = [];
			return employee;
		},
		list: async function () {
			return await webApiService.executeGet("Accounting/Employee/List");
		},
		referenceData: async function () {
			return await webApiService.executeGet("Accounting/Employee/ReferenceData");
		},
		save: async function(employee) {
			var formData = new FormData();
			if (employee.id)
				formData.append("id", employee.id);
			for (const fieldName of this.getSimpleValueFieldNames()) {
				if (employee[fieldName])
					formData.append(fieldName, employee[fieldName]);
			}
			for (const fieldName of this.getCollectionFieldNames()) {
				if (employee[fieldName].length > 0)
					employee[fieldName].forEach(value => formData.append(fieldName, value));
			}

			return await webApiService.executePost("Accounting/Employee/Save", formData);
		},
	},
	party: {
		/**
		 * @param {String} id 
		 * @returns 200, {
		 * 		id: {String},
		 * 		name: {String},
		 * 		isCustomer: {Boolean},
		 * 		isSupplier: {Boolean},
		 * 		customerInfo: {
		 * 			billingAddress: {String},
		 * 			defaultAccountsReceivableAccountId: {String},
		 * 			defaultTerms: {String}
		 * 		}
		 * }
		 */
		byId: async function (id) {
			return await webApiService.executeGet(`Accounting/Party/ById?Id=${id}`);
		},
		customerTransactionHistory: async function (customerId) {
			return await webApiService.executeGet(`Accounting/Party/CustomerTransactionHistory?PartyId=${customerId}`);
			// await new Promise(r => setTimeout(r, 500));
			// return Promise.resolve({
			// 	data: [
			// 		{ id: "111", transactionType: "Invoice", date: "6/30/2023", number: 2035, amount: 10000, isPaidInFull: true },
			// 		{ id: "222", transactionType: "Invoice", date: "7/31/2023", number: 2036, amount: 8500, isPaidInFull: false },
			// 		{ id: "112", transactionType: "Payment", date: "8/15/2023", amount: 10000, invoiceAllocations: [{ number: 2035, amount: 10000 }], prepaymentCreditAmount: 0 },
			// 		{ id: "333", transactionType: "Invoice", date: "8/31/2023", number: 2037, amount: 9500, isPaidInFull: false },
			// 		{ id: "113", transactionType: "Payment", date: "9/15/2023", amount: 8500, invoiceAllocations: [{ number: 2036, amount: 4500 }], prepaymentCreditAmount: 4000 },
			// 	]
			// });
		},
		/**
		 * @param {String} id 
		 * @returns 200
		 */
		delete: async function(id) {
			return await webApiService.executePost(`Accounting/Party/Delete`, { id });
		},
		/**
		 * @returns 200, [{
		 * 		id: {String},
		 * 		name: {String},
		 * 		isCustomer: {Boolean},
		 * 		isSupplier: {Boolean}
		 * 	}]
		 */
		list: async function() {
			return await webApiService.executeGet("Accounting/Party/List");
		},
		/**
		 * @returns 200, {
		 * 		accounts: [ { id: {String}, name: {String} } ],
		 * 		parties: [ { id: {String}, name: {String} } ]
		 * }
		 */
		referenceData: async function() {
			return await webApiService.executeGet("Accounting/Party/ReferenceData");
		},
		/**
		 * @param { id: {String}, name: {String} } party 
		 * @returns 200, {String}
		 * 	-or- 400, [{String}]
		 */
		save: async function(party) {
			var partyToSubmit = {
				id: party.id,
				name: party.name,
			};

			if (party.isCustomer)
				partyToSubmit.customerInfo = party.customerInfo;
			if (party.isSupplier)
				partyToSubmit.supplierInfo = party.supplierInfo;

			return await webApiService.executePost(`Accounting/Party/Save`, partyToSubmit);
		},
	},
	payrollRun: {
		byId: async function(payrollRunId) {
			return await webApiService.executeGet(`Accounting/PayrollRun/ById?Id=${payrollRunId}`);
		},
		calculatePayroll: async function(payrollEmployeeSelection) {
			return await webApiService.executePost("Accounting/PayrollRun/CalculatePayroll", payrollEmployeeSelection);
		},
		editPayroll: async function(payrollRun) {
			return await webApiService.executePost("Accounting/PayrollRun/EditPayroll", payrollRun);
		},
		employeesForPayrollRun: async function(payrollScheduleId) {
			return await webApiService.executeGet(`Accounting/PayrollRun/EmployeesForNextPayrollRun?PayrollScheduleId=${payrollScheduleId}`);
		},
		finalizePayroll: async function(payrollRun) {
			return await webApiService.executePost("Accounting/PayrollRun/FinalizePayroll", payrollRun);
		},
		listCompleted: async function() {
			return await webApiService.executeGet("Accounting/PayrollRun/ListCompleted");
		},
		listPending: async function() {
			return await webApiService.executeGet("Accounting/PayrollRun/ListPending");
		},
	},
	payrollSchedule: {
		byId: async function(id) {
			return await webApiService.executeGet(`Accounting/PayrollSchedule/ById?Id=${id}`);
		},
		deactivate: async function (id) {
			return await webApiService.executePost("Accounting/PayrollSchedule/Deactivate", { id });
		},
		delete: async function(id) {
			return await webApiService.executePost("Accounting/PayrollSchedule/Delete", { id });
		},
		listActive: async function() {
			return await webApiService.executeGet("Accounting/PayrollSchedule/ListActive");
		},
		listInactive: async function() {
			return await webApiService.executeGet("Accounting/PayrollSchedule/ListInactive");
		},
		reactivate: async function (id) {
			return await webApiService.executePost("Accounting/PayrollSchedule/Reactivate", { id });
		},
		save: async function(payrollSchedule) {
			return await webApiService.executePost("Accounting/PayrollSchedule/Save", payrollSchedule);
		}
	},
	product: {
		/**
		 * @param {String} id 
		 * @returns 200, {
		 * 		id: {String},
		 * 		name: {String},
		 * 		incomeAccountId: {String},
		 * 		unitPrice: {Number}
		 * }
		 */
		byId: async function (id) {
			return await webApiService.executeGet(`Accounting/Product/ById?Id=${id}`);
		},
		/**
		 * @param {String} id 
		 * @returns 200
		 */
		delete: async function(id) {
			return await webApiService.executePost(`Accounting/Product/Delete`, { id });
		},
		/**
		 * @returns 200, [{
		 * 		id: {String},
		 * 		name: {String},
		 * 		productType: {String},
		 * 		unitPrice: {Number}
		 * }]
		 */
		list: async function() {
			return await webApiService.executeGet("Accounting/Product/List");
		},
		/**
		 * @returns 200, {
		 * 		accounts: [ { id: {String}, name: {String} } ]
		 * }
		 */
		referenceData: async function() {
			return await webApiService.executeGet("Accounting/Product/ReferenceData");
		},
		/**
		 * @param { id: {String}, name: {String}, incomeAccountId: {String}, unitPrice: {Number} } product 
		 * @returns 200, {String}
		 * 	-or- 400, [{String}]
		 */
		save: async function(product) {
			return await webApiService.executePost(`Accounting/Product/Save`, product);
		},
	},
	settings: {
		currentPayroll: async function() {
			return await webApiService.executeGet("Accounting/Setting/CurrentPayroll");
		},
		getSimplePayrollSettingsFields: function () {
			return ["employerTaxesExpenseAccountId","payrollPayableAccountId","payrollTaxesPayableAccountId","salariesAndWagesExpenseAccountId","texasSutaTaxRate"];
		},
		savePayroll: async function(settings) {
			return await webApiService.executePost("Accounting/Setting/SavePayroll", settings);
		},
	},
	transaction: {
		/**
		 * @param {String} transactionId 
		 * @returns 200, {
		 * 		id: {String},
		 * 		date: {String},
		 * 		reference: {String},
		 * 		partyId: {String},
		 * 		isDeletable: {Boolean},
		 * 		transactionRows: [{
		 * 			accountId: {String},
		 * 			memo: {String},
		 * 			debitAmount: {Number},
		 * 			creditAmount: {Number}
		 * 		}]
		 * }
		 */
		byId: async function (transactionId) {
			return await webApiService.executeGet(`Accounting/Transaction/ById?Id=${transactionId}`);
		},
		/**
		 * @param {String} id 
		 * @returns 200
		 */
		delete: async function (id) {
			return await webApiService.executePost(`Accounting/Transaction/Delete`, { id });
		},
		/**
		 * @param {String} accountId 
		 * @returns 200, {
		 * 		account: { name: {String}, isDebitPositive: {Boolean} },
		 * 		transactions: [{
		 * 			id: {String},
		 * 			reference: {String},
		 * 			displayDate: {String},
		 * 			partyName: {String},
		 * 			debitAmount: {Number},
		 * 			creditAmount: {Number}
		 * 		}]
		 * }
		 */
		ledger: async function(accountId) {
			return await webApiService.executeGet(`Accounting/Transaction/Ledger?AccountId=${accountId}`);
		},
		/**
		 * @returns 200, {
		 * 		accounts: [{
		 * 			id: {String},
		 * 			name: {String},
		 * 			accountType: {String},
		 * 			number: {String},
		 * 			debitBalance: {Number},
		 * 			creditBalance: {Number}
		 * 		}],
		 * 		parties: [{ id: {String}, name: {String} }]
		 * }
		 */
		referenceData: async function () {
			return await webApiService.executeGet("Accounting/Transaction/ReferenceData");
		},
		/**
		 * @param { id: {String}, date: {String}, partyId: {String}, reference: {String}, transactionRows: [{ accountId: {String}, memo: {String}, debitAmount: {Number}, creditAmount: {Number} }] } transaction 
		 * @returns 200, {String}
		 * 	-or- 400, [<String>]
		 */
		save: async function(transaction) {
			return await webApiService.executePost("Accounting/Transaction/Save", transaction);
		},
	},
};

export { accountingService };
